/**
 * 获取链式调用对象取值
 * @param obj 如obj = { a: { b: 10 }}
 * @param keys keys = a.b
 * @returns {*} 10
 */
export const getObjValue = (obj, keys) => {
  if (!obj._success) return "";
  if (!keys && keys !== 0 && keys !== "0") return "";
  keys = keys.toString();
  let keyList = keys.split(".");
  let value = obj;
  let key;
  for (let i = 0; i < keyList.length; i++) {
    key = keyList[i];
    value = value && value[key];
  }
  return value;
};

export const isAllEqual = function (array) {
  if (Array.isArray(array)) {
    return new Set(array).size === 1;
  }
  return false;
};


/*
将base64转换为文件,接收2个参数，第一是base64，第二个是文件名字
最后返回文件对象
*/
export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const getMapDelayByMarkerCount = (count) => {
  if (count <= 10) {
    return 300
  } else if (count <= 200) {
    return 600
  } else if (count <= 400) {
    return 900
  } else if (count <= 600) {
    return 1200
  } else if (count <= 800) {
    return 1500
  } else if (count <= 1000) {
    return 2000
  } else if (count <= 1200) {
    return 2500
  } else if (count <= 1400) {
    return 3000
  } else if (count <= 1600) {
    return 4000
  } else if (count <= 1800) {
    return 5000
  } else if (count <= 2000) {
    return 6000
  } else if (count <= 2500) {
    return 7000
  } else {
    return 8000
  }
}