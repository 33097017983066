<template>
  <div class="empty">
      <div class="empty-text">{{ text }} </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
export default defineComponent({
    props: {
        text: String
    }
})
</script>

<style>
.empty {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.38);
    backdrop-filter: blur(6px);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99999
}
.empty .empty-text {
    width: 100%;
    height: 213px;
    background: rgba(51, 51, 51, .64);

    font-size: 31px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 213px;
    text-align: center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
</style>