<template>
  <div class="block page18 f-r f-c">
    <PageHeader :title="moduleData['3_18_1_1']" :sub-title="moduleData['3_18_1_1_en']"/>
    <PageTips page-num="12"/>
    <div class="page18-body f-r j-b">
      <div class="page18-body-left">
        <div class="page18-body-header f-r j-b a-c">
          <div class="page18-body-header-left">周边餐饮门店 共 {{ total }} 家</div>
        </div>
        <PieChart style="margin-top: -40px; height: 500px; width: 100%" :chart-data="pieData">
          <Empty text="当前区域暂无餐饮覆盖" v-if="!pieData.length"/>
        </PieChart>
        <div class="page-tips2">>当前图表中仅展示部分分类，全部餐饮数据于APP「周边配套」中查看</div>

        <div class="page18-body-header f-r j-b a-c" style="margin-top: 40px; margin-bottom: 10px">
          <div class="page18-body-header-left">区域餐饮品类数量分布（家）</div>
        </div>
        <BarChart style="height: 265px; width: 100%"
                  :x-data="barData.map(v => v.name)"
                  :y-data="barData.map(v => v.value)">
          <Empty text="当前区域暂无餐饮覆盖" v-if="!barData.length"/>
        </BarChart>
      </div>
      <div class="page18-body-right">
        <div class="page18-body-right f-c">
          <div class="page18-map" id="page18Map">
            <Empty text="当前区域暂无餐饮覆盖" v-if="!typeList.length"/>
          </div>
          <div class="page18-map-legend f-r a-c f-w">
            <div
              class="page18-map-legend-item f-r a-c"
              v-for="(item, index) in typeList"
              :key="index"
            >
              <div
                class="page18-map-legend-point point1"
                :style="{ background: item.color }"
              ></div>
              {{ item.name }}
            </div>
          </div>
          <div class="page18-map-tips">
            数据来源：餐饮外卖平台边界大数据整合
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "./components/PageHeader";
import PageTips from "./components/PageTips";
import PieChart from "./components/PieChart2";
import BarChart from "./components/BarChart3";

import AMapLoader from "@amap/amap-jsapi-loader";
import { shallowRef } from "@vue/reactivity";

import { computed, defineComponent, onMounted, ref } from "vue";
import { mapAddCircle, addMapCenter } from "@/utils/pdf";
import { useStore } from "vuex";

import { getMapDelayByMarkerCount } from '@/utils'
import {
  drawMapKeepAlive,
} from '@/api/apiv2'

export default defineComponent({
  components: {
    PageHeader,
    PageTips,
      PieChart,
    BarChart,
  },
  props: {
    cityInfo: {
        type: Object,
        default: () => {
            return {};
        },
    },
    catData: {
        type: Array,
        default: () => [],
    },
    moduleData: {
      type: Object,
      default: () => {
        return {

        };
      },
    },
    // poi改造后数据
    poiData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore()
    const color = [
      "#0045FF",
      "#FF7800",
      '#FF0000',
      '#17C49D',
      '#00BEFF',
      '#A933E4',
      '#00FF19',
      '#DACE10',
      '#6694CF',
      '#F53A99',
      '#A1C607',
      '#6005B1',
      '#FF5E87',
    ];

    let map = shallowRef(null);
    const addMaker = (amap, map, data) => {
        let markerList = [];
        const newData = data?.poi || []
        newData.forEach((v) => {
            let content = `<div style="width: 12px;height: 12px; border-radius: 50%;background: ${data.color};"></div>`
            let marker = new amap.Marker({
                position: new amap.LngLat(
                    v.location.split(",")[0],
                    v.location.split(",")[1]
                ),
                content
            });
            markerList.push(marker);
        });
        map.add(markerList);
    };
    onMounted(() => {
        AMapLoader.load({
            key: "f0da9fb8263bb9b657e2257ec09c6df7", // 申请好的Web端开发者Key，首次调用 load 时必填
            version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        }).then((AMap) => {
          map = new AMap.Map("page18Map", {
            WebGLParams: {
                preserveDrawingBuffer:true
            },
            dragEnable: false,
            zoomEnable: false,
            zoom: store.getters.mapZoom, //初始化地图级别
            center: props.cityInfo.location.split(","), //初始化地图中心点位置
          });


          mapAddCircle(props.cityInfo, map)
          addMapCenter(AMap, map, props.cityInfo.location)

          // 按数量排序 多的点先绘制 在底层 视觉效果好一点
          const sortList = props.catData.map((c, index) => ({
            ...c,
            color: color[index],
          })).sort((a, b) => b.poi.length - a.poi.length)
          for (let i = 0; i < sortList.length; i++) {
            addMaker(AMap, map, sortList[i]);
          }

          // 等待地图渲染
          const delay = getMapDelayByMarkerCount(sortList.length)
          drawMapKeepAlive(delay).catch((e) => {})

          map.setFitView()
      })
      .catch((e) => {
          console.log(e);
      });
    });

    // poi改版
    const total = computed(() => {
      if (!props.poiData || !props.poiData?.nearby?.length) return 0
      const item = props.poiData.nearby.find((n) => n.bhProType === 'P070000')
      return item?.count || 0
    })

    const typeList = props.catData.map((item, index) => ({
      name: item.bhProTypeName,
      color: color[index],
    }))

    const pieData = computed(() => {
        return props.catData.map((item) => ({
          name: item.bhProTypeName,
          value: item.count,
        }))
    })

    const barData = computed(() => {
        return props.catData.map((item) => ({
          name: item.bhProTypeName,
          value: item.count,
        }))
    })

    return {
      map,
      total,
      pieData,
      barData,
      typeList,
    };
  },
});
</script>

<style lang="less">
.page18 {
  position: relative;
  background: #ffffff;
  padding: 26px 60px 32px 0;
  .page18-body {
    flex-grow: 1;
    padding: 67px 20px 0 61px;
    .page18-body-left {
      width: 900px;
      .page18-body-header {
        height: 33px;
        font-size: 24px;

        font-weight: bold;
        color: #333333;
        line-height: 33px;
        margin-bottom: 10px;
      }
    }
    .page18-body-right {
      width: 853px;
      .page18-map {
        width: 100%;
        height: 760px;
      }
      .page18-map-legend {
        flex-grow: 1;
          margin-bottom: 15px;
        .page18-map-legend-item {
            width: 20%;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 28px;
            margin-top: 15px;
        }
        .page18-map-legend-item:last-child {
          margin-right: 0;
        }
        .page18-map-legend-icon {
          width: 22px;
          height: 22px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-right: 12px;
        }
        .icon1 {
          background-image: url("~@/assets/机场.png");
        }
        .icon2 {
          background-image: url("~@/assets/火车站.png");
        }
        .icon3 {
          background-image: url("~@/assets/高铁站.png");
        }
        .page18-map-legend-point {
          width: 22px;
          height: 22px;
          margin-right: 12px;
          border-radius: 50%;
        }
        .point1 {
          background-color: #0045FF ;
        }
      }
      .page18-map-tips {
        flex-shrink: 0;
        text-align: right;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9a9a9a;
        line-height: 28px;
      }
    }
  }
}
</style>
