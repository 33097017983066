import request from "@/utils/request";

export function getReport(
  address_id,
  module_ids = "1|2|3|4|5|6|7|8|9|10|11|12|13|14|15"
) {
  return request({
    url: "/api/market_survey_report/urban_insight_pdf",
    method: "post",
    params: {
      address_id,
      module_ids,
    },
  });
}

export function getCatData({ location, radius, cate_id, return_type = "all" }) {
  return request({
    url: "/api/poi/distribution",
    method: "post",
    params: {
      location,
      radius: radius * 1000,
      cate_id,
      return_type,
      list_filter: 1,
    },
  });
}

export function getHousePriceData({ location, radius, households }) {
  return request({
    url: "/api/data_map/house_price",
    method: "post",
    params: {
      location,
      radius: radius * 1000,
      households,
      source: 1,
      list_filter: 1,
    },
  });
}

export function getBrandData(address_id) {
  return request({
    url: "/api/market_survey_report/recommend_brand",
    method: "post",
    params: {
      address_id
    },
  });
}

/**
 * 获取城市半径
 * @param district_no
 * @return {AxiosPromise}
 */
export function getCityKm(district_no) {
  return request({
    url: "/api/data_map/get_map_radius",
    method: "post",
    params: {
      district_no
    },
  });
}

/**
 * 获取商区围栏数据
 * @param location
 * @param radius
 * @return {AxiosPromise}
 */
export function getBusPolygon({ location, radius }) {
  return request({
    url: "/api/home_page/fence_data_by_location",
    method: "post",
    params: {
      location,
      radius: radius * 1000
    },
  });
}

// 绘制地图保持连接
export const drawMapKeepAlive = (millisecond) => {
  return request({
    url: "/api/keep_connection",
    method: "post",
    params: {
      millisecond
    },
  });
}