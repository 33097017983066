<template>
  <div class="ptable f-r">
    <slot></slot>
    <table>
      <thead>
        <tr>
          <th
            v-for="(col, colIndex) in colData"
            :key="colIndex"
            :style="{ width: col.width }"
          >
            {{ col.label }} <span style="font-size: 12px">{{col.subLabel}}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in rowData" :key="rowIndex">
          <td
            v-for="(col, colIndex) in colData"
            :key="colIndex"
            :style="{ width: col.width }"
          >
            <div class="cell">
              {{ row[col.prop] }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    rowData: Array,
    colData: Array,
  },
});
</script>

<style lang="less">
.ptable {
  position: relative;
  table {
      border-spacing: 2px;
      width: 100%;
      height: 100px;
    tr {
      th {
        height: 52px;
        background: #dbdbdb;
        font-size: 20px;
        line-height: 24px;
      }
      td {
        height: 63px;
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        text-align: center;
        background: #f2f2f2;
      }
      td:first-child {
        background: #dbdbdb;
      }
    }
  }
}
</style>
