<template>
  <div class="ptable2 f-r">
    <slot></slot>
    <table>
      <thead>
        <tr>
          <th
            v-for="(col, colIndex) in colData"
            :key="colIndex"
            :style="{ width: col.width }"
          >
            {{ col.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in rowData" :key="rowIndex">
          <td
            v-for="(col, colIndex) in colData"
            :key="colIndex"
            :style="{ width: col.width, fontSize: getFontSize(row[col.prop])}"
          >
            <div class="cell">
              {{ row[col.prop] }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    rowData: Array,
    colData: Array,
  },
  methods: {
    getFontSize(value) {
      if (!value) return '20px'
      if (value.length > 50) {
        return '16px'
      } else if (value.length > 80) {
        return '14px'
      }
    }
  }
});
</script>

<style lang="less">
.ptable2 {
  position: relative;
  table {
    border-spacing: 2px;
    width: 100%;
    tr {
      th {
        height: 50px;
        background: #dbdbdb;
        font-size: 20px;
        line-height: 26px;
      }
      td {
        height: 80px;
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        line-height: 26px;
        padding-left: 15px;
        text-align: center;
        background: #f2f2f2;
      }
      td:first-child {
        background: #dbdbdb;
        padding: 0 30px;
      }
    }
  }
}
</style>
