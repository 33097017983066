<template>
  <div class="bar-chart2">
    <slot></slot>
    <VChart
      style="width: 100%; height: 100%"
      :option="option"
      autoresize
    ></VChart>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
export default defineComponent({
  props: {
    barWidth: {
      type: Number,
      default: 36,
    },
    xData: {
      type: Array,
      default: () => [

      ],
    },
    yData: {
      type: Array,
      default: () => [],
    },
    color: {
      type: Array,
      default: () => [
        '#0045FF', '#FF7800', '#FF0000',
        '#17C49D', '#00BEFF', '#A933E4',
      ],
    }
  },
  setup(props) {
    // const color = ["#0045FF", "#FF7800", "#FF0000", "#17C49D"];
    const option = computed(() => {
      return {
        grid: {
          top: 0,
          left: 0,
          right: 40,
          bottom: 0,
          containLabel: true,
        },
        xAxis: {
          type: "value",
          show: false,
        },
        yAxis: {
          type: "category",
          data: props.xData,
          inverse:true,
          axisLabel: {
            fontSize: 12,
            color: "rgba(0,0,0,0.45)",
          },
          axisTick: { show: false },
          axisLine: {
            show: false,
          },
        },
        series: [
          {
            type: "bar",
            data: props.yData.map((el, index) => {
              return {
                name: props.xData[index],
                value: el,
                itemStyle: {
                  color: props.color[index],
                },
              };
            }),
            barMaxWidth: props.barWidth,
            label: {
              show: true,
              position: "right",
              fontSize: 20,
              color: "#000",
            },
          },
        ],
      };
    });
    return {
      option,
    };
  },
});
</script>

<style lang="less">
.bar-chart2 {
  height: 100%;
  position: relative;
}
</style>
