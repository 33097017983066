<template>
    <div class="pie-chart2 f-r f-c">
      <slot></slot>
        <div class="chart-canvas">
            <VChart v-show="chartData.filter(el => el && el.value).length" style="width: 100%; height: 100%" :option="option" autoresize></VChart>
        </div>
        <div class="chart-legend f-c">
            <div class="chart-legend-item f-r a-c" v-for="(item, index) in legendList" :key="index">
                <div class="chart-legend-color" :style="{ background: item.color }"></div>
                <div class="chart-legend-text">{{ item.name }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent } from "vue";
export default defineComponent({
    props: {
        chartData: {
            type: Array,
            default: () => [],
        },
    },
    setup(props) {
        const color = [
            "#73DEB3",
            "#7585A2",
            '#F7C739',
            '#EB7E65',
            '#83D0EF',
            '#A285D2',
            '#FFAB67',
            '#46A9A8',
            '#73A0FA',
        ];
        const option = computed(() => {
            return {
                color,
                tooltip: {
                  show: true,
                },
                series: [
                    {
                      type: "pie",
                      radius: '65%',
                      center: ["35%", "50%"],
                      avoidLabelOverlap: true,
                      // minAngle: 5,
                      label: {
                          color: "#7F7F7F",
                          fontSize: 18,
                          formatter: '{d}%'
                      },
                      data: props.chartData,
                    },
                ],
            };
        });
        const legendList = computed(() => {
            return props.chartData.map((el, index) => {
                return {
                    ...el,
                    color: color[index],
                };
            });
        });
        return {
            option,
            legendList,
        };
    },
});
</script>

<style lang="less">
.pie-chart2 {
    height: 100%;
    padding: 12px;
    position: relative;
    .chart-title {
        position: absolute;
        top: 42%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        text-align: center;

        font-weight: bold;
        color: #333333;
        line-height: 33px;
        .title {
            font-size: 20px;
        }
        .subtitle {
            font-size: 38px;
            line-height: 38px;
        }
    }
    .chart-legend {
        position: absolute;
        right: 50px;
        top: 50px;
        padding: 0 18px;
        .chart-legend-item {
            margin-bottom: 17px;
            .chart-legend-color {
                width: 13px;
                height: 13px;
                margin-right: 15px;
            }
            .chart-legend-text {
                height: 24px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #7F7F7F;
                line-height: 24px;
            }
        }
    }
    .chart-canvas {
        flex-grow: 1;
    }
}
</style>
