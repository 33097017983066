<template>
  <div class="block page2" id="page2">
    <div class="content f-r f-c a-c j-c">
      <div class="title">
        {{ moduleData.model2_name }}
      </div>
      <div class="sub-title">
        {{ moduleData.model2_name_en }}
      </div>
    </div>
    <PageTips />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import PageTips from "./components/PageTips";
export default defineComponent({
  components: { PageTips },
  props: {
    moduleData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
});
</script>

<style lang="less">
.page2 {
  background: #333333;
  position: relative;
  .content {
    width: 100%;
    height: 100%;
    .title {
      width: 100%;
      text-align: center;
      height: 154px;
      font-size: 110px;

      font-weight: bold;
      color: #ffffff;
      line-height: 154px;
    }
    .sub-title {
      width: 100%;
      text-align: center;
      font-size: 59px;

      font-weight: bold;
      color: #ffffff;
      line-height: 86px;
    }
  }
}
</style>
