<template>
  <div class="block page3 f-r f-c" id="page3">
    <PageHeader
      :title="moduleData['2_3_1_1']"
      :sub-title="moduleData['2_3_1_1_en']"
    />
    <PageTips page-num="15" />
    <div class="page3-body f-r j-b">
      <div class="page3-body-left">
        <TableNav width="899px">
          <template v-slot:title>{{ moduleData["2_3_2_2"] }}</template>
        </TableNav>
        <div class="etable f-r">
          <div class="etable-wrapper">
            <table>
              <tbody>
                <tr>
                  <td style="width: 225px">
                    <div class="cell">区县名称</div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell">
                      {{ moduleData.district_county.dc_name }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="width: 225px">
                    <div class="cell">
                      {{
                      moduleData.district_county.dc_permanent_population
                      .sub_cate_name
                      }}
                    </div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell">
                      {{
                      moduleData.district_county.dc_permanent_population
                      .sub_cate_value
                      }}
                      {{
                      moduleData.district_county.dc_permanent_population
                      .company
                      }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="width: 225px">
                    <div class="cell f-r a-c">
                      <span class="color-red">
                        {{
                        moduleData.district_county.dc_second_house_price
                        .month
                        }}月
                      </span>
                      <span>
                        {{
                        moduleData.district_county.dc_second_house_price
                        .sub_cate_name
                        }}
                      </span>
                    </div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell">
                      {{
                      moduleData.district_county.dc_second_house_price
                      .sub_cate_value
                      }}
                      {{
                      moduleData.district_county.dc_second_house_price.company
                      }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="width: 225px">
                    <div class="cell f-r a-c">较市均价</div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell f-r a-c j-c">
                      <div
                        :class="[
                          getArrowIcon(
                            moduleData.district_county.dc_second_house_price
                              .tip1_plus_minus
                          ),
                        ]"
                      ></div>
                      <span>
                        {{
                        moduleData.district_county.dc_second_house_price
                        .tip1_percent
                        }}
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="etable-wrapper">
            <table>
              <tbody>
                <tr>
                  <td style="width: 225px">
                    <div class="cell">区县级别</div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell">{{ moduleData.district_county.dc_level }}</div>
                  </td>
                </tr>
                <tr>
                  <td style="width: 225px">
                    <div class="cell">
                      {{
                      moduleData.district_county.dc_permanent_gdp
                      .sub_cate_name
                      }}
                    </div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell">
                      {{
                      moduleData.district_county.dc_permanent_gdp
                      .sub_cate_value
                      }}
                      {{
                        moduleData.district_county.dc_permanent_gdp
                          .company
                      }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="width: 225px">
                    <div class="cell f-r a-c">
                      <span class="color-red">
                        {{
                        moduleData.district_county.dc_new_house_price.month
                        }}月
                      </span>
                      <span>
                        {{
                        moduleData.district_county.dc_new_house_price
                        .sub_cate_name
                        }}
                      </span>
                    </div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell">
                      {{
                      moduleData.district_county.dc_new_house_price
                      .sub_cate_value
                      }}
                      {{
                      moduleData.district_county.dc_new_house_price.company
                      }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="width: 225px">
                    <div class="cell f-r a-c">较市均价</div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell f-r a-c j-c">
                      <div
                        :class="[
                          getArrowIcon(
                            moduleData.district_county.dc_new_house_price
                              .tip2_plus_minus
                          ),
                        ]"
                      ></div>
                      <span>
                        {{
                        moduleData.district_county.dc_new_house_price
                        .tip2_percent
                        }}
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <TableNav width="899px">
          <template v-slot:title>{{ moduleData["2_3_3"] }}</template>
        </TableNav>
        <ETable :table-data="tableData2" cell-width="224px" />

        <TableNav width="899px">
          <template v-slot:title>{{ moduleData["2_3_4"] }}</template>
        </TableNav>
        <ETable :table-data="tableData3" cell-width="224px" />

        <TableNav width="899px">
          <template v-slot:title>{{ moduleData["2_3_5"] }}</template>
        </TableNav>
        <div class="etable f-r">
          <div class="etable-wrapper">
            <table>
              <tbody>
                <tr>
                  <td style="width: 225px">
                    <div class="cell">城市GDP</div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell f-c a-c j-c">
                      <div>
                        {{
                          moduleData.urban_economy.city_gdp.y.length
                            ? moduleData.urban_economy.city_gdp.y[moduleData.urban_economy.city_gdp.y.length - 1]
                            : ''
                        }}
                        {{ moduleData.urban_economy.city_gdp.company }}
                      </div>
                      <div class="color-red f-14">
                        （全国排名 -
                        {{ moduleData.urban_economy.city_gdp.latest_rank }} -）
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="width: 225px">
                    <div class="cell">人均消费支出</div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell f-c a-c j-c">
                      <div>
                        {{
                          moduleData.urban_economy.per_cap_con_exp.y.length
                            ? moduleData.urban_economy.per_cap_con_exp.y[moduleData.urban_economy.per_cap_con_exp.y.length - 1]
                            : ''
                        }}
                        {{ moduleData.urban_economy.per_cap_con_exp.company }}
                      </div>
                      <div class="color-red f-14">
                        （全国排名 -
                        {{
                        moduleData.urban_economy.per_cap_con_exp.latest_rank
                        }}
                        -）
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="width: 225px">
                    <div class="cell">社会消费品零售总额</div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell f-c a-c j-c">
                      <div>
                        {{
                          moduleData.urban_economy.consumption_export.y.length
                            ? moduleData.urban_economy.consumption_export.y[moduleData.urban_economy.consumption_export.y.length - 1]
                            : ''
                        }}
                        {{
                        moduleData.urban_economy.consumption_export.company
                        }}
                      </div>
                      <div class="color-red f-14">
                        （全国排名 -
                        {{
                        moduleData.urban_economy.consumption_export
                        .latest_rank
                        }}
                        -）
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="width: 225px">
                    <div class="cell">
                      <div class="cell f-r a-c">
                        <span class="color-red">
                          {{
                          moduleData.urban_economy.second_current_month.month
                          }}月
                        </span>
                        <span>二手房均价</span>
                      </div>
                    </div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell">
                      {{
                      moduleData.urban_economy.second_current_month
                      .sub_cate_value
                      }}
                      {{
                      moduleData.urban_economy.second_current_month.company
                      }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="etable-wrapper">
            <table>
              <tbody>
                <tr>
                  <td style="width: 225px">
                    <div class="cell">人均GDP</div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell f-c a-c j-c">
                      <div>
                        {{
                          moduleData.urban_economy.per_cap_gdp.y.length
                          ? moduleData.urban_economy.per_cap_gdp.y[moduleData.urban_economy.per_cap_gdp.y.length - 1]
                            : ''
                        }}
                        {{ moduleData.urban_economy.per_cap_gdp.company }}
                      </div>
                      <div class="color-red f-14">
                        （全国排名 -
                        {{ moduleData.urban_economy.per_cap_gdp.latest_rank }}
                        -）
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="width: 225px">
                    <div class="cell">人均可支配收入</div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell f-c a-c j-c">
                      <div>
                        {{
                          moduleData.urban_economy.per_cap_dis_in_come.y.length
                            ? moduleData.urban_economy.per_cap_dis_in_come.y[moduleData.urban_economy.per_cap_dis_in_come.y.length - 1]
                            : ''
                        }}
                        {{
                        moduleData.urban_economy.per_cap_dis_in_come.company
                        }}
                      </div>
                      <div class="color-red f-14">
                        （全国排名 -
                        {{
                        moduleData.urban_economy.per_cap_dis_in_come
                        .latest_rank
                        }}
                        -）
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="width: 225px">
                    <div class="cell">人均月薪</div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell f-c a-c j-c">
                      <div>
                        {{
                          moduleData.urban_economy.per_cap_dis_mon_salary.y.length
                            ? moduleData.urban_economy.per_cap_dis_mon_salary.y[moduleData.urban_economy.per_cap_dis_mon_salary.y.length - 1]
                            : ''
                        }}
                        {{
                        moduleData.urban_economy.per_cap_dis_mon_salary
                        .company
                        }}
                      </div>
                      <div class="color-red f-14">
                        （全国排名 -
                        {{
                        moduleData.urban_economy.per_cap_dis_mon_salary
                        .latest_rank
                        }}
                        -）
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="width: 225px">
                    <div class="cell">
                      <div class="cell f-r a-c">
                        <span class="color-red">
                          {{
                          moduleData.urban_economy.second_last_month.month
                          }}月
                        </span>
                        <span>新房均价</span>
                      </div>
                    </div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell">
                      {{
                      moduleData.urban_economy.second_last_month
                      .sub_cate_value
                      }}
                      {{ moduleData.urban_economy.second_last_month.company }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <TableNav width="899px">
          <template v-slot:title>{{ moduleData["2_3_6"] }}</template>
        </TableNav>
        <div class="etable f-r">
          <div class="etable-wrapper">
            <table>
              <tbody>
                <tr>
                  <td style="width: 225px">
                    <div class="cell">接待国内游客</div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell f-c a-c j-c">
                      <div>
                        {{
                        moduleData.tourist_overview.receive_domestic_tourist
                        .sub_cate_value
                        }}
                        {{
                        moduleData.tourist_overview.receive_domestic_tourist
                        .company
                        }}
                      </div>
                      <div class="f-r a-c j-c f-14">
                        比上年
                        <div
                          :class="[
                            getArrowIcon(
                              moduleData.tourist_overview
                                .receive_domestic_tourist.percent > 0
                                ? '+'
                                : '-'
                            ),
                          ]"
                        ></div>
                        <div
                          :class="[
                            getArrowColor(
                              moduleData.tourist_overview
                                .receive_domestic_tourist.percent > 0
                                ? '+'
                                : '-'
                            ),
                          ]"
                        >
                          {{
                          moduleData.tourist_overview.receive_domestic_tourist
                          .percent
                          }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="etable-wrapper">
            <table>
              <tbody>
                <tr>
                  <td style="width: 225px">
                    <div class="cell">旅游总收入</div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell f-c a-c j-c">
                      <div>
                        {{
                        moduleData.tourist_overview.total_travel_income
                        .sub_cate_value
                        }}
                        {{
                        moduleData.tourist_overview.total_travel_income
                        .company
                        }}
                      </div>
                      <div class="f-r a-c j-c f-14">
                        比上年
                        <div
                          :class="[
                            getArrowIcon(
                              moduleData.tourist_overview.total_travel_income
                                .percent > 0
                                ? '+'
                                : '-'
                            ),
                          ]"
                        ></div>
                        <div
                          :class="[
                            getArrowColor(
                              moduleData.tourist_overview.total_travel_income
                                .percent > 0
                                ? '+'
                                : '-'
                            ),
                          ]"
                        >
                          {{
                          moduleData.tourist_overview.total_travel_income
                          .percent
                          }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="page3-body-right f-c">
        <div style="width: 100%; margin-bottom: 21px" class="f-r j-b">
          <div style="width: 420px">
            <ChartNav :title="moduleData['2_3_7']"></ChartNav>
            <LineChart
              style="height: 360px"
              :unit="moduleData.city_gdp.company"
              :x-data="moduleData.city_gdp.x"
              :y-data="moduleData.city_gdp.y"
            />
          </div>
          <div style="width: 420px">
            <ChartNav title="人均GDP"></ChartNav>
            <LineChart
              style="height: 360px"
              :unit="moduleData.per_cap_gdp.company"
              :x-data="moduleData.per_cap_gdp.x"
              :y-data="moduleData.per_cap_gdp.y"
            />
          </div>
        </div>
        <ChartNav :title="moduleData['2_3_9_16']" :subtitle="moduleData.each_region_pop.y_company"></ChartNav>
        <BarChart
          style="width: 100%; height: 400px"
          :unit="moduleData.each_region_pop.company"
          :x-data="moduleData.each_region_pop.x.slice(0,10)"
          :y-data="moduleData.each_region_pop.y.slice(0,10)"
        />
        <div class="each_region_pop-tips">数据来源：国家统计局社会发展公报整合，以上数据为最新年份统计数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "./components/PageHeader";
import PageTips from "./components/PageTips";
import TableNav from "./components/TableNav";
import ChartNav from "./components/ChartNav";
import LineChart from "./components/LineChart";
import BarChart from "./components/BarChart";
import ETable from "./components/ETable";

import { defineComponent, computed } from "vue";
export default defineComponent({
  components: {
    ETable,
    PageHeader,
    PageTips,
    TableNav,
    ChartNav,
    LineChart,
    BarChart,
  },
  props: {
    moduleData: {
      type: Object,
      default: () => {}
    },
  },
  setup(props) {
    function getArrowIcon(plus_minus) {
      if (plus_minus === "+") return "arrow-up";
      if (plus_minus === "-") return "arrow-down";
      return "";
    }
    function getArrowColor(plus_minus) {
      if (plus_minus === "+") return "arrow-up-color";
      if (plus_minus === "-") return "arrow-down-color";
      return "";
    }

    const tableData2 = computed(() => {
      return [
        [
          ["城市名称", props.moduleData.prefecture_level_city_data.plc_name],
          [
            "城市面积",
            props.moduleData.prefecture_level_city_data.plc_area + " 平方公里",
          ],
        ],
        [
          ["城市线级", props.moduleData.prefecture_level_city_data.plc_line_level],
          [
            "常住人口",
            props.moduleData.prefecture_level_city_data.plc_permanent_population
              .sub_cate_value +
              " " +
              props.moduleData.prefecture_level_city_data
                .plc_permanent_population.company,
          ],
        ],
      ];
    });
    const tableData3 = computed(() => {
      return [
        [
          [
            "户籍人口",
            props.moduleData.prefecture_level_city_data
              .plc_registered_residence_population.sub_cate_value +
              " " +
              props.moduleData.prefecture_level_city_data
                .plc_registered_residence_population.company,
          ],
          [
            "七普户数",
            props.moduleData.prefecture_level_city_data
              .plc_seven_house_hold_population.sub_cate_value +
              " " +
              props.moduleData.prefecture_level_city_data
                .plc_seven_house_hold_population.company,
          ],
        ],
        [
          [
            "新增人口",
            props.moduleData.prefecture_level_city_data.plc_new_population
              .sub_cate_value +
              " " +
              props.moduleData.prefecture_level_city_data.plc_new_population
                .company,
          ],
          [
            "城镇人口",
            props.moduleData.prefecture_level_city_data.plc_urban_population
              .sub_cate_value +
              " " +
              props.moduleData.prefecture_level_city_data.plc_urban_population
                .company,
          ],
        ],
      ];
    });

    return {
      getArrowIcon,
      getArrowColor,
      tableData2,
      tableData3,
    };
  },
});
</script>

<style lang="less">
.page3 {
  position: relative;
  background: #ffffff;
  padding: 26px 60px 32px 0;
  .page3-body {
    flex-grow: 1;
    padding: 17px 20px 0 39px;
    .page3-body-left {
      width: 900px;
    }
    .page3-body-right {
      width: 853px;
    }
    .each_region_pop-tips {
      flex-grow: 1;
      width: 100%;
      text-align: right;
      font-size: 20px;
      font-weight: 400;
      color: #9a9a9a;
    }
  }
}
</style>
