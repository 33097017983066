<template>
  <div class="chart-nav f-r" :style="{ width }">
    <div class="nav1">
      {{ title }}
    </div>
    <div class="nav2">
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    width: String,
    title: String,
    subtitle: String,
  },
});
</script>

<style lang="less">
.report-page {
  .chart-nav {
    height: 50px;
    background: #333333;
    position: relative;
    font-size: 24px;

    font-weight: bold;
    color: #ffffff;
    line-height: 50px;
    .nav1 {
      width: 100%;
      text-align: center;
    }
    .nav2 {
      position: absolute;
      top: 0;
      height: 50px;
      right: 12px;
    }
  }
}
</style>
