<template>
  <div class="block page13 f-c">
    <PageHeader :title="moduleData['3_13_1_0']" :sub-title="moduleData['3_13_1_0_en']"/>
    <PageTips page-num="10"/>
    <div class="page13-body f-c">
      <TableNav width="1774px" style="height: 70px; margin-bottom: 25px">
        <template v-slot:title>
          <div class="page13-table-header f-r j-b">
            <div class="page13-table-title">
              {{ moduleData["3_13_2_1"] }}
            </div>
            <div class="page13-table-sub-title">
              总办公楼项目数：{{ catCount }}个
            </div>
          </div>
        </template>
      </TableNav>
      <div style="width: 1774px" class="f-r j-b" v-if="rowData.length">
          <PTable :col-data="colData" :row-data="rowData.slice(0,10)">
          </PTable>
          <PTable v-if="rowData.length" :col-data="colData" :row-data="rowData.slice(10,20)">
          </PTable>
      </div>
      <div style="flex-grow: 1; " class="page13-map-tips f-c j-e">
        <Empty text="当前区域暂无办公楼覆盖" v-if="!rowData.length"/>
        <div class="page-tips2" style="padding-bottom: 27px;">>更多写字楼分布于APP「数据地图/市调详情_周边配套」中查看</div>
        数据来源：边界房产大数据，房产平台数据整合
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import PageHeader from "@/views/Report/components/PageHeader";
import PageTips from "@/views/Report/components/PageTips";
import TableNav from "@/views/Report/components/TableNav";
import PTable from "./components/PTable";

export default defineComponent({
  components: {
    PageHeader,
    PageTips,
    TableNav,
    PTable,
  },
  props: {
      catData: {
          type: Array,
          default: () => {
              return [];
          },
      },
      catCount: {
        type: Number,
        default: 0,
      },
    moduleData: {
      type: Object,
      default: () => {}
    },
  },
  setup(props) {
    const colData = ref([
      { prop: "index", width: "67px", label: "序号" },
      { prop: "poi_type", width: "133px", label: "类型" },
      { prop: "name", width: "486px", label: "写字楼名称" },
      // {
      //   prop: "daily_rent",
      //   width: "133px",
      //   label: "日租金",
      //   subLabel: "(元/㎡)",
      // },
      // {
      //   prop: "floor_avg",
      //   width: "100px",
      //   label: "总楼层",
      // },
      { prop: "distance", width: "107px", label: "距离" , subLabel: "km"},
    ]);
    const rowData = computed(() => {
        return props.catData.slice(0,20).map((el,index) => {
            let _index = index + 1
            return {
                index: _index < 10 ? ('0' + _index) : _index,
                ...el
            }
        })
    });
    return {
      rowData,
      colData,
    };
  },
});
</script>

<style lang="less">
.page13 {
  position: relative;
  background: #ffffff;
  padding: 26px 60px 32px 0;
  .page13-body {
    flex-grow: 1;
    padding: 17px 20px 0 39px;
    .page13-table-header {
      display: flex;
      .page13-table-title {
        font-size: 36px;
      }
      .page13-table-sub-title {
        font-size: 21px;
      }
    }

    .page13-map-tips {
      position: relative;
      width: 100%;
      flex-shrink: 0;
      text-align: right;
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9a9a9a;
      line-height: 28px;
    }
  }
}
</style>
