<template>
  <div class="block page9 f-r f-c">
    <PageHeader
      :title="moduleData['3_9_1_1']"
      :sub-title="moduleData['3_9_1_1_en']"
    />
    <PageTips page-num="06" />
    <div class="page9-body f-r j-b">
      <div class="page9-body-left f-r j-b f-w">
        <div class="page9-border-item box1 f-r j-b" style="height: 260px">
          <div class="group_26 flex-col justify-between">
            <div class="box_3 flex-col ditie"></div>
            <span class="text_3">地铁站</span>
          </div>
          <div class="text-wrapper_2">
            <span class="text_4">
              {{ moduleData["3_9_2_1"].subway_station.val }}
            </span>
            <span class="text_5"></span>
            <span class="text_14">
              {{ moduleData["3_9_2_1"].subway_station.company }}
            </span>
          </div>
        </div>
        <div class="page9-border-item box1 f-r j-b" style="height: 260px">
          <div class="group_26 flex-col justify-between">
            <div class="box_3 flex-col bus"></div>
            <span class="text_3">公交站</span>
          </div>
          <div class="text-wrapper_2">
            <span class="text_4">{{ moduleData["3_9_2_1"].bus_stop.val }}</span>
            <span class="text_5"></span>
            <span class="text_14">
              {{ moduleData["3_9_2_1"].bus_stop.company }}
            </span>
          </div>
        </div>
        <div class="page9-border-item box2 f-r j-b" style="height: 160px">
          <div class="group_4 buxing"></div>
          <div class="image-text_21 f-r j-e">
            <div class="text-group_12 f-c j-b">
              <span class="text_9">
                {{ moduleData["3_9_2_1"].subway_station_walk_time.val
                }}{{ moduleData["3_9_2_1"].subway_station_walk_time.company }}
              </span>
              <span class="text_10">
                {{ moduleData["3_9_2_1"].subway_station_walk_time.tip }}
              </span>
            </div>
            <div class="group_7 ditie_small"></div>
            <div class="group_6 flex-col"></div>
          </div>
        </div>
        <div class="page9-border-item box2 f-r j-b" style="height: 160px">
          <div class="group_4 buxing"></div>
          <div class="image-text_21 f-r j-e">
            <div class="text-group_12 f-c j-b">
              <span class="text_9">
                {{ moduleData["3_9_2_1"].bus_stop_walk_time.val
                }}{{ moduleData["3_9_2_1"].bus_stop_walk_time.company }}
              </span>
              <span class="text_10">
                {{ moduleData["3_9_2_1"].bus_stop_walk_time.tip }}
              </span>
            </div>
            <div class="group_7 bus_small"></div>
            <div class="group_6 flex-col"></div>
          </div>
        </div>
        <div class="page9-border-item box2 f-r j-b" style="height: 160px">
          <div class="group_4 car"></div>
          <div class="image-text_21 f-r j-e">
            <div class="text-group_12 f-c j-b">
              <span class="text_9">
                {{ moduleData["3_9_2_1"].drive_station_time.val
                }}{{ moduleData["3_9_2_1"].drive_station_time.company }}
              </span>
              <span class="text_10">
                {{ moduleData["3_9_2_1"].drive_station_time.tip }}
              </span>
            </div>
            <div class="group_7 huochezhan2"></div>
            <div class="group_6 flex-col"></div>
          </div>
        </div>
        <div class="page9-border-item box2 f-r j-b" style="height: 160px">
          <div class="group_4 car"></div>
          <div class="image-text_21 f-r j-e">
            <div class="text-group_12 f-c j-b">
              <span class="text_9">
                {{ moduleData["3_9_2_1"].drive_airport_time.val
                }}{{ moduleData["3_9_2_1"].drive_airport_time.company }}
              </span>
              <span class="text_10">
                {{ moduleData["3_9_2_1"].drive_airport_time.tip }}
              </span>
            </div>
            <div class="group_7 feiji"></div>
            <div class="group_6 flex-col"></div>
          </div>
        </div>
      </div>
      <div class="page9-body-right">
        <div class="page9-body-right f-c">
          <div class="page9-map" id="page9Map"></div>
          <div class="page9-map-legend f-r a-c">
            <div class="page9-map-legend-item f-r a-c">
              <div class="page9-map-legend-icon icon1"></div>
              机场
            </div>
            <div class="page9-map-legend-item f-r a-c">
              <div class="page9-map-legend-icon icon2"></div>
              火车站
            </div>
            <div class="page9-map-legend-item f-r a-c">
              <div class="page9-map-legend-icon icon3"></div>
              高铁站
            </div>
            <div class="page9-map-legend-item f-r a-c">
              <div class="page9-map-legend-point point1"></div>
              公交站
            </div>
            <div class="page9-map-legend-item f-r a-c">
              <div class="page9-map-legend-point point2"></div>
              地铁站
            </div>
          </div>
          <div class="page9-map-tips">数据来源：位置厂商数据</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "./components/PageHeader";
import PageTips from "./components/PageTips";
import AMapLoader from "@amap/amap-jsapi-loader";
import { shallowRef } from "@vue/reactivity";
import { defineComponent, onMounted } from "vue";
import { mapImage, mapAddCircle, addMapCenter } from "@/utils/pdf";
import { useStore } from "vuex";

import { getMapDelayByMarkerCount } from '@/utils'
import {
  drawMapKeepAlive,
} from '@/api/apiv1'

export default defineComponent({
  components: {
    PageHeader,
    PageTips,
  },
  props: {
    cityInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    catData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    moduleData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();
    let map = shallowRef(null);
    const image = {
      机场: require("@/assets/机场.png"),
      火车站: require("@/assets/火车站.png"),
      高铁站: require("@/assets/高铁站.png"),
    };
    const addMaker = (amap, map, newData) => {
      let markerList = [];
      newData.forEach((el) => {
        el.pois.forEach((v) => {
          if (el.cate_name === "公交站") {
            let marker = new amap.Marker({
              position: new amap.LngLat(
                v.location.split(",")[0],
                v.location.split(",")[1]
              ),
              content: `
                <div style="width: 12px;height: 12px; border-radius: 50%;background: #1644FF;"></div>
              `,
            });
            markerList.push(marker);
          } else if (el.cate_name === "地铁站") {
            let marker = new amap.Marker({
              position: new amap.LngLat(
                v.location.split(",")[0],
                v.location.split(",")[1]
              ),
              content: `
                <div style="width: 12px;height: 12px; border-radius: 50%;background: #FF8116;"></div>
              `,
            });
            markerList.push(marker);
          } else {
            let icon = new amap.Icon({
              size: new amap.Size(20, 21), // 图标尺寸
              image: image[el.cate_name], // Icon的图像
              imageSize: new amap.Size(20, 21), // 根据所设置的大小拉伸或压缩图片
            });
            let marker = new amap.Marker({
              position: new amap.LngLat(
                v.location.split(",")[0],
                v.location.split(",")[1]
              ),
              icon: icon, // 添加 Icon 实例 ，icon也可以是url链接
            });
            markerList.push(marker);
          }
        });
      });
      map.add(markerList);
    };
    onMounted(() => {
      AMapLoader.load({
        key: "f0da9fb8263bb9b657e2257ec09c6df7", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          map = new AMap.Map("page9Map", {
            WebGLParams: {
              preserveDrawingBuffer: true,
            },
            dragEnable: false,
            zoomEnable: false,
            zoom: store.getters.mapZoom, //初始化地图级别
            center: props.cityInfo.location.split(","), //初始化地图中心点位置
          });
          mapAddCircle(props.cityInfo, map);
          addMaker(
            AMap,
            map,
            props.catData.filter((v) => v)
          );
          addMapCenter(AMap, map, props.cityInfo.location)

          // 等待地图渲染
          const totalCount = props.catData.reduce((a, b) => a + (b?.pois?.length || 0), 0)
          const delay = getMapDelayByMarkerCount(totalCount)
          drawMapKeepAlive(delay).catch((e) => {})

          map.setFitView();
        })
        .catch((e) => {
          console.log(e);
        });
    });

    return {
      map,
    };
  },
});
</script>

<style lang="less">
.page9 {
  position: relative;
  background: #ffffff;
  padding: 26px 60px 32px 0;
  .feiji {
    background-image: url("~@/assets/plane.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .ditie {
    background-image: url("~@/assets/subway-1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .ditie_small {
    background-image: url("~@/assets/subway-2.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .buxing {
    background-image: url("~@/assets/walk.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .bus {
    background-image: url("~@/assets/bus-2.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .bus_small {
    background-image: url("~@/assets/bus-1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .car {
    background-image: url("~@/assets/car.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .huochezhan2 {
    background-image: url("~@/assets/train.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .page9-body {
    flex-grow: 1;
    padding: 67px 20px 0 61px;
    .page9-body-left {
      width: 900px;
      .page9-border-item {
        margin-bottom: 90px;
        width: 420px;
        border-radius: 16px;
        border: 2px solid #ff8c00;

        &.box1 {
          display: flex;
          align-items: center;
          justify-content: space-around;

          .group_26 {
            width: 100px;
            height: 145px;

            .box_3 {
              width: 100px;
              height: 100px;
              background-size: 100% 100%;
            }
            .text_3 {
              width: 90px;
              height: 42px;
              overflow-wrap: break-word;
              color: rgba(51, 51, 51, 1);
              font-size: 30px;
              text-align: left;
              white-space: nowrap;
              line-height: 42px;
              margin: 3px 0 0 10px;
            }
          }
          .text-wrapper_2 {
            height: 90px;
            overflow-wrap: break-word;
            font-size: 0;
            font-family: PingFangSC-Medium;
            text-align: left;
            white-space: nowrap;
            line-height: 90px;

            .text_4 {
              width: 107px;
              height: 90px;
              overflow-wrap: break-word;
              color: rgba(51, 51, 51, 1);
              font-size: 64px;
              font-family: PingFangSC-Medium;
              text-align: left;
              white-space: nowrap;
              line-height: 90px;
            }
            .text_5 {
              width: 107px;
              height: 90px;
              overflow-wrap: break-word;
              color: rgba(51, 51, 51, 1);
              font-size: 30px;
              font-family: PingFangSC-Medium;
              text-align: left;
              line-height: 90px;
            }
            .text_14 {
              width: 107px;
              height: 90px;
              overflow-wrap: break-word;
              color: rgba(102, 102, 102, 1);
              font-size: 20px;
              text-align: left;
              line-height: 90px;
            }
          }
        }

        &.box2 {
          .group_4 {
            width: 67px;
            height: 67px;
            margin: 47px 0 0 50px;
          }
          .image-text_21 {
            position: relative;
            width: 243px;
            height: 79px;
            margin: 41px 50px 0 0;
            .text-group_12 {
              width: 144px;
              height: 79px;
              margin-left: 15px;
              .text_9 {
                width: 63px;
                height: 33px;
                overflow-wrap: break-word;
                color: rgba(51, 51, 51, 1);
                font-size: 24px;
                font-family: PingFangSC-Medium;
                text-align: center;
                white-space: nowrap;
                line-height: 33px;
                margin-left: 41px;
              }
              .text_10 {
                width: 144px;
                height: 33px;
                overflow-wrap: break-word;
                color: rgba(102, 102, 102, 1);
                font-size: 24px;
                text-align: center;
                white-space: nowrap;
                line-height: 33px;
                margin-top: 13px;
              }
            }
            .group_7 {
              width: 67px;
              height: 67px;
              margin: 6px 0 0 17px;
            }
            .group_6 {
              position: absolute;
              left: 0;
              top: 40px;
              width: 166px;
              height: 2px;
              background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng1f030905ed6e57057ebbf44fc380b377d653eb3265a96ddd78703e338631c593)
                100% no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      }
    }
    .page9-body-right {
      width: 853px;
      .page9-map {
        width: 100%;
        height: 760px;
      }
      .page9-map-legend {
        flex-grow: 1;
        padding: 23px 0 23px 46px;
        .page9-map-legend-item {
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 28px;
          margin-right: 76px;
        }
        .page9-map-legend-item:last-child {
          margin-right: 0;
        }
        .page9-map-legend-icon {
          width: 22px;
          height: 22px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-right: 12px;
        }
        .icon1 {
          background-image: url("~@/assets/机场.png");
        }
        .icon2 {
          background-image: url("~@/assets/火车站.png");
        }
        .icon3 {
          background-image: url("~@/assets/高铁站.png");
        }
        .page9-map-legend-point {
          width: 22px;
          height: 22px;
          margin-right: 12px;
          border-radius: 50%;
        }
        .point1 {
          background-color: #1644ff;
        }
        .point2 {
          background-color: #ff8116;
        }
      }
      .page9-map-tips {
        flex-shrink: 0;
        text-align: right;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9a9a9a;
        line-height: 28px;
      }
    }
  }
}
</style>
