import axios from "axios";
import store from "@/store";

const http = axios.create({
  baseURL: "",
});

// 全局请求拦截器
http.interceptors.request.use((config) => {
  if (store.state.token) {
    config.headers.Authorization = 'Bearer ' + store.state.token
  }
  return config;
});

// 全局响应拦截器
http.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default http;
