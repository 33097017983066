6y<template>
  <div class="block page6 f-c">
    <PageHeader
      :title="moduleData['2_6_1_1']"
      :sub-title="moduleData['2_6_1_1_en']"
    />
    <PageTips page-num="04"/>
    <div class="page6-body f-r">
      <div class="page6-left">
        <div class="page6-score-top"></div>
        <div class="page6-score f-c a-c j-c">
          <div class="page6-score-title">综合评分</div>
          <div class="page6-score-value">
            {{ moduleData.total_score }}
          </div>
        </div>
        <div class="page6-metric-list">
          <div
            class="page6-metric-item"
            v-for="(item, index) in moduleData['2_6_3_3']"
            :key="index"
          >
            <div class="page6-metric-title">
              {{ item.title }}
            </div>
            <div class="page6-metric-desc">
              {{ item["0"] }}
            </div>
          </div>
        </div>
      </div>
      <div class="page6-right">
        <RadarChart :chart-data="moduleData.value_analysis_index"/>

        <div class="page6-map-tips" style="margin-top: 20px;">
          数据来源：国家统计局社会发展公报整合，以上数据为最新年份统计数据
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import PageHeader from "@/views/Report/components/PageHeader";
import PageTips from "@/views/Report/components/PageTips";
import RadarChart from "@/views/Report/components/RadarChart";

export default defineComponent({
  components: {
    PageHeader,
    PageTips,
    RadarChart
  },
  props: {
    moduleData: {
      type: Object,
      default: () => {}
    },
  },
  setup(props) {
    const colData = ref([
      { prop: "id", width: "109px", label: "序号" },
      { prop: "circle_name", width: "538px", label: "商圈名称" },
      { prop: "district", width: "279px", label: "所属区" },
      {
        prop: "business_circle_positioning",
        width: "408.63px",
        label: "商圈定位",
      },
      { prop: "business_num", width: "408.63px", label: "覆盖商圈数量" },
    ]);
    const rowData = computed(() => {
      return props.moduleData["2_5_4_4"];
    });
    return {
      rowData,
      colData,
    };
  },
});
</script>

<style lang="less">
.page6 {
  position: relative;
  background: #ffffff;
  padding: 26px 60px 32px 0;
  .page6-body {
    flex-grow: 1;
    padding: 17px 20px 0 39px;
    .page6-left {
      width: 595px;
      margin-right: 53px;
      .page6-score-top {
        width: 100%;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        height: 15px;
        background: #FFDB00;
      }
      .page6-score {
        width: 100%;
        height: 217px;
        background: #333333;
        border-radius: 25px;
        margin-bottom: 25px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        .page6-score-title {
          height: 50px;
          font-size: 36px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 50px;
        }
        .page6-score-value {
          font-size: 90px;

          font-weight: bold;
          color: #ffffff;
          line-height: 126px;
        }
        .page6-metric-list {
          margin-top: 26px;
        }
      }
      .page6-metric-item {
        margin-bottom: 30px;
        .page6-metric-title {
          font-size: 28px;

          font-weight: bold;
          color: #333333;
          line-height: 40px;
        }
        .page6-metric-desc {
          font-size: 22px;
          color: #333333;
          line-height: 40px;
        }
      }
    }
    .page6-right {
      width: 1126px;
      height: 877px;
      background: #ffffff;
      border-radius: 23px;
      border: 1px dashed #333333;
    }
  }
  .page6-map-tips {
    width: 100%;
    padding-right: 20px;
    flex-shrink: 0;
    text-align: right;
    height: 28px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9a9a9a;
    line-height: 28px;
  }
}
</style>
