<template>
  <div class="block page4 f-c">
    <PageHeader
      :title="moduleData['2_3_1_1']"
      :sub-title="moduleData['2_3_1_1_en']"
    />
    <PageTips page-num="16"/>
    <div class="page4-body f-r j-b">
      <div class="page4-body-left">
        <!--    交通概况    -->
        <TableNav width="899px">
          <template v-slot:title>{{ moduleData["2_4_1_1"] }}</template>
        </TableNav>
        <ETable
          style="font-size: 18px"
          :table-data="tableData1"
          cell-width="224px"
        />
        <!--    城市商业    -->
        <TableNav width="899px">
          <template v-slot:title>{{ moduleData["2_4_2_2"] }}</template>
        </TableNav>
        <ETable :table-data="tableData2" cell-width="224px" />
        <!--    消费指标    -->
        <TableNav width="899px">
          <template v-slot:title>{{ moduleData["2_4_3_3"] }}</template>
        </TableNav>
        <div class="etable f-r">
          <div class="etable-wrapper" style="width: 100%">
            <table>
              <tbody>
              <tr>
                <td style="width: 225px">
                  <div class="cell">
                    {{
                      moduleData.urban_consumption.urban_consumption_active[0]
                    }}
                  </div>
                </td>
                <td style="width: 225px">
                  <div class="cell f-c a-c j-c">
                    <div>
                      {{ moduleData.urban_consumption.urban_consumption_active[1] }}
                    </div>
                    <div class="color-red f-14">
                      （全国排名 -
                      {{ moduleData.urban_consumption.urban_consumption_active[3] }}
                      -）
                    </div>
                  </div>
                </td>

                <td style="width: 225px">
                  <div class="cell">
                    {{
                      moduleData.urban_consumption.urban_consumption_diversity[0]
                    }}
                  </div>
                </td>
                <td style="width: 225px">
                  <div class="cell f-c a-c j-c">
                    <div>
                      {{ moduleData.urban_consumption.urban_consumption_diversity[1] }}
                    </div>
                    <div class="color-red f-14">
                      （全国排名 -
                      {{ moduleData.urban_consumption.urban_consumption_diversity[3] }}
                      -）
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td style="width: 225px; height: 70px">
                  <div class="cell">
                    {{ moduleData["2_4_4_4"] }}
                  </div>
                </td>
                <td colspan="3" style="height: 70px; padding: 0 64px">
                  <div
                    class="cell f-c a-c j-c"
                    style="text-align: left; line-height: 25px"
                  >
                    {{ moduleData.city_impress.join("、") }}......
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="page4-body-right f-c">
        <div class="page4-map" id="page4Map"></div>
        <div class="page4-map-legend f-r a-c">
          <div class="page4-map-legend-item f-r a-c">
            <div class="page4-map-legend-icon icon1"></div>
            机场
          </div>
          <div class="page4-map-legend-item f-r a-c">
            <div class="page4-map-legend-icon icon2"></div>
            火车站
          </div>
          <div class="page4-map-legend-item f-r a-c">
            <div class="page4-map-legend-point point2"></div>
            地铁站
          </div>
        </div>
        <div class="page4-map-tips">
          数据来源：位置厂商数据，边界商业数据整合
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted } from "vue";
import ETable from "@/views/Report/components/ETable";
import PageHeader from "@/views/Report/components/PageHeader";
import PageTips from "./components/PageTips";
import TableNav from "@/views/Report/components/TableNav";

import AMapLoader from "@amap/amap-jsapi-loader";
import { shallowRef } from "@vue/reactivity";
import { useStore } from "vuex";
import { getCityKm } from "@/api/apiv1";

import { getMapDelayByMarkerCount } from '@/utils'
import {
  drawMapKeepAlive,
} from '@/api/apiv1'

export default defineComponent({
  components: {
    ETable,
    PageHeader,
    PageTips,
    TableNav,
  },
  props: {
    cityInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    catData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    detailData: {
      type: Array,
      default: () => [],
    },
    moduleData: {
      type: Object,
      default: () => {}
    },
  },
  setup(props) {
    const store = useStore()
    const tableData1 = computed(() => {
      return [
        [
          [
            props.moduleData.traffic_overview.subway_lines[0],
            props.detailData[0] +
            " " +
            props.moduleData.traffic_overview.subway_lines[2],
          ],
          [
            props.moduleData.traffic_overview.train_station[1],
            props.detailData[1] +
            " " +
            props.moduleData.traffic_overview.train_station[3],
          ],
          [
            props.moduleData.traffic_overview.high_speed_rail_direct_num[0],
            props.moduleData.traffic_overview.high_speed_rail_direct_num[1] +
            " " +
            props.moduleData.traffic_overview.high_speed_rail_direct_num[2],
          ],
          [
            props.moduleData.traffic_overview.city_access[0],
            props.moduleData.traffic_overview.city_access[1] +
            " " +
            props.moduleData.traffic_overview.city_access[2],
          ],
        ],
        [
          [
            props.moduleData.traffic_overview.subway_num[1],
            props.detailData[2] +
            " " +
            props.moduleData.traffic_overview.subway_num[3],
          ],
          [
            props.moduleData.traffic_overview.airport[1],
            props.detailData[3] +
            " " +
            props.moduleData.traffic_overview.airport[3],
          ],
          [
            props.moduleData.traffic_overview.high_speed_rail_degree[0],
            props.moduleData.traffic_overview.high_speed_rail_degree[1]
          ],
          [],
        ],
      ];
    });
    const tableData2 = computed(() => {
      return [
        [
          [
            props.moduleData.urb_comme[0][0],
            props.moduleData.urb_comme[0][1] +
            " " +
            props.moduleData.urb_comme[0][2],
          ],
          [
            props.moduleData.urb_comme[2][0],
            props.moduleData.urb_comme[2][1] +
            " " +
            props.moduleData.urb_comme[2][2],
          ],
          [
            props.moduleData.urb_comme[4][0],
            props.moduleData.urb_comme[4][1] +
            " " +
            props.moduleData.urb_comme[4][2],
          ],
        ],
        [
          [
            props.moduleData.urb_comme[1][0],
            props.moduleData.urb_comme[1][1] +
            " " +
            props.moduleData.urb_comme[1][2],
          ],
          [
            props.moduleData.urb_comme[3][0],
            props.moduleData.urb_comme[3][1] +
            " " +
            props.moduleData.urb_comme[3][2],
          ],
          [
            props.moduleData.urb_comme[5][0],
            props.moduleData.urb_comme[5][1] +
            " " +
            props.moduleData.urb_comme[5][2],
          ],
        ],
      ];
    });

    let map = shallowRef(null);

    const image = {
      P050101: require("@/assets/机场.png"),
      P050201: require("@/assets/火车站.png"),
    };
    const addMaker = (amap, map, newData) => {
      let markerList = [];
      newData.forEach((el) => {
        if (el.poi) {
          el.poi.forEach((v) => {
            if (el.bhProType === "P050301") {
              // 地铁站
              let marker = new amap.Marker({
                position: new amap.LngLat(
                  v.location.split(",")[0],
                  v.location.split(",")[1]
                ),
                content: `
                <div style="width: 6px;height: 6px; border-radius: 50%;background: #FF8116;"></div>
              `,
              });
              markerList.push(marker);
            } else {
              let icon = new amap.Icon({
                size: new amap.Size(20, 21), // 图标尺寸
                image: image[el.bhProType], // Icon的图像
                imageSize: new amap.Size(20, 21), // 根据所设置的大小拉伸或压缩图片
              });
              let marker = new amap.Marker({
                position: new amap.LngLat(
                  v.location.split(",")[0],
                  v.location.split(",")[1]
                ),
                icon: icon, // 添加 Icon 实例 ，icon也可以是url链接
              });
              markerList.push(marker);
            }
          });
        }
      });
      map.add(markerList);
    };
    onMounted(async ()=> {
      const radiusData = await getCityKm(store.state.baseReport.module1.district_no)
      AMapLoader.load({
        key: "f0da9fb8263bb9b657e2257ec09c6df7", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          map = new AMap.Map("page4Map", {
            WebGLParams: {
              preserveDrawingBuffer: true
            },
            zoom: store.getters.mapZoom, //初始化地图级别
            dragEnable: false,
            zoomEnable: false,
            center: props.cityInfo.location.split(","), //初始化地图中心点位置
          });

          // 等待地图渲染
          const totalCount = props.catData.reduce((a, b) => a + (b?.count || 0), 0)
          const delay = getMapDelayByMarkerCount(totalCount)
          drawMapKeepAlive(delay).catch((e) => {
          })

          addMaker(AMap, map, props.catData.filter(v => v));

          map.setFitView()
        })
        .catch((e) => {
          console.log(e);
        });
    });
    return {
      tableData1,
      tableData2,
      map,
    };
  },
});
</script>

<style lang="less">
.page4 {
  position: relative;
  background: #ffffff;
  padding: 26px 60px 32px 0;

  .page4-body {
    flex-grow: 1;
    padding: 17px 20px 0 39px;

    .page4-body-left {
      width: 900px;
    }

    .page4-body-right {
      width: 853px;

      .page4-map {
        width: 100%;
        height: 760px;
      }

      .page4-map-legend {
        flex-grow: 1;
        padding: 23px 46px;

        .page4-map-legend-item {
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 28px;
          margin-right: 76px;
        }

        .page4-map-legend-icon {
          width: 22px;
          height: 22px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-right: 12px;
        }

        .icon1 {
          background-image: url("~@/assets/机场.png");
        }

        .icon2 {
          background-image: url("~@/assets/火车站.png");
        }

        .icon3 {
          background-image: url("~@/assets/高铁站.png");
        }

        .page4-map-legend-point {
          width: 22px;
          height: 22px;
          margin-right: 12px;
          border-radius: 50%;
        }

        .point1 {
          background-color: #1644ff;
        }

        .point2 {
          background-color: #ff8116;
        }
      }

      .page4-map-tips {
        flex-shrink: 0;
        text-align: right;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9a9a9a;
        line-height: 28px;
      }
    }
  }
}
</style>
