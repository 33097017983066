import { createRouter, createWebHistory } from "vue-router";
import Report from "@/views/Report";
import ReportV2 from "@/views/ReportV2";
import store from "@/store";

const routes = [
  {
    path: "/report",
    component: Report,
  },
  {
    path: "/reportV2",
    component: ReportV2,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.query && to.query.token) {
    store.commit('SET_TOKEN', to.query.token)
  }
  next()
})

export default router;
