<template>
  <div class="block page11 f-c">
    <PageHeader :title="moduleData['3_9_2_1']" :sub-title="moduleData['3_9_2_1_en']"/>
    <PageTips page-num="08"/>
    <div class="page11-body f-c">
      <TableNav width="1774px" style="height: 70px; margin-bottom: 25px">
        <template v-slot:title>
          <div class="page11-table-header f-r j-b">
            <div class="page11-table-title">
              距离最近调研地住宅小区信息（最多展示TOP 20）
            </div>
            <div class="page11-table-sub-title">
              总住宅项目数： {{ catData.count || 0 }} 个
            </div>
          </div>
        </template>
      </TableNav>
      <div style="width: 1774px" class="f-r j-b">
        <PTable :col-data="colData" :row-data="rowData.slice(0,10)" style="height: 706px">
          <Empty text="当前区域暂无住宅覆盖" v-if="!rowData.length"/>
        </PTable>
        <PTable style="height: 706px" :col-data="colData" :row-data="rowData.slice(10,20)" >
          <Empty text="当前区域暂无住宅覆盖" v-if="!rowData.length"/>
        </PTable>
      </div>
      <div style="flex-grow: 1;padding-right: 22px" class="page11-map-tips f-c j-e">
        <div class="page-tips2" style="padding-bottom: 27px;">>更多住宅分布于APP「数据地图/市调详情_周边配套」中查看</div>
        数据来源：边界房产大数据，房产平台数据整合
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import PageHeader from "@/views/Report/components/PageHeader";
import PageTips from "@/views/Report/components/PageTips";
import TableNav from "@/views/Report/components/TableNav";
import PTable from "./components/PTable";

export default defineComponent({
  components: {
    PageHeader,
    PageTips,
    TableNav,
    PTable,
  },
  props: {
      catData: {
          type: Object,
          default: () => {
              return {
                count: 0,
                pois: []
              };
          },
      },
    moduleData: {
      type: Object,
      default: () => {
        return {
        };
      },
    },
  },
  setup(props) {
    const colData = ref([
      { prop: "index", width: "67px", label: "序号" },
      { prop: "poi_type", width: "123px", label: "类型" },
      { prop: "name", width: "203px", label: "小区名称" },
      {
        prop: "households",
        width: "110px",
        label: "户数",
        subLabel: "(户)",
      },
      {
        prop: "housing_price",
        width: "123px",
        label: "房价",
          subLabel: "(元/㎡)",
      },
      {
        prop: "property_fee",
        width: "143px",
        label: "物业费",
          subLabel: "(元/㎡)",
      },
      { prop: "distance", width: "87px", label: "距离" , subLabel: "km"},
    ]);
    const rowData = computed(() => {
      if (!props.catData.pois) return []
      return props.catData.pois.map((el,index) => {
          let _index = index + 1
          return {
              index: _index < 10 ? ('0' + _index) : _index,
              ...el
          }
      })
    });
    return {
      rowData,
      colData,
    };
  },
});
</script>

<style lang="less">
.page11 {
  position: relative;
  background: #ffffff;
  padding: 26px 60px 32px 0;
  .page11-body {
    flex-grow: 1;
    padding: 17px 20px 0 39px;
    .page11-table-header {
      display: flex;
      .page11-table-title {
        font-size: 36px;
      }
      .page11-table-sub-title {
        font-size: 21px;
      }
    }
    .page11-map-tips {
      width: 100%;
      flex-shrink: 0;
      text-align: right;
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9a9a9a;
      line-height: 28px;
    }
  }
}
</style>
