<template>
  <div class="block page5 f-c">
    <PageHeader
      :title="moduleData['2_5_1_1']"
      :sub-title="moduleData['2_5_1_1_en']"
    />
    <PageTips page-num="17"/>
    <div class="page5-body f-c">
      <TableNav width="1749px" style="height: 70px; margin-bottom: 25px">
        <template v-slot:title>
          <div class="page5-table-header">
            <div class="page5-table-title">
              {{ moduleData["2_5_2_2_h1"] }}
            </div>
            <div class="page5-table-sub-title">
              {{ moduleData["2_5_2_2_h2"] }}
            </div>
          </div>
        </template>
      </TableNav>
      <div style="flex-grow: 1; width: 1749px;display: flex;flex-direction: column;justify-content: space-between">
        <PTable :col-data="colData" :row-data="rowData" style="min-height: 706px">
          <Empty text="当前区域暂无商圈覆盖" v-if="!rowData.length"/>
        </PTable>
        <div class="page-tips2">>更多详情于APP「商圈调研」中查看</div>
      </div>
      <div class="page5-map-tips">
        数据来源：全网商业数据整合
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import PageHeader from "@/views/Report/components/PageHeader";
import PageTips from "./components/PageTips";
import TableNav from "@/views/Report/components/TableNav";
import PTable from "./components/PTable";

export default defineComponent({
  components: {
    PageHeader,
    PageTips,
    TableNav,
    PTable,
  },
  props: {
    moduleData: {
      type: Object,
      default: () =>{}
    },
  },
  setup(props) {
    const colData = ref([
      { prop: "id", width: "109px", label: "序号" },
      { prop: "circle_name", width: "538px", label: "商圈名称" },
      { prop: "district", width: "279px", label: "所属区" },
      {
        prop: "business_circle_positioning",
        width: "408.63px",
        label: "商圈定位",
      },
      { prop: "business_num", width: "408.63px", label: "覆盖商圈数量" },
    ]);
    const rowData = computed(() => {
      return props.moduleData["2_5_4_4"].slice(0,10);
    });
    return {
      rowData,
      colData,
    };
  },
});
</script>

<style lang="less">
.page5 {
  position: relative;
  background: #ffffff;
  padding: 26px 60px 32px 0;
  .page5-body {
    flex-grow: 1;
    padding: 17px 20px 0 39px;
    .page5-table-header {
      display: flex;
      .page5-table-title {
        font-size: 36px;
      }
      .page5-table-sub-title {
        font-size: 21px;
      }
    }
    .ptable {
      tbody {
        tr:first-child {
          td {
            color: #f40000 !important;
            font-weight: bold;
          }
        }
        tr td:first-child {
          color: #333333 !important;
        }
      }
    }
    .page5-map-tips {
      width: 100%;
      flex-shrink: 0;
      text-align: right;
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9a9a9a;
      line-height: 28px;
    }
  }
}
</style>
