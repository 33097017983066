<template>
  <div class="block page24">
    <div class="code">
      <img :src="'/api' + moduleData.qr_code" alt="">
    </div>
    <PageTips />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import PageTips from "./components/PageTips";
export default defineComponent({
  components: { PageTips },
  props: {
    moduleData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
});
</script>

<style lang="less" scoped>
.page24 {
  background: #333333;
  position: relative;
  .code {
    width: 175px;
    height: 175px;
    background: #FFFFFF;
    position: absolute;
    right: 134px;
    bottom: 33px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
