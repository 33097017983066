<template>
  <div class="block page17 f-r f-c">
    <PageHeader :title="moduleData['3_17_1_1']" :sub-title="moduleData['3_17_1_1_en']"/>
    <PageTips page-num="11"/>
    <div class="page17-body f-r j-b">
      <div class="page17-body-left">
        <div class="page17-body-header f-r j-b a-c">
          <div class="page17-body-header-left">周边酒店 共 {{ totalSize }} 家</div>
          <div class="page17-body-header-right">平均价格：{{ order_price_avg }}</div>
        </div>
        <TableNav width="900px">
          <template v-slot:title> 距离调研地最近的酒店（最多展示各TOP5） </template>
        </TableNav>
        <PTable :col-data="colData" :row-data="rowData1" style="min-height: 500px">
          <Empty text="当前区域暂无酒店覆盖" v-if="!rowData1.length"/>
        </PTable>
        <div class="page-tips2">>当前图表中仅展示部分分类，全部酒店数据于APP「周边配套」中查看</div>

<!--    柱图放不下 先隐藏    -->
<!--        <div class="page17-body-header f-r j-b a-c" style="margin-top: 30px">-->
<!--          <div class="page17-body-header-left">区域酒店分布数量（家）</div>-->
<!--        </div>-->
<!--        <BarChart style="height: 200px; width: 100%"-->
<!--            :color="rowData1.map(v => v.color)"-->
<!--            :x-data="rowData1.map(v => v.name)"-->
<!--            :y-data="rowData1.map(v => v.value)">-->
<!--          <Empty text="当前区域暂无酒店覆盖" v-if="!rowData1.length"/>-->
<!--        </BarChart>-->

      </div>
      <div class="page17-body-right">
        <div class="page17-body-right f-c">
          <div class="page17-map" id="page17Map">
            <Empty text="当前区域暂无酒店覆盖" v-if="!rowData1.length"/>
          </div>
          <div class="page17-map-legend f-r a-c f-w">
            <div
              class="page17-map-legend-item f-r a-c"
              v-for="(item, index) in typeList"
              :key="index"
            >
              <div
                class="page17-map-legend-point point1"
                :style="{ background: item.color }"
              ></div>
              {{ item.name }}
            </div>

          </div>
          <div class="page17-map-tips">
            数据来源：旅游平台边界酒旅大数据整合
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "./components/PageHeader";
import PageTips from "./components/PageTips";
import TableNav from "./components/TableNav";
import PTable from "./components/PTable2";
import BarChart from "./components/BarChart2";

import AMapLoader from "@amap/amap-jsapi-loader";
import { shallowRef } from "@vue/reactivity";

import { computed, defineComponent, onMounted, ref } from "vue";
import { mapAddCircle, addMapCenter } from "@/utils/pdf";
import { useStore } from "vuex";

import { getMapDelayByMarkerCount } from '@/utils'
import {
  drawMapKeepAlive,
} from '@/api/apiv2'

export default defineComponent({
  components: {
    PageHeader,
    PageTips,
    TableNav,
    PTable,
    BarChart,
  },
  props: {
    cityInfo: {
        type: Object,
        default: () => {
            return {};
        },
    },
    catData: {
        type: Object,
        default: () => {
            return []
        },
    },
    moduleData: {
      type: Object,
      default: () => {
        return {
        };
      },
    },
    // poi改造后数据
    poiData: {
      type: Object,
      default: () => {},
    },
  },
    setup(props) {
      const color = [
        "#0045FF",
        "#FF7800",
        '#FF0000',
        '#17C49D',
        '#00BEFF',
        '#A933E4',
        '#00FF19',
        '#DACE10',
        '#6694CF',
        '#F53A99',
        '#A1C607',
        '#6005B1',
        '#FF5E87',
      ];
    const store = useStore()
      const colData = ref([
          { prop: "name", width: "147px", label: "类型" },
          { prop: "names", width: "497px", label: "距离项目最近酒店" },
          { prop: "order_price_avg", width: "136px", label: "平均价格" },
        { prop: "count", width: "118px", label: "数量" },
      ]);

      const rowData1 = computed(() => {
        return props.catData.map((el, index) => {
          return {
            ...el,
            type: el.bhProType,
            name: el.bhProTypeName,
            names: el.poi.slice(0,5).map(el => el.name).join('、'),
            value: el.poi.length,
            order_price_avg: (el.hotelPriceAvg || '--') + ' 元/晚',
            poi: el.poi || [],
            color: color[index],
          }
        })
      });

      let map = shallowRef(null);
      const addMaker = (amap, map, data, color) => {
        let markerList = [];
        data.forEach((v) => {
          let content = `<div style="width: 12px;height: 12px; border-radius: 50%;background: ${color};"></div>`
          let marker = new amap.Marker({
            position: new amap.LngLat(
              v.location.split(",")[0],
              v.location.split(",")[1]
            ),
            content
          });
          markerList.push(marker);
        });
        map.add(markerList);
      };
      onMounted(() => {
          AMapLoader.load({
              key: "f0da9fb8263bb9b657e2257ec09c6df7", // 申请好的Web端开发者Key，首次调用 load 时必填
              version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
              plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
          }).then((AMap) => {
            map = new AMap.Map("page17Map", {
              WebGLParams: {
                  preserveDrawingBuffer:true
              },
              dragEnable: false,
              zoomEnable: false,
              zoom: store.getters.mapZoom, //初始化地图级别
              center: props.cityInfo.location.split(","), //初始化地图中心点位置
            });

            mapAddCircle(props.cityInfo, map)
            addMapCenter(AMap, map, props.cityInfo.location)

            addMaker(AMap, map, rowData1.value.find(el => el.type === 'P030101')?.poi || [], '#0045FF')
            addMaker(AMap, map, rowData1.value.find(el => el.type === 'P030201')?.poi || [], '#FF7800')
            addMaker(AMap, map, rowData1.value.find(el => el.type === 'P030301')?.poi || [], '#FF0000')
            addMaker(AMap, map, rowData1.value.find(el => el.type === 'P030401')?.poi || [], '#17C49D')
            addMaker(AMap, map, rowData1.value.find(el => el.type === 'P030501')?.poi || [], '#00BEFF')
            addMaker(AMap, map, rowData1.value.find(el => el.type === 'P030601')?.poi || [], '#A933E4')
            addMaker(AMap, map, rowData1.value.find(el => el.type === 'P030701')?.poi || [], '#00FF19')

            // 等待地图渲染
            const totalCount = rowData1.value.reduce((a, b) => a + (b?.poi?.length || 0), 0)
            const delay = getMapDelayByMarkerCount(totalCount)
            drawMapKeepAlive(delay).catch((e) => {})

            map.setFitView()
        })
        .catch((e) => {
            console.log(e);
        });
      });

      const typeList = props.catData.map((item, index) => ({
        name: item.bhProTypeName,
        color: color[index],
      }))

      // poi改版
      const totalSize = computed(() => {
        if (!props.poiData || !props.poiData?.nearby?.length) return 0
        const item = props.poiData.nearby.find((n) => n.bhProType === 'P030000')
        return item?.count || 0
      })

      const order_price_avg = computed(() => {
        if (!rowData1.value.length) return '--'
        const length = rowData1.value.filter((item) => !!item.hotelPriceAvg && item.hotelPriceAvg !== '--').length
        if (!length) return '--'
        let value =  rowData1.value.map(el => el?.hotelPriceAvg || 0).reduce((pre,next) => {
          return pre + next
        }, 0) / length
        if (value === 0) return '--'
        return value.toFixed(0) + ' 元/晚'
      })

      return {
        map,
        colData,
        rowData1,
        totalSize,
        typeList,
        order_price_avg,
      };
    },
});
</script>

<style lang="less">
.page17 {
  position: relative;
  background: #ffffff;
  padding: 26px 60px 32px 0;
  .page17-body {
    flex-grow: 1;
    padding: 67px 20px 0 61px;
    .page17-body-left {
      width: 900px;
      .page17-body-header {
        height: 33px;
        font-size: 24px;

        font-weight: bold;
        color: #333333;
        line-height: 33px;
        margin-bottom: 10px;
      }

      .page-tips2 {
        margin-top: 12px;
      }
    }
    .page17-body-right {
      width: 853px;
      .page17-map {
        position: relative;
        width: 100%;
        height: 760px;
      }
      .page17-map-legend {
        flex-grow: 1;
        padding: 23px 0 23px 46px;
        .page17-map-legend-item {
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 28px;
          margin-right: 76px;
        }
        .page17-map-legend-item:last-child {
          margin-right: 0;
        }
        .page17-map-legend-icon {
          width: 22px;
          height: 22px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-right: 12px;
        }
        .icon1 {
          background-image: url("~@/assets/机场.png");
        }
        .icon2 {
          background-image: url("~@/assets/火车站.png");
        }
        .icon3 {
          background-image: url("~@/assets/高铁站.png");
        }
        .page17-map-legend-point {
          width: 22px;
          height: 22px;
          margin-right: 12px;
          border-radius: 50%;
        }

        .point1 {
          background-color: #0045FF;
        }
      }
      .page17-map-tips {
        flex-shrink: 0;
        text-align: right;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9a9a9a;
        line-height: 28px;
      }
    }
  }
}
</style>
