<template>
  <div class="point-chart">
    <slot></slot>
    <VChart style="width: 100%; height: 100%" :option="option" autoresize></VChart>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
export default defineComponent({
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    unit: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const option = computed(() => {
      return {
        grid: {
          top: 10,
          left: 0,
          right:30,
          bottom: 0,
          containLabel: true,
        },
        xAxis: {
          axisLabel: {
            formatter: "{value}m",
            color: "#000000",
            fontSize: 12,
          },
          axisLine: {
            lineStyle: {
              color: "#000",
            },
          },
          splitLine: {
            lineStyle: {
              color: "rgba(0,0,0,0.15)",
            },
          },
          axisTick: { show: false },
        },
        yAxis: {
          min: function(value) {
            return value.min
          },
          axisLabel: {
            formatter: "{value}" + props.unit,
            color: "#000000",
            fontSize: 12,
          },
          axisLine: {
            lineStyle: {
              color: "#000",
            },
          },
          splitLine: {
            lineStyle: {
              color: "rgba(0,0,0,0.15)",
            },
          },
          axisTick: { show: false },
        },
        series: [
          {
            symbolSize: 8,
            data: props.chartData,
            type: "scatter",
          },
        ],
      };
    });
    return {
      option,
    };
  },
});
</script>

<style lang="less">
.point-chart {
  height: 100%;
  padding: 12px;
  background: #f6f7fa;
  position: relative;
  border: 2px solid #333333;
  border-top: none;
}
</style>
