<template>
  <div class="bar-chart3">
    <slot></slot>
    <VChart
      style="width: 100%; height: 100%"
      :option="option"
      autoresize
    ></VChart>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
export default defineComponent({
  props: {
    barWidth: {
      type: Number,
      default: 32,
    },
    color: {
      type: String,
      default: "#FF8C00",
    },
    xData: {
      type: Array,
      default: () => [""],
    },
    yData: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const option = computed(() => {
      return {
        grid: {
          top: 25,
          left: 0,
          right: 20,
          bottom: 0,
          containLabel: true,
        },
        yAxis: {
          type: "value",
          axisTick: { show: false },
          axisLine: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            color: "#000000",
          },
          splitLine: {
            lineStyle: {
              color: "rgba(0,0,0,0.15)",
            },
          },
        },
        xAxis: {
          type: "category",
          data: props.xData,
          axisLabel: {
            fontSize: 14,
            color: "#000000",
            interval: 0
          },
          axisTick: { show: false },
          axisLine: {
            lineStyle: {
              color: "rgba(0,0,0,0.15)",
            },
          },
        },
        series: [
          {
            type: "bar",
            data: props.yData.map((el, index) => {
              return {
                name: props.xData[index],
                value: el,
                itemStyle: {
                  color: props.color,
                },
              };
            }),
            barMaxWidth: props.barWidth,
            label: {
              show: true,
              position: "top",
              fontSize: 20,
              color: "#000",
            },
          },
        ],
      };
    });
    return {
      option,
    };
  },
});
</script>

<style lang="less">
.bar-chart3 {
  height: 100%;
  position: relative;
}
</style>
