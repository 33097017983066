<template>
  <div class="block page21 f-r f-c j-b">
    <PageHeader :title="moduleData['3_21_1_1']" :sub-title="moduleData['3_21_1_1_en']" />
    <PageTips page-num=""/>
    <div class="page21-logo f-r a-c">
      <div class="box_9 f-c j-b">
        <span class="text_3">业态类型/品牌推荐</span>
        <div class="group_5"></div>
      </div>
      <div class="group_6 f-r">
        <div class="section_2"></div>
        <span class="text_4">儿童亲子</span>
      </div>
    </div>
    <div class="page21-body f-c j-b">
      <div class="body-desc">
       {{ catData.condition_qinzi_str }}
      </div>
      <div class="f-g f-c j-b">
        <div
          class="page21-box f-c a-c j-c"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="page21-box-title">
            {{ item.title }}
          </div>
          <div class="page21-box-items f-r a-c">
            <div
              class="page21-box-item f-r a-c"
              v-for="(sitem, sindex) in item.list"
              :key="sindex"
            >
              <div class="page21-box-img f-r a-c j-c">
                <img style="width: 100%;height: 100%" :src="sitem.icon" alt="" />
              </div>
              <div class="page21-box-text f-c j-c">
                <div>
                  {{ sitem.name }}
                </div>
                <div>
                  {{ sitem.subname }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <span></span>
        <div class="page21-tips">
          *推荐的品牌为【边界品牌库】已收录的品牌，通过
          大数据模型推荐，排名不分先后。入驻【边界品牌库】请联系，商务企业微信：{{ phone }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "./components/PageHeader";
import PageTips from "./components/PageTips";

import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  components: {
    PageHeader,
    PageTips,
  },
  props: {
      catData: {
          type: Object,
          default: () => {
              return {
              };
          },
      },
    moduleData: {
      type: Object,
      default: () => {
        return {

        };
      },
    },
  },
  setup(props) {
    const store = useStore()
    const list = computed(() => {
        return props.catData.tuijian_qinzi || []
    });
    const phone = computed(() => {
      return store.state.baseReport.tel
    })
    return {
      phone,
      list,
    };
  },
});
</script>

<style lang="less">
.page21 {
  position: relative;
  background: #ffffff;
  padding: 26px 60px 32px 0;
  .page21-body {
    flex-grow: 1;
    padding: 54px 76px 0 68px;
    .body-desc {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #454955;
      line-height: 43px;
    }
    .page21-tips {
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #454955;
      line-height: 25px;
      text-align: right;
      width: 100%;
      margin-top: 63px;
    }
  }
  .page21-logo {
    position: absolute;
    top: 90px;
    right: 127px;
    .box_9 {
      width: 278px;
      height: 66px;
      margin: 0px 0 0 445px;
      .text_3 {
        width: 254px;
        height: 35px;
        overflow-wrap: break-word;
        color: #F40000;
        font-size: 30px;
        font-family: FZZYJW--GB1-0;
        text-align: left;
        white-space: nowrap;
        line-height: 35px;
        margin-left: 12px;
      }
      .group_5 {
        background-color: rgba(51, 51, 51, 1);
        width: 278px;
        height: 20px;
        margin-top: 11px;
      }
    }

    .group_6 {
      width: 192px;
      height: 70px;
      background: url("~@/assets/儿童亲子.png") 100% no-repeat;
      background-size: 100% 100%;
      .section_2 {
        background-color: transparent;
        width: 20px;
        height: 20px;
        margin-top: 50px;
      }
      .text_4 {
        width: 120px;
        height: 42px;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 30px;
        font-family: PingFangSC-Medium;
        text-align: center;
        white-space: nowrap;
        line-height: 42px;
        margin: 14px 36px 0 16px;
      }
    }
  }
  .page21-box {
    width: 100%;
    .page21-box-title {
      width: 255px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 24px;

      font-weight: bold;
      color: #ffffff;
      border-radius: 25px;
      margin-bottom: 10px;
      margin-top: 24px;
      background: #f8a93c;
    }
    .page21-box-items {
      width: 100%;
    }
    .page21-box-item {
      width: 472px;
      height: 136px;
      border-radius: 16px;
      border: 6px solid #f8a93c;
      background: #ededed;
      .page21-box-img {
        width: 124px;
        height: 100%;
        background: #ffffff;
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px;
          padding:10px
      }
      .page21-box-text {
        padding-left: 36px;
          flex-grow: 1;
          height: 100%;
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 50px;
      }
    }
    .page21-box-item:nth-child(2) {
      margin: 0 150px
    }
  }
}
</style>
