<template>
    <div class="block page19 f-r f-c">
        <PageHeader :title="moduleData['3_19_1_1']" :sub-title="moduleData['3_19_1_1_en']" />
        <PageTips page-num="13"/>
        <div class="page19-body f-r j-b">
            <div class="page19-body-left">
                <div class="page19-body-header f-r j-b a-c">
                    <div class="page19-body-header-left">零售门店 共 {{ totalSize }} 家</div>
                </div>
                <TableNav width="900px">
                    <template v-slot:title> 距离调研地最近的零售门店（最多展示各 TOP 5） </template>
                </TableNav>
                <PTable :col-data="colData" :row-data="rowData" style="min-height: 472px">
                  <Empty text="当前区域暂无零售门店覆盖" v-if="!rowData.length"/>
                </PTable>
                <div class="page16-map-tips">
                  <div class="page-tips2" style="padding-bottom: 27px;margin: 23px 0 0">>当前图表中仅展示部分分类，全部零售数据于APP「周边配套」中查看</div>
                </div>
                <div class="page19-body-header f-r j-b a-c">
                    <div class="page19-body-header-left">区域零售门店分布数量（家）</div>
                </div>
                <BarChart style="height: 195px; width: 100%"
                          :bar-width="62"
                          color="#17C49D"
                          :x-data="rowData.map(v => v.name)"
                          :y-data="rowData.map(v => v.value)">
                  <Empty text="当前区域暂无零售门店覆盖" v-if="!rowData.length"/>
                </BarChart>
            </div>
            <div class="page19-body-right">
                <div class="page19-body-right f-c">
                    <div class="page19-map" id="page19Map">
                      <Empty text="当前区域暂无零售门店覆盖" v-if="!rowData.length"/>
                    </div>
                    <div class="page19-map-legend f-r a-c f-w">
                      <div
                        class="page19-map-legend-item f-r a-c"
                        v-for="(item, index) in typeList"
                        :key="index"
                      >
                        <div
                          class="page19-map-legend-point point1"
                          :style="{ background: item.color }"
                        ></div>
                        {{ item.name }}
                      </div>
                    </div>
                    <div class="page19-map-tips">
                        数据来源：位置厂商地图数据
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageHeader from "./components/PageHeader";
import PageTips from "./components/PageTips";
import TableNav from "./components/TableNav";
import PTable from "./components/PTable";
import BarChart from "./components/BarChart3";

import AMapLoader from "@amap/amap-jsapi-loader";
import { shallowRef } from "@vue/reactivity";

import { computed, defineComponent, onMounted, ref } from "vue";
import { mapAddCircle, addMapCenter } from "@/utils/pdf";
import { useStore } from "vuex";

import { getMapDelayByMarkerCount } from '@/utils'
import {
  drawMapKeepAlive,
} from '@/api/apiv2'

export default defineComponent({
    components: {
        PageHeader,
        PageTips,
        TableNav,
        PTable,
        BarChart,
    },
    props: {
      cityInfo: {
          type: Object,
          default: () => {
              return {};
          },
      },
      catData: {
          type: Array,
          default: () => {
              return  []
          },
      },
      moduleData: {
          type: Object,
          default: () => {
              return {
              };
          },
      },
      // poi改造后数据
      poiData: {
        type: Object,
        default: () => {},
      },
    },
    setup(props) {
      const color = [
        "#0045FF",
        "#FF7800",
        '#FF0000',
        '#17C49D',
        '#00BEFF',
        '#A933E4',
        '#00FF19',
        '#DACE10',
        '#6694CF',
        '#F53A99',
        '#A1C607',
        '#6005B1',
        '#FF5E87',
      ];
      const store = useStore()
        const colData = ref([
            { prop: "name", width: "147px", label: "类型" },
            { prop: "names", width: "750px", label: "距离项目最近零售门店" },
        ]);
        const rowData = computed(() => {
            return props.catData.map((el, index) => {
              return {
                name: el.bhProTypeName,
                names: el.poi.slice(0,5).map(el => el.name).join('、'),
                value: el?.count || 0,
                poi: el.poi || [],
                color: color[index],
              }
            })
        });
        // 旧版
        // const totalSize = computed(() => {
        //   if (!rowData.value.length) return 0
        //   return rowData.value.map(el => el.value).reduce((pre,next) => pre + next)
        // })
        // poi改版
        const totalSize = computed(() => {
          if (!props.poiData || !props.poiData?.nearby?.length) return 0
          const item = props.poiData.nearby.find((n) => n.bhProType === 'P080000')
          return item?.count || 0
        })

        let map = shallowRef(null);
        const addMaker = (amap, map, data) => {
          const markerList = [];
          const newData = data?.poi || []
          newData.forEach((v) => {
            let marker = new amap.Marker({
                position: new amap.LngLat(
                    v.location.split(",")[0],
                    v.location.split(",")[1]
                ),
              content: `<div style="width: 12px;height: 12px; border-radius: 50%;background: ${data.color};"></div>`
            });
            markerList.push(marker);
          });
          map.add(markerList);
        };

        onMounted(() => {
            AMapLoader.load({
                key: "f0da9fb8263bb9b657e2257ec09c6df7", // 申请好的Web端开发者Key，首次调用 load 时必填
                version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            }).then((AMap) => {
              map = new AMap.Map("page19Map", {
                WebGLParams: {
                    preserveDrawingBuffer:true
                },
                dragEnable: false,
                zoomEnable: false,
                zoom: store.getters.mapZoom, //初始化地图级别
                center: props.cityInfo.location.split(","), //初始化地图中心点位置
              });

              // 按数量排序 多的点先绘制 在底层 视觉效果好一点
              const sortList = rowData.value.sort((a, b) => b.poi.length - a.poi.length)
              for (let i = 0; i < sortList.length; i++) {
                addMaker(AMap, map, sortList[i]);
              }

              addMapCenter(AMap, map, props.cityInfo.location)
              mapAddCircle(props.cityInfo, map)

              // 等待地图渲染
              const totalCount = rowData.value.reduce((a, b) => a + (b?.poi?.length || 0), 0)
              const delay = getMapDelayByMarkerCount(totalCount)
              drawMapKeepAlive(delay).catch((e) => {})

              map.setFitView()
          })
          .catch((e) => {
              console.log(e);
          });
        });

      const typeList = props.catData.map((item, index) => ({
        name: item.bhProTypeName,
        color: color[index],
      }))

      return {
        map,
        colData,
        rowData,
        totalSize,
        typeList,
      };
    },
});
</script>

<style lang="less">
.page19 {
    position: relative;
    background: #ffffff;
    padding: 26px 60px 32px 0;
  .page16-map-tips {
    flex-shrink: 0;
    text-align: right;
    height: 28px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9a9a9a;
    line-height: 28px;
  }
    .page19-body {
        flex-grow: 1;
        padding: 67px 20px 0 61px;
        td {
            height: 50px;
            font-size: 18px;
            padding: 0 10px;
        }
        td:nth-child(2) {
            text-align: left;
        }
        .page19-body-left {
            width: 900px;
            .page19-body-header {
                height: 33px;
                font-size: 24px;

                font-weight: bold;
                color: #333333;
                line-height: 33px;
                margin-bottom: 10px;
            }
        }
        .page19-body-right {
            width: 853px;
            .page19-map {
              width: 100%;
              height: 760px;
              position: relative;
            }
            .page19-map-legend {
                flex-grow: 1;
                padding: 23px 0 23px 46px;
                .page19-map-legend-item {
                    font-size: 20px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 28px;
                    margin-right: 76px;
                }
                .page19-map-legend-item:last-child {
                    margin-right: 0;
                }
                .page19-map-legend-icon {
                    width: 22px;
                    height: 22px;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    margin-right: 12px;
                }
                .icon1 {
                    background-image: url("~@/assets/机场.png");
                }
                .icon2 {
                    background-image: url("~@/assets/火车站.png");
                }
                .icon3 {
                    background-image: url("~@/assets/高铁站.png");
                }
                .page19-map-legend-point {
                    width: 22px;
                    height: 22px;
                    margin-right: 12px;
                    border-radius: 50%;
                }
                .point1 {
                    background-color: #1644ff;
                }
            }
            .page19-map-tips {
                flex-shrink: 0;
                text-align: right;
                height: 28px;
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9a9a9a;
                line-height: 28px;
            }
        }
    }
}
</style>
