<template>
  <div class="container">
    <div class="report-page" id="screen">
      <Page1 v-if="requestSuccessedMap.reportData" :module-data="reportData.module1" />
      <Page7 v-if="requestSuccessedMap.reportData" :module-data="reportData.module4" />
      <Page25 v-if="requestSuccessedMap.reportData" :module-data="reportData.module1_1" />
      <Page8
        v-if="requestSuccessedMap.reportData"
        :module-data="reportData.module5"
        :cat-data="page8PoiData"
        :poi-data="newPoiData"
      />
      <Page9
        v-if="requestSuccessedMap.page9"
        :module-data="reportData.module5"
        :cat-data="page9PoiData"
        :city-info="reportData.module1"
        :poi-data="newPoiData"
      />
      <Page10
        v-if="requestSuccessedMap.page10"
        :module-data="reportData.module6"
        :cat-data="page10And11PoiData"
        :city-info="reportData.module1"
      />
      <Page11
        v-if="requestSuccessedMap.page10"
        :module-data="reportData.module6"
        :cat-data="page10And11PoiData"
      />
      <Page12
        v-if="requestSuccessedMap.page12"
        :city-info="reportData.module1"
        :module-data="reportData.module7"
        :cat-data="page12And13PoiData"
      />
      <Page13
        v-if="requestSuccessedMap.page12"
        :module-data="reportData.module7"
        :cat-data="page12And13PoiData"
      />
      <Page14
        v-if="requestSuccessedMap.page14"
        :module-data="reportData.module8"
        :cat-data1="page14PoiData1"
        :cat-data2="page14PoiData2"
        :city-info="reportData.module1"
      />
      <Page15
        v-if="requestSuccessedMap.page14"
        :module-data="reportData.module8"
        :city-info="reportData.module1"
        :cat-data1="page14PoiData3"
        :cat-data2="page14PoiData4"
      />
    </div>
    <div class="report-page" id="screen2">
      <Page16
        v-if="requestSuccessedMap.page16"
        :module-data="reportData.module9"
        :city-info="reportData.module1"
        :cat-data="page16PoiData"
      />
      <Page17
        v-if="requestSuccessedMap.page17"
        :module-data="reportData.module10"
        :city-info="reportData.module1"
        :cat-data="page17PoiData"
        :poi-data="newPoiData"
      />
      <Page18
        v-if="requestSuccessedMap.page18"
        :city-info="reportData.module1"
        :module-data="reportData.module11"
        :cat-data="page18PoiData"
        :poi-data="newPoiData"
      />
      <Page19
        v-if="requestSuccessedMap.page19"
        :module-data="reportData.module12"
        :city-info="reportData.module1"
        :cat-data="page19PoiData"
        :poi-data="newPoiData"
      />
      <Page20
        v-if="requestSuccessedMap.page20"
        :module-data="reportData.module13"
        :city-info="reportData.module1"
        :cat-data="page20PoiData"
        :poi-data="newPoiData"
      />
      <Page2 v-if="requestSuccessedMap.reportData" :module-data="reportData.module2" />
      <Page3 v-if="requestSuccessedMap.reportData" :module-data="reportData.module3"  />
      <Page4
        v-if="requestSuccessedMap.reportData && requestSuccessedMap.page4"
        :city-info="reportData.module1"
        :module-data="reportData.module3"
        :cat-data="page4PoiData"
        :detail-data="page4DetailData"
      />
      <Page5 v-if="requestSuccessedMap.reportData" :module-data="reportData.module3" />
      <Page6 v-if="requestSuccessedMap.reportData" :module-data="reportData.module3" />
      <!-- <Page21
        v-if="requestSuccessedMap.page21"
        :module-data="reportData.module14"
        :cat-data="page21Data"
      />
      <Page22
        v-if="requestSuccessedMap.page22 "
        :module-data="reportData.module14"
        :cat-data="page21Data"
      />
      <Page23
        v-if="requestSuccessedMap.page23"
        :module-data="reportData.module14"
        :cat-data="page21Data"
      /> -->
      <Page24 :module-data="reportData.module15"/>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import Page5 from "./Page5";
import Page6 from "./Page6";
import Page7 from "./Page7";
import Page8 from "./Page8";
import Page9 from "./Page9";
import Page10 from "./Page10";
import Page11 from "./Page11";
import Page12 from "./Page12";
import Page13 from "./Page13";
import Page14 from "./Page14";
import Page15 from "./Page15";
import Page16 from "./Page16";
import Page17 from "./Page17";
import Page18 from "./Page18";
import Page19 from "./Page19";
import Page20 from "./Page20";
import Page21 from "./Page21";
import Page22 from "./Page22";
import Page23 from "./Page23";
import Page24 from "./Page24";
import Page25 from "./Page25";

import {
  getReport,

  getPoiData,
  getMarketPoiData,
  getCityAroundData,
  getCityDetailData,

  getCatData,
  getHousePriceData,
  getBrandData,
} from "@/api/apiv2";
export default defineComponent({
  props: {
    transform: String
  },
  components: {
    Page1,
    Page2,
    Page3,
    Page4,
    Page5,
    Page6,
    Page7,
    Page8,
    Page9,
    Page10,
    Page11,
    Page12,
    Page13,
    Page14,
    Page15,
    Page16,
    Page17,
    Page18,
    Page19,
    Page20,
    Page21,
    Page22,
    Page23,
    Page24,
    Page25,
  },
  setup() {
    const route = useRoute()
    const address_id = route.query.address_id
    if (!address_id) return
    // 记录请求记录，使用对象设置每页是否允许加载开关
    const requestSuccessedMap = ref({
      reportData: false,
      page4: false,
      page8: true,
      page9: false,
      page10: false,
      page11: false,
      page12: false,
      page14: false,
      page16: false,
      page17: false,
      page18: false,
      page19: false,
      page20: false,
      page21: false,
      page22: false,
      page23: false,
      page25: false,
    });
    // 设置请求成功方法
    const setRequestSuccessed = (key) => {
      requestSuccessedMap.value[key] = true;
    };
    // 报告基础数据
    const reportData = ref({});
    // 空数据
    const isEmptyPage = ref('')
    /**
     * catIds 每页所需要的catids
     * catData 定义每页需要的数据格式
     * getPage 获取每页需要的数据
     * 数据调用成功会更新请求成功状态true 开始加载页面，地图必须等待数据成功后才能加载
     */

    // page4数据
    const page4Types = [
      'P050301',
      'P050201',
      'P050101',
    ];
    const page4PoiData = ref([]);
    const page4DetailData = ref([])
    function getPage4(res) {
      getPoiData({
        location: res.data.module1.location,
        cityCode: [res.data.module1.district_no],
        bhProType: page4Types,
      }).then((page4res) => {
        page4PoiData.value = page4res.data || []
        setRequestSuccessed("page4");
      }).catch(() => {
        getPoiData({
          location: res.data.module1.location,
          radius: res.data.module1.radius,
          bhProType: page4Types,
        }).then((page4res) => {
          page4PoiData.value = page4res.data || []
          setRequestSuccessed("page4");
        }).catch(() => {
          setRequestSuccessed("page4");
        });
      });

      // 新 poi 改动
      const sort = [
        '地铁线路条数',
        '火车站',
        '地铁站',
        '机场',
      ]
      getCityDetailData(address_id).then((res) => {
        page4DetailData.value = sort.map((s) => {
          const item = res?.data?.traffic?.find((t) => t.name === s)
          return item?.value || '--'
        })
      }).catch(() => {
        getCityDetailData(address_id).then((res) => {
          page4DetailData.value = sort.map((s) => {
            const item = res?.data?.traffic?.find((t) => t.name === s)
            return item?.value || '--'
          })
        }).catch(() => {
        })
      })
    }
    // page8数据
    const page8Types = [
      ['P020101', 'P020201', 'P020301', 'P020401', 'P020501'],
      ['P030101', 'P030201', 'P030301', 'P030401', 'P030501', 'P030601', 'P030701'],
      ['P060100', 'P060200', 'P060300'],
      ['P040100', 'P040200', 'P040300', 'P040400', 'P040500', 'P040600'],
    ];
    const page8PoiData = ref([[], [], [], []]);
    const newPoiData = ref({})
    function getPage8(res) {
      // page8
      getPoiData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        bhProType: page8Types[0],
        showPoiList: false,
      }).then((page8res) => {
        page8PoiData.value[0] = page8Types[0].map((v) => {
          return page8res.data.find((d) => d.bhProType === v);
        }).filter(v => !!v.count);
      });
      getPoiData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        bhProType: page8Types[1],
      }).then((page8res) => {
        page8PoiData.value[1] = page8Types[1].map((v) => {
          return page8res.data.find((d) => d.bhProType === v);
        }).filter(v => !!v.count);
      });
      getPoiData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        bhProType: page8Types[2],
      }).then((page8res) => {
        page8PoiData.value[2] = page8Types[2].map((v) => {
          return page8res.data.find((d) => d.bhProType === v);
        }).filter(v => !!v.count);
      });
      getPoiData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        bhProType: page8Types[3],
      }).then((page8res) => {
        page8PoiData.value[3] = page8Types[3].map((v) => {
          return page8res.data.find((d) => d.bhProType === v);
        }).filter(v => !!v.count);
      });

      // poi改版新数据
      getCityAroundData({
        addressId: address_id,
        location: res.data.module1.location,
        radius: res.data.module1.radius,
      }).then((res) => {
        const data = res?.data || {}
        newPoiData.value = {...data}
      }).catch(() => {
        getCityAroundData({
          addressId: address_id,
          location: res.data.module1.location,
          radius: res.data.module1.radius,
        }).then((res) => {
          const data = res?.data || {}
          newPoiData.value = {...data}
        }).catch(() => {})
      })
    }

    // page9数据
    const page9Types = [
      'P050101',
      'P050201',
      'P050301',
      'P050401',
      'P050601',
    ];
    const page9PoiData = ref([]);
    function getPage9(res) {
      getPoiData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        bhProType: page9Types,
      }).then((page9res) => {
        page9PoiData.value = page9res.data
        setRequestSuccessed("page9");
      }).catch(() => {
        getCatData({
          location: res.data.module1.location,
          radius: res.data.module1.radius,
          bhProType: page9Types,
        }).then((page9res) => {
          page9PoiData.value = page9res.data
          setRequestSuccessed("page9");
        }).catch(() => {
          setRequestSuccessed("page9");
        });
      });
    }

    // 新poi page10 ｜ page11 使用同样的数据
    const page10And11Types = ['P010101']
    const page10And11PoiData = ref({
      count: 0,
      poi: [],
    });
    function getPage10(res) {
      getPoiData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        bhProType: page10And11Types,
      }).then((page10res) => {
        page10And11PoiData.value = page10res?.data[0] || {
          count: 0,
          poi: [],
        }
        setRequestSuccessed("page10");
      }).catch(() => {
        getPoiData({
          location: res.data.module1.location,
          radius: res.data.module1.radius,
          bhProType: page10And11Types,
        }).then((page10res) => {
          page10And11PoiData.value = page10res?.data[0] || {
            count: 0,
            poi: [],
          }
          setRequestSuccessed("page10");
        }).catch(() => {
          setRequestSuccessed("page10");
        })
      })
    }

    // 新poi page12 ｜ page13 使用同样的数据
    const page12And13Types = ['P010201']
    const page12And13PoiData = ref({
      count: 0,
      poi: [],
    });
    function getPage12(res) {
      getPoiData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        bhProType: page12And13Types,
      }).then((page12res) => {
        page12And13PoiData.value = page12res?.data[0] || {
          count: 0,
          poi: [],
        }
        setRequestSuccessed("page12");
      }).catch(() => {
        getPoiData({
          location: res.data.module1.location,
          radius: res.data.module1.radius,
          bhProType: page12And13Types,
        }).then((page12res) => {
          page12And13PoiData.value = page12res?.data[0] || {
            count: 0,
            poi: [],
          }
          setRequestSuccessed("page12");
        }).catch(() => {
          setRequestSuccessed("page12");
        })
      })
    }

    // page14 | page15 数据
    const page14Types = ['P040101', 'P040201', 'P040301', 'P040401', 'P040501'];
    const page14PoiData1 = ref({
      poi: [],
    });
    const page14PoiData2 = ref({
      poi: [],
    });
    const page14PoiData3 = ref({
      poi: [],
    });
    const page14PoiData4 = ref({
      poi: [],
    });
    function getPage14(res) {
      getPoiData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        bhProType: page14Types,
      }).then((pageRes) => {
        page14PoiData1.value = pageRes.data.find((el) => el.bhProType === page14Types[0]) || {poi: []};
        page14PoiData2.value = pageRes.data.find((el) => el.bhProType === page14Types[1]) || {poi: []};
        page14PoiData3.value = pageRes.data.find((el) => el.bhProType === page14Types[2]) || {poi: []};
        // 高等院校需要组合两个不同的数据
        const data1 = pageRes.data.find((el) => el.bhProType === page14Types[3]) || {poi: []};
        const data2 = pageRes.data.find((el) => el.bhProType === page14Types[4]) || {poi: []};
        page14PoiData4.value = {
          count: (data1?.count || 0) + (data2?.count || 0),
          poi: [].concat((data1?.poi || []), (data2?.poi || [])),
        };
        setRequestSuccessed("page14");
      }).catch(() => {
        getPoiData({
          location: res.data.module1.location,
          radius: res.data.module1.radius,
          bhProType: page14Types,
        }).then((pageRes) => {
          page14PoiData1.value = pageRes.data.find((el) => el.bhProType === page14Types[0]) || {poi: []};
          page14PoiData2.value = pageRes.data.find((el) => el.bhProType === page14Types[1]) || {poi: []};
          page14PoiData3.value = pageRes.data.find((el) => el.bhProType === page14Types[2]) || {poi: []};
          // 高等院校需要组合两个不同的数据
          const data1 = pageRes.data.find((el) => el.bhProType === page14Types[3]) || {poi: []};
          const data2 = pageRes.data.find((el) => el.bhProType === page14Types[4]) || {poi: []};
          page14PoiData4.value = {
            count: (data1?.count || 0) + (data2?.count || 0),
            poi: [].concat((data1?.poi || []), (data2?.poi || [])),
          };
          setRequestSuccessed("page14");
        }).catch(() => {
          setRequestSuccessed("page14");
        });
      });
    }

    // page16数据
    const page16PoiData = ref([]);
    function getPage16(res) {
      getMarketPoiData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        bhProType: [],
      }).then((pageRes) => {
        page16PoiData.value = pageRes?.data || [];
        setRequestSuccessed("page16");
      }).catch(() => {
        getMarketPoiData({
          location: res.data.module1.location,
          radius: res.data.module1.radius,
          bhProType: [],
        }).then((pageRes) => {
          page16PoiData.value = pageRes?.data || [];
          setRequestSuccessed("page16");
        }).catch(() => {
          setRequestSuccessed("page16");
        });
      });
    }

    // page17数据
    const page17Types = [
      'P030101',
      'P030201',
      'P030301',
      'P030401',
      'P030501',
      'P030601',
      'P030701',
    ];
    const page17PoiData = ref([]);
    function getPage17(res) {
      getPoiData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        bhProType: page17Types,
      }).then((pageRes) => {
        page17PoiData.value = pageRes.data
        setRequestSuccessed("page17");
      }).catch(() => {
        getPoiData({
          location: res.data.module1.location,
          radius: res.data.module1.radius,
          bhProType: page17Types,
        }).then((pageRes) => {
          page17PoiData.value = pageRes.data
          setRequestSuccessed("page17");
        }).catch(() => {
          setRequestSuccessed("page17");
        });
      });
    }

    // page18数据
    const page18Types = [
      'P070100',
      'P070200',
      'P070300',
      'P070400',
      'P070500',
      'P070600',
      'P070700',
      'P070800',
      'P070900',
      'P071000',
      'P071100',
      'P071200',
      'P071300',
    ];
    const page18PoiData = ref([]);
    async function getPage18(res) {
      getPoiData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        bhProType: page18Types,
      }).then((pageRes) => {
        page18PoiData.value = pageRes?.data.filter((d) => !!d.count) || [];
        setRequestSuccessed('page18');
      }).catch(() => {
        getPoiData({
          location: res.data.module1.location,
          radius: res.data.module1.radius,
          bhProType: page18Types,
        }).then((pageRes) => {
          page18PoiData.value = pageRes?.data.filter((d) => !!d.count) || [];
          setRequestSuccessed('page18');
        }).catch(() => {
          setRequestSuccessed('page18');
        });
      });
    }

    // page19数据
    const page19Types = [
      'P080201',
      'P080401',
      'P080501',
      'P080101',
      'P080801',
      'P080901',
      'P081001',
      'P081301',
    ];
    const page19PoiData = ref([])
    function getPage19(res) {
      getPoiData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        bhProType: page19Types,
      }).then((pageRes) => {
        page19PoiData.value = pageRes?.data.filter((d) => !!d.count) || [];
        setRequestSuccessed("page19");
      }).catch(() => {
        getPoiData({
          location: res.data.module1.location,
          radius: res.data.module1.radius,
          bhProType: page19Types,
        }).then((pageRes) => {
          page19PoiData.value = pageRes?.data.filter((d) => !!d.count) || [];
          setRequestSuccessed("page19");
        }).catch(() => {
          setRequestSuccessed("page19");
        });
      });
    }

    // page20数据
    const page20Types = [
      'P110101',
      'P110201',
      'P110301',
      'P110401',
      'P110501',
      'P110601',
      'P111001',
      'P111101',
      'P111201',
    ];
    const page20PoiData = ref([])
    function getPage20(res) {
      getPoiData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        bhProType: page20Types,
      }).then((pageRes) => {
        page20PoiData.value = pageRes?.data.filter((d) => !!d.count) || [];
        setRequestSuccessed("page20");
      }).catch(() => {
        getPoiData({
          location: res.data.module1.location,
          radius: res.data.module1.radius,
          bhProType: page20Types,
        }).then((pageRes) => {
          page20PoiData.value = pageRes?.data.filter((d) => !!d.count) || [];
          setRequestSuccessed("page20");
        }).catch(() => {
          setRequestSuccessed("page20");
        });
      });
    }
    const page21Data = ref({});
    // 三个页面状态独立
    const wrapPage21 = (data) => {
      if (data?.tuijian_qinzi?.length) {
        setRequestSuccessed("page21");
      }
      if (data?.tuijian_canyin?.length) {
        setRequestSuccessed("page22");
      }
      if (data?.tuijian_lingshou?.length) {
        setRequestSuccessed("page23");
      }
    }
    function getPage21() {
      getBrandData(address_id).then((pageRes) => {
        const { data } = pageRes
        page21Data.value = data;
        wrapPage21(data)
      }).catch(() => {
        getBrandData(address_id).then((pageRes) => {
          const { data } = pageRes
          page21Data.value = data;
          wrapPage21(data)
        }).catch(() => {
          console.log('挂了');
          // setRequestSuccessed("page21");
        });
      });
    }
    /**
     * 页面渲染完成开始加载数据
     */
    const store = useStore()
    onMounted(() => {
      // 报表数据
      getReport(address_id).then((res) => {
        if (Array.isArray(res.data)) {
          isEmptyPage.value = res.info
          return false
        }
        reportData.value = {
          ...(res.data || {}),
        };
        // 缓存基本报告数据
        store.commit('SET_BASIC_REPORT', reportData.value)
        setRequestSuccessed("reportData");
        getPage4(res);
        getPage8(res);
        getPage9(res);
        getPage10(res); // 10 | 11 页用同样的数据
        getPage12(res); // 12 ｜ 13 页用同样的数据
        getPage14(res);
        getPage16(res);
        getPage17(res);
        getPage18(res);
        getPage19(res);
        getPage20(res);
        getPage21(res);
      });
    });
    return {
      requestSuccessedMap,
      reportData,
      newPoiData,

      isEmptyPage,

      page4DetailData,
      page4PoiData,

      page8PoiData,
      page9PoiData,

      page10And11PoiData,

      page12And13PoiData,

      page14PoiData1,
      page14PoiData2,
      page14PoiData3,
      page14PoiData4,
      page16PoiData,

      page17PoiData,

      page18PoiData,

      page19PoiData,

      page20PoiData,
      page21Data,
    };
  },
});
</script>

<style lang="less">
.container {
  width: 1920px;
}
.empty-page {
  font-size: 48px;
  width: 1920px;
  height: 1080px;
  line-height: 1080px;
  text-align: center;
}
.page-box {
  width: 1920px;
  height: 1080px;

  .amap-container {
    position: relative;
  }
  .map-img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
  }
}
.report-page {
  .block {
    height: 1080px;
    width: 100%;
  }
  .color-red {
    color: #f40000;
    margin-right: 10px;
  }
  .f-14 {
    font-size: 14px;
  }
  .arrow-up {
    width: 13px;
    height: 13px;
    margin-right: 5px;
    background-image: url("~@/assets/arrow-up.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .arrow-up-color {
    color: #f40000;
  }
  .arrow-down-color {
    color: #05c44b;
  }
  .arrow-down {
    width: 13px;
    height: 13px;
    margin-right: 5px;
    background-image: url("~@/assets/arrow-down.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .etable {
    font-size: 20px;
    .etable-wrapper {
      width: 50%;
    }
    table {
      border-spacing: 1px;
      width: 100%;
      tr.empty-row {
        width: 100%;
        background: #f2f2f2;
        td {
          background: #f2f2f2 !important;
          border-spacing: 0 !important;
        }
      }

      tr {
        td {
          height: 51px;
          background: #dbdbdb;

          font-weight: bold;
          color: #333333;
          line-height: 20px;
          padding-left: 15px;
        }
        td:nth-child(1) {
          text-align: left;
        }
        td:nth-child(2n) {
          text-align: center;
          background: #f2f2f2;
          font-weight: 400;
        }
      }
    }
    .etable-wrapper:nth-child(2) {
      margin-left: -0.5px;
    }
  }
}
</style>
