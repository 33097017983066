<template>
    <div class="block page19 f-r f-c">
        <PageHeader :title="moduleData['3_19_1_1']" :sub-title="moduleData['3_19_1_1_en']" />
        <PageTips page-num="16"/>
        <div class="page19-body f-r j-b">
            <div class="page19-body-left">
                <div class="page19-body-header f-r j-b a-c">
                    <div class="page19-body-header-left">零售门店 共 {{ totalSize }} 家</div>
                </div>
                <TableNav width="900px">
                    <template v-slot:title> 距离调研地最近的零售门店（最多展示各 TOP 5） </template>
                </TableNav>
                <PTable :col-data="colData" :row-data="rowData1" style="min-height: 472px">
                  <Empty text="当前区域暂无零售门店覆盖" v-if="!rowData1.length"/>
                </PTable>
                <div class="page16-map-tips">
                  <div class="page-tips2" style="padding-bottom: 27px;margin: 23px 0 0">>更多零售分布于APP「数据地图/市调详情_周边配套」中查看</div>
                </div>
                <div class="page19-body-header f-r j-b a-c">
                    <div class="page19-body-header-left">区域零售门店分布数量（家）</div>
                </div>
                <BarChart style="height: 195px; width: 100%"
                          :bar-width="62"
                          color="#17C49D"
                          :x-data="rowData1.map(v => v.name)"
                          :y-data="rowData1.map(v => v.value)">
                  <Empty text="当前区域暂无零售门店覆盖" v-if="!rowData1.length"/>
                </BarChart>
            </div>
            <div class="page19-body-right">
                <div class="page19-body-right f-c">
                    <div class="page19-map" id="page19Map">
                      <Empty text="当前区域暂无零售门店覆盖" v-if="!rowData1.length"/>
                    </div>
                    <div class="page19-map-legend f-r a-c">
                        <div class="page19-map-legend-item f-r a-c">
                            <div class="page19-map-legend-point point1"></div>
                            便利店
                        </div>
                        <div class="page19-map-legend-item f-r a-c">
                            <div class="page19-map-legend-point point2"></div>
                            菜市场
                        </div>
                        <div class="page19-map-legend-item f-r a-c">
                            <div class="page19-map-legend-point point3"></div>
                            母婴
                        </div>
                        <div class="page19-map-legend-item f-r a-c">
                            <div class="page19-map-legend-point point4"></div>
                            汽车销售
                        </div>
                    </div>
                    <div class="page19-map-tips">
                        数据来源：位置厂商地图数据
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageHeader from "./components/PageHeader";
import PageTips from "./components/PageTips";
import TableNav from "./components/TableNav";
import PTable from "./components/PTable";
import BarChart from "./components/BarChart3";

import AMapLoader from "@amap/amap-jsapi-loader";
import { shallowRef } from "@vue/reactivity";

import { computed, defineComponent, onMounted, ref } from "vue";
import { mapAddCircle, addMapCenter } from "@/utils/pdf";
import { useStore } from "vuex";

import { getMapDelayByMarkerCount } from '@/utils'
import {
  drawMapKeepAlive,
} from '@/api/apiv1'

export default defineComponent({
    components: {
        PageHeader,
        PageTips,
        TableNav,
        PTable,
        BarChart,
    },
    props: {
        cityInfo: {
            type: Object,
            default: () => {
                return {};
            },
        },
        catData: {
            type: Array,
            default: () => {
                return  []
            },
        },
        moduleData: {
            type: Object,
            default: () => {
                return {
                };
            },
        },
    },
    setup(props) {
      const store = useStore()
        const colData = ref([
            { prop: "name", width: "147px", label: "类型" },
            { prop: "names", width: "750px", label: "距离项目最近零售门店" },
        ]);
        const rowData1 = computed(() => {
            return props.catData.map(el => {
              return {
                name: el.cate_name,
                names: el.pois.slice(0,5).map(el => el.name).join('、'),
                value: el.count || 0,
                pois: el.pois || []
              }
            })
        });
        const totalSize = computed(() => {
          if (!rowData1.value.length) return 0
          return rowData1.value.map(el => el.value).reduce((pre,next) => pre + next)
        })
        let map = shallowRef(null);
        const addMaker = (amap, map, data, color) => {
            const newData = data.pois || []
            let markerList = [];
            newData.forEach((v) => {
                let marker = new amap.Marker({
                    position: new amap.LngLat(
                        v.location.split(",")[0],
                        v.location.split(",")[1]
                    ),
                  content: `<div style="width: 12px;height: 12px; border-radius: 50%;background: ${color};"></div>`

              });
                markerList.push(marker);
            });
            map.add(markerList);
        };
        onMounted(() => {
            AMapLoader.load({
                key: "f0da9fb8263bb9b657e2257ec09c6df7", // 申请好的Web端开发者Key，首次调用 load 时必填
                version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            }).then((AMap) => {
              map = new AMap.Map("page19Map", {
                WebGLParams: {
                    preserveDrawingBuffer:true
                },
                dragEnable: false,
                zoomEnable: false,
                zoom: store.getters.mapZoom, //初始化地图级别
                center: props.cityInfo.location.split(","), //初始化地图中心点位置
              });
              addMaker(AMap, map, rowData1.value.find(el => el.name === '便利店') || {}, '#1644FF')
              addMaker(AMap, map, rowData1.value.find(el => el.name === '菜市场') || {}, '#FF8116')
              addMaker(AMap, map, rowData1.value.find(el => el.name === '母婴') || {}, '#F40000')
              addMaker(AMap, map, rowData1.value.find(el => el.name === '汽车销售') || {}, '#17C49D')
              addMapCenter(AMap, map, props.cityInfo.location)
              mapAddCircle(props.cityInfo, map)

              // 等待地图渲染
              const totalCount = rowData1.value.reduce((a, b) => a + (b?.pois?.length || 0), 0)
              const delay = getMapDelayByMarkerCount(totalCount)
              drawMapKeepAlive(delay).catch((e) => {})

              map.setFitView()
          })
          .catch((e) => {
              console.log(e);
          });
        });

        return {
            map,
            colData,
            rowData1,
            totalSize
        };
    },
});
</script>

<style lang="less">
.page19 {
    position: relative;
    background: #ffffff;
    padding: 26px 60px 32px 0;
  .page16-map-tips {
    flex-shrink: 0;
    text-align: right;
    height: 28px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9a9a9a;
    line-height: 28px;
  }
    .page19-body {
        flex-grow: 1;
        padding: 67px 20px 0 61px;
        td {
            height: 50px;
            font-size: 18px;
            padding: 0 10px;
        }
        td:nth-child(2) {
            text-align: left;
        }
        .page19-body-left {
            width: 900px;
            .page19-body-header {
                height: 33px;
                font-size: 24px;

                font-weight: bold;
                color: #333333;
                line-height: 33px;
                margin-bottom: 10px;
            }
        }
        .page19-body-right {
            width: 853px;
            .page19-map {
                width: 100%;
                height: 760px;
              position: relative;
            }
            .page19-map-legend {
                flex-grow: 1;
                padding: 23px 0 23px 46px;
                .page19-map-legend-item {
                    font-size: 20px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 28px;
                    margin-right: 76px;
                }
                .page19-map-legend-item:last-child {
                    margin-right: 0;
                }
                .page19-map-legend-icon {
                    width: 22px;
                    height: 22px;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    margin-right: 12px;
                }
                .icon1 {
                    background-image: url("~@/assets/机场.png");
                }
                .icon2 {
                    background-image: url("~@/assets/火车站.png");
                }
                .icon3 {
                    background-image: url("~@/assets/高铁站.png");
                }
                .page19-map-legend-point {
                    width: 22px;
                    height: 22px;
                    margin-right: 12px;
                    border-radius: 50%;
                }
                .point1 {
                    background-color: #1644ff;
                }
                .point2 {
                    background-color: #ff8116;
                }
                .point3 {
                    background-color: #F40000;
                }
                .point4 {
                    background-color: #17C49D;
                }
            }
            .page19-map-tips {
                flex-shrink: 0;
                text-align: right;
                height: 28px;
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9a9a9a;
                line-height: 28px;
            }
        }
    }
}
</style>
