<template>
  <div class="radar-chart">
    <slot></slot>
    <VChart
      style="width: 100%; height: 100%"
      :option="option"
      autoresize
    ></VChart>
  </div>
</template>

<script>
export default {
  props: {
    chartData: Array,
  },
  computed: {
    option() {
      if (!this.chartData || !this.chartData.length) return {};
      return {
        radar: {
          // shape: 'circle',
          indicator: [
            {
              text: "人口密度",
              max: 10,
              value: this.chartData[0],
            },
            {
              text: "区域等级",
              max: 10,
              value: this.chartData[1],
            },
            {
              text: "交通便利度",
              max: 10,
              value: this.chartData[2],
            },
            {
              text: "客群消费能力",
              max: 10,
              value: this.chartData[3],
            },
            {
              text: "商业成熟度",
              max: 10,
              value: this.chartData[4],
            },
          ],
          name: {
            formatter: function (params, i) {
              return "{yellow|" + i.text + "\n}{white| " + i.value + "}";
            },
            rich: {
              yellow: {
                color: "#000000",
                fontSize: 24,
                padding: [0, 0, 6, 0],
                fontWeight:600
              },
              white: {
                color: "#000000",
                fontSize: 24,
                align:'center',
                fontWeight:600
              },
            },
          },
        },
        series: [
          {
            name: "Budget vs spending",
            type: "radar",
            symbol: "circle",
            symbolSize:14,
            areaStyle: {
              color: "rgba(244,0,0,0.3)",
            },
            lineStyle: {
              color: "rgba(244,0,0,1)",
            },
            itemStyle:{
              color:'#FFFFFF',
              borderWidth:1,
              borderColor:"#F40000"
            },
            data: [
              {
                value: [
                  this.chartData[0],
                  this.chartData[1],
                  this.chartData[2],
                  this.chartData[3],
                  this.chartData[4],
                ],
                name: "Allocated Budget",
              },
            ],
          },
        ],
      };
    },
  },
};
</script>
<style lang="less">
.radar-chart {
  height: 100%;
  padding: 12px;
  position: relative;
}
</style>
