<template>
  <div class="container">
<!--    <div class="version">2022-11-17-V2</div>-->

    <div class="report-page" id="screen">
      <Page1 v-if="requestSuccessedMap.reportData" :module-data="reportData.module1" />
      <Page2 v-if="requestSuccessedMap.reportData" :module-data="reportData.module2" />
      <Page3 v-if="requestSuccessedMap.reportData" :module-data="reportData.module3"  />
      <Page4
        v-if="requestSuccessedMap.reportData && requestSuccessedMap.page4"
        :city-info="reportData.module1"
        :module-data="reportData.module3"
        :cat-data="page4CatData"
      />
      <Page5 v-if="requestSuccessedMap.reportData" :module-data="reportData.module3" />
      <Page6 v-if="requestSuccessedMap.reportData" :module-data="reportData.module3" />
      <Page7 v-if="requestSuccessedMap.reportData" :module-data="reportData.module4" />
      <Page8
        v-if="requestSuccessedMap.reportData"
        :module-data="reportData.module5"
        :cat-data="page8CatData"
      />
      <Page9
        v-if="requestSuccessedMap.page9"
        :module-data="reportData.module5"
        :cat-data="page9CatData"
        :city-info="reportData.module1"
      />
      <Page10
        v-if="requestSuccessedMap['page10-1'] && requestSuccessedMap['page10-2']"
        :module-data="reportData.module6"
        :cat-data1="page10CatData1"
        :cat-data2="page10CatData2"
        :city-info="reportData.module1"
      />
      <Page11
        v-if="requestSuccessedMap.page11"
        :module-data="reportData.module6"
        :cat-data="page11CatData"
      />
      <Page12
        v-if="requestSuccessedMap.page12"
        :city-info="reportData.module1"
        :module-data="reportData.module7"
        :cat-data="page12CatData"
      />
      <Page13
        v-if="requestSuccessedMap.page12"
        :module-data="reportData.module7"
        :cat-data="page12CatData"
        :cat-count="page12Count"
      />
      <Page14
        v-if="requestSuccessedMap.page14"
        :module-data="reportData.module8"
        :cat-data1="page14CatData1"
        :cat-data2="page14CatData2"
        :city-info="reportData.module1"
      />
      <Page15
        v-if="requestSuccessedMap.page14"
        :module-data="reportData.module8"
        :city-info="reportData.module1"
        :cat-data1="page14CatData3"
        :cat-data2="page14CatData4"
      />
    </div>
    <div class="report-page" id="screen2">
      <Page16
        v-if="requestSuccessedMap.page16"
        :module-data="reportData.module9"
        :city-info="reportData.module1"
        :cat-data1="page16CatData1"
      />
      <Page17
        v-if="requestSuccessedMap.page17"
        :module-data="reportData.module10"
        :city-info="reportData.module1"
        :cat-data="page17CatData"
      />
      <Page18
        v-if="requestSuccessedMap.page18 && requestSuccessedMap['page18-2']"
        :city-info="reportData.module1"
        :module-data="reportData.module11"
        :cat-data1="page18CatData1"
        :cat-data2="page18CatData2"
      />
      <Page19
        v-if="requestSuccessedMap.page19"
        :module-data="reportData.module12"
        :city-info="reportData.module1"
        :cat-data="page19CatData"
      />
      <Page20
        v-if="requestSuccessedMap.page20"
        :module-data="reportData.module13"
        :city-info="reportData.module1"
        :cat-data="page20CatData"
      />
      <Page21
        v-if="requestSuccessedMap.page21"
        :module-data="reportData.module14"
        :cat-data="page21Data"
      />
      <Page22
        v-if="requestSuccessedMap.page22 "
        :module-data="reportData.module14"
        :cat-data="page21Data"
      />
      <Page23
        v-if="requestSuccessedMap.page23"
        :module-data="reportData.module14"
        :cat-data="page21Data"
      />
      <Page24 :module-data="reportData"/>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import Page5 from "./Page5";
import Page6 from "./Page6";
import Page7 from "./Page7";
import Page8 from "./Page8";
import Page9 from "./Page9";
import Page10 from "./Page10";
import Page11 from "./Page11";
import Page12 from "./Page12";
import Page13 from "./Page13";
import Page14 from "./Page14";
import Page15 from "./Page15";
import Page16 from "./Page16";
import Page17 from "./Page17";
import Page18 from "./Page18";
import Page19 from "./Page19";
import Page20 from "./Page20";
import Page21 from "./Page21";
import Page22 from "./Page22";
import Page23 from "./Page23";
import Page24 from "./Page24";

import {
  getReport,
  getCatData,
  getHousePriceData,
  getBrandData,
} from "@/api/apiv1";
export default defineComponent({
  props: {
    transform: String
  },
  components: {
    Page1,
    Page2,
    Page3,
    Page4,
    Page5,
    Page6,
    Page7,
    Page8,
    Page9,
    Page10,
    Page11,
    Page12,
    Page13,
    Page14,
    Page15,
    Page16,
    Page17,
    Page18,
    Page19,
    Page20,
    Page21,
    Page22,
    Page23,
    Page24,
  },
  setup() {
    const route = useRoute()
    const address_id = route.query.address_id
    if (!address_id) return
    // 记录请求记录，使用对象设置每页是否允许加载开关
    const requestSuccessedMap = ref({
      reportData: false,
      page4: false,
      page8: true,
      page9: false,
      "page10-1": false,
      "page10-2": false,
      page11: false,
      page12: false,
      page14: false,
      page16: false,
      page17: false,
      page19: false,
      page18: false,
      "page18-2": false,
      page20: false,
      page21: false,
      page22: false,
      page23: false,
    });
    // 设置请求成功方法
    const setRequestSuccessed = (key) => {
      requestSuccessedMap.value[key] = true;
    };
    // 报告基础数据
    const reportData = ref({});
    // 空数据
    const isEmptyPage = ref('')
    /**
     * catIds 每页所需要的catids
     * catData 定义每页需要的数据格式
     * getPage 获取每页需要的数据
     * 数据调用成功会更新请求成功状态true 开始加载页面，地图必须等待数据成功后才能加载
     */
      // page4数据
    const page4CatIds = [199, 198, 197];
    const page4CatData = ref([]);
    function getPage4(res) {
      getCatData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        cate_id: page4CatIds.join("|"),
        return_type: 'city_all',
      }).then((page4res) => {
        page4CatData.value = page4CatIds.map((v) => {
          return page4res.data.find((d) => d.cate_id == v);
        });
        setRequestSuccessed("page4");
      }).catch(() => {
        getCatData({
          location: res.data.module1.location,
          radius: res.data.module1.radius,
          cate_id: page4CatIds.join("|"),
          return_type: 'city_all',
        }).then((page4res) => {
          page4CatData.value = page4CatIds.map((v) => {
            return page4res.data.find((d) => d.cate_id == v);
          });
          setRequestSuccessed("page4");
        }).catch(() => {
          setRequestSuccessed("page4");
        });
      });
    }
    // page8数据
    const page8CatIds = [
      [140, 137, 136, 138],
      [614, 615, 616, 617, 618, 613],
      [39, 40, 201, 41, 38, 155],
      [184, 185, 186, 187, 190],
    ];
    const page8CatData = ref([[], [], [], []]);
    function getPage8(res) {
      // page8
      getCatData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        cate_id: [
          ...page8CatIds[0],
        ].join("|"),
      }).then((page8res) => {
        page8CatData.value[0] = page8CatIds[0].map((v) => {
          return page8res.data.find((d) => d.cate_id == v);
        }).filter(v => v);
      });
      getCatData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        cate_id: [
          ...page8CatIds[1],
        ].join("|"),
      }).then((page8res) => {
        page8CatData.value[1] = page8CatIds[1].map((v) => {
          return page8res.data.find((d) => d.cate_id == v);
        }).filter(v => v);
      });

      getCatData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        cate_id: [
          ...page8CatIds[2],
        ].join("|"),
      }).then((page8res) => {
        page8CatData.value[2] = page8CatIds[2].map((v) => {
          return page8res.data.find((d) => d.cate_id == v);
        }).filter(v => v);
      });

      getCatData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        cate_id: [
          ...page8CatIds[3],
        ].join("|"),
      }).then((page8res) => {
        page8CatData.value[3] = page8CatIds[3].map((v) => {
          return page8res.data.find((d) => d.cate_id == v);
        }).filter(v => v);
      });
    }
    // page9数据
    const page9CatIds = [199, 198, 196, 197];
    const page9CatData = ref([]);
    function getPage9(res) {
      getCatData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        cate_id: page9CatIds.join("|"),
      }).then((page8res) => {
        page9CatData.value = page9CatIds.map((v) => {
          return page8res.data.find((d) => d.cate_id == v);
        });
        setRequestSuccessed("page9");
      }).catch(() => {
        getCatData({
          location: res.data.module1.location,
          radius: res.data.module1.radius,
          cate_id: page9CatIds.join("|"),
        }).then((page8res) => {
          page9CatData.value = page9CatIds.map((v) => {
            return page8res.data.find((d) => d.cate_id == v);
          });
          setRequestSuccessed("page9");
        }).catch(() => {
          setRequestSuccessed("page9");
        });
      });
    }
    // page10数据
    const page10CatData1 = ref([]);
    const page10CatData2 = ref([]);
    function getPage10(res) {
      // page10
      getHousePriceData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
      }).then((page8res) => {
        page10CatData1.value = page8res.data;
        setRequestSuccessed("page10-1");
      }).catch(() => {
        getHousePriceData({
          location: res.data.module1.location,
          radius: res.data.module1.radius,
        }).then((page8res) => {
          page10CatData1.value = page8res.data;
          setRequestSuccessed("page10-1");
        }).catch(() => {
          setRequestSuccessed("page10-1");
        });
      });
      getHousePriceData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        households: 1,
      }).then((page8res) => {
        page10CatData2.value = page8res.data;
        setRequestSuccessed("page10-2");
      }).catch(() => {
        getHousePriceData({
          location: res.data.module1.location,
          radius: res.data.module1.radius,
          households: 1,
        }).then((page8res) => {
          page10CatData2.value = page8res.data;
          setRequestSuccessed("page10-2");
        }).catch(() => {
          setRequestSuccessed("page10-2");
        });
      });
    }
    // page11
    const page11CatIds = [17];
    const page11CatData = ref([]);
    function getPage11(res) {
      getCatData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        cate_id: page11CatIds.join("|"),
      }).then((pageRes) => {
        page11CatData.value = pageRes.data.find((el) => el.cate_id === 17);
        setRequestSuccessed("page11", pageRes);
      }).catch(() => {
        getCatData({
          location: res.data.module1.location,
          radius: res.data.module1.radius,
          cate_id: page11CatIds.join("|"),
        }).then((pageRes) => {
          page11CatData.value = pageRes.data.find((el) => el.cate_id === 17);
          setRequestSuccessed("page11", pageRes);
        }).catch(() => {
          setRequestSuccessed("page11");
        });
      });
    }
    // page12
    const page12CatIds = [18];
    const page12CatData = ref([]);
    const page12Count = ref(0);
    function getPage12(res) {
      getCatData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        cate_id: page12CatIds.join("|"),
      }).then((pageRes) => {
        page12CatData.value = pageRes.data.find((el) => el.cate_id === 18)?.pois || [];
        page12Count.value = pageRes.data.find((el) => el.cate_id === 18)?.count || 0;
        setRequestSuccessed("page12", pageRes);
      }).catch(() => {
        getCatData({
          location: res.data.module1.location,
          radius: res.data.module1.radius,
          cate_id: page12CatIds.join("|"),
        }).then((pageRes) => {
          page12CatData.value = pageRes.data.find((el) => el.cate_id === 18)?.pois || [];
          page12Count.value = pageRes.data.find((el) => el.cate_id === 18)?.count || 0;
          setRequestSuccessed("page12", pageRes);
        }).catch(() => {
          setRequestSuccessed("page12");
        });
      });
    }

    // page14数据
    const page14CatIds = [184, 185, 186, 188, 189];
    const page14CatData1 = ref({
      pois: [],
    });
    const page14CatData2 = ref({
      pois: [],
    });
    const page14CatData3 = ref({
      pois: [],
    });
    const page14CatData4 = ref({
      pois: [],
    });
    function getPage14(res) {
      getCatData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        cate_id: page14CatIds.join("|"),
      }).then((pageRes) => {
        page14CatData1.value = pageRes.data.find((el) => el.cate_id === 184) || {};
        page14CatData2.value = pageRes.data.find((el) => el.cate_id === 185) || {};
        page14CatData3.value = pageRes.data.find((el) => el.cate_id === 186) || {};
        page14CatData4.value = {
          count:
            (pageRes.data.find((el) => el.cate_id === 188)?.count || 0) +
            (pageRes.data.find((el) => el.cate_id === 189)?.count || 0),
          order_price_avg:
            (pageRes.data.find((el) => el.cate_id === 188)?.order_price_avg || 0) +
            (pageRes.data.find((el) => el.cate_id === 189)?.order_price_avg || 0),
          pois: [
            ...pageRes.data.find((el) => el.cate_id === 188)?.pois || [],
            ...pageRes.data.find((el) => el.cate_id === 189)?.pois || [],
          ],
        };
        setRequestSuccessed("page14");
      }).catch(() => {
        getCatData({
          location: res.data.module1.location,
          radius: res.data.module1.radius,
          cate_id: page14CatIds.join("|"),
        }).then((pageRes) => {
          page14CatData1.value = pageRes.data.find((el) => el.cate_id === 184) || {};
          page14CatData2.value = pageRes.data.find((el) => el.cate_id === 185) || {};
          page14CatData3.value = pageRes.data.find((el) => el.cate_id === 186) || {};
          page14CatData4.value = {
            count:
              (pageRes.data.find((el) => el.cate_id === 188)?.count || 0) +
              (pageRes.data.find((el) => el.cate_id === 189)?.count || 0),
            order_price_avg:
              (pageRes.data.find((el) => el.cate_id === 188)?.order_price_avg || 0) +
              (pageRes.data.find((el) => el.cate_id === 189)?.order_price_avg || 0),
            pois: [
              ...pageRes.data.find((el) => el.cate_id === 188)?.pois || [],
              ...pageRes.data.find((el) => el.cate_id === 189)?.pois || [],
            ],
          };
          setRequestSuccessed("page14");
        }).catch(() => {
          setRequestSuccessed("page14");
        });
      });
    }

    // page16数据
    const page16CatIds = [136];
    const page16CatData1 = ref({
      pois: [],
    });
    function getPage16(res) {
      getCatData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        cate_id: page16CatIds.join("|"),
      }).then((pageRes) => {
        page16CatData1.value = pageRes.data.find((el) => el.cate_id === 136) || { pois: [] };
        setRequestSuccessed("page16");
      }).catch(() => {
        getCatData({
          location: res.data.module1.location,
          radius: res.data.module1.radius,
          cate_id: page16CatIds.join("|"),
        }).then((pageRes) => {
          page16CatData1.value = pageRes.data.find((el) => el.cate_id === 136) || { pois: [] };
          setRequestSuccessed("page16");
        }).catch(() => {
          setRequestSuccessed("page16");
        });
      });
    }
    // page17数据
    const page17CatIds = [618,617,616,615,614,613];
    const page17CatData = ref([]);
    function getPage17(res) {
      getCatData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        cate_id: page17CatIds.join("|"),
      }).then((pageRes) => {
        page17CatData.value = pageRes.data
        setRequestSuccessed("page17");
      }).catch(() => {
        getCatData({
          location: res.data.module1.location,
          radius: res.data.module1.radius,
          cate_id: page17CatIds.join("|"),
        }).then((pageRes) => {
          page17CatData.value = pageRes.data
          setRequestSuccessed("page17");
        }).catch(() => {
          setRequestSuccessed("page17");
        });
      });
    }
    // page18数据
    const page18CatIds1 = [
      {  id: 141 , name: '小吃快餐',  color: '#1644FF' },
      {  id: 142, name: '面包甜点蛋糕',  color: '#FF8116' },
      {  id: 145, name: '异域料理',  color: '#F40000' },
      {  id: 146, name: '饮品店',  color: '#17C49D' },
      {  id: 147, name: '火锅',  color: '#FF00BD' },
      {  id: 148, name: '鱼鲜海鲜',  color: '#00F7FF' },
      {  id: 149, name: '自助餐',  color: '#FFD500' },
      {  id: 143, name: '地方菜系',  color: '#91B802' },
      {  id: 144, name: '烧烤烤肉',  color: '#8316FF' },
    ]
    const page18CatData1 = ref([]);
    const page18CatIds2 = [
      578, 581, 582, 584, 590, 583, 597, 600, 602, 601, 605, 147, 149,
    ];
    const page18CatData2 = ref([]);

    async function getPage18(res) {
      getCatData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        cate_id: page18CatIds2.join("|"),
      }).then((pageRes) => {
        page18CatData2.value = pageRes.data;
        setRequestSuccessed("page18-2");
      }).catch(() => {
        getCatData({
          location: res.data.module1.location,
          radius: res.data.module1.radius,
          cate_id: page18CatIds2.join("|"),
        }).then((pageRes) => {
          page18CatData2.value = pageRes.data;
          setRequestSuccessed("page18-2");
        }).catch(() => {
          setRequestSuccessed("page18-2");
        });
      });

      try {
        for (let i = 0; i < page18CatIds1.length; i++) {
          const pageRes = await getCatData({
            location: res.data.module1.location,
            radius: res.data.module1.radius,
            cate_id: page18CatIds1[i].id,
          })
          let item = {
            ...page18CatIds1[i],
            value: 0,
            pois: []
          }
          pageRes.data.forEach(el => {
            item.value+= el.pois.length
            item.pois = [...item.pois, ...el.pois || []]
          })
          page18CatData1.value.push(item)
        }
        setRequestSuccessed("page18");
      } catch (e) {
        try {
          for (let i = 0; i < page18CatIds1.length; i++) {
            const pageRes = await getCatData({
              location: res.data.module1.location,
              radius: res.data.module1.radius,
              cate_id: page18CatIds1[i].id,
            })
            let item = {
              ...page18CatIds1[i],
              value: 0,
              pois: []
            }
            pageRes.data.forEach(el => {
              item.value+= el.pois.length
              item.pois = [...item.pois, ...el.pois || []]
            })
            page18CatData1.value.push(item)
          }
          setRequestSuccessed("page18");
        } catch (e) {
          setRequestSuccessed("page18");
        }
      }
    }
    // page19数据
    const page19CatIds = [150, 44,152, 155, 156, 157, 158];
    const page19CatData = ref([])
    function getPage19(res) {
      getCatData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        cate_id: page19CatIds.join("|"),
      }).then((pageRes) => {
        page19CatData.value = pageRes.data
        setRequestSuccessed("page19");
      }).catch(() => {
        getCatData({
          location: res.data.module1.location,
          radius: res.data.module1.radius,
          cate_id: page19CatIds.join("|"),
        }).then((pageRes) => {
          page19CatData.value = pageRes.data
          setRequestSuccessed("page19");
        }).catch(() => {
          setRequestSuccessed("page19");
        });
      });
    }

    // page20数据
    const page20CatIds = [26, 28, 29, 30, 32, 36, 35];
    const page20CatData = ref([])
    function getPage20(res) {
      getCatData({
        location: res.data.module1.location,
        radius: res.data.module1.radius,
        cate_id: page20CatIds.join("|"),
      }).then((pageRes) => {
        page20CatData.value = pageRes.data
        setRequestSuccessed("page20");
      }).catch(() => {
        getCatData({
          location: res.data.module1.location,
          radius: res.data.module1.radius,
          cate_id: page20CatIds.join("|"),
        }).then((pageRes) => {
          page20CatData.value = pageRes.data
          setRequestSuccessed("page20");
        }).catch(() => {
          setRequestSuccessed("page20");
        });
      });
    }
    const page21Data = ref({});
    // 三个页面状态独立
    const wrapPage21 = (data) => {
      if (data?.tuijian_qinzi?.length) {
        setRequestSuccessed("page21");
      }
      if (data?.tuijian_canyin?.length) {
        setRequestSuccessed("page22");
      }
      if (data?.tuijian_lingshou?.length) {
        setRequestSuccessed("page23");
      }
    }
    function getPage21() {
      getBrandData(address_id).then((pageRes) => {
        const { data } = pageRes
        page21Data.value = data;
        wrapPage21(data)
      }).catch(() => {
        getBrandData(address_id).then((pageRes) => {
          const { data } = pageRes
          page21Data.value = data;
          wrapPage21(data)
        }).catch(() => {
          console.log('挂了');
          // setRequestSuccessed("page21");
        });
      });
    }
    /**
     * 页面渲染完成开始加载数据
     */
    const store = useStore()
    onMounted(() => {
      // 报表数据
      getReport(address_id).then((res) => {
        if (Array.isArray(res.data)) {
          isEmptyPage.value = res.info
          return false
        }
        reportData.value = {
          ...(res.data || {}),
        };
        // 缓存基本报告数据
        store.commit('SET_BASIC_REPORT', reportData.value)
        setRequestSuccessed("reportData");
        getPage4(res);
        getPage8(res);
        getPage9(res);
        getPage10(res);
        getPage11(res);
        getPage12(res);
        getPage14(res);
        getPage16(res);
        getPage17(res);
        getPage18(res);
        getPage19(res);
        getPage20(res);
        getPage21(res);
      });
    });
    return {
      requestSuccessedMap,
      reportData,
      isEmptyPage,
      page4CatData,
      page8CatData,
      page9CatData,
      page10CatData1,
      page10CatData2,
      page11CatData,
      page12CatData,
      page12Count,
      page14CatData1,
      page14CatData2,
      page14CatData3,
      page14CatData4,
      page16CatData1,

      page17CatData,

      page18CatData1,
      page18CatData2,

      page19CatData,

      page20CatData,
      page21Data,
    };
  },
});
</script>

<style lang="less">
.container {
  width: 1920px;
}
.empty-page {
  font-size: 48px;
  width: 1920px;
  height: 1080px;
  line-height: 1080px;
  text-align: center;
}
.page-box {
  width: 1920px;
  height: 1080px;

  .amap-container {
    position: relative;
  }
  .map-img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
  }
}
.report-page {
  .block {
    height: 1080px;
    width: 100%;
  }
  .color-red {
    color: #f40000;
    margin-right: 10px;
  }
  .f-14 {
    font-size: 14px;
  }
  .arrow-up {
    width: 13px;
    height: 13px;
    margin-right: 5px;
    background-image: url("~@/assets/arrow-up.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .arrow-up-color {
    color: #f40000;
  }
  .arrow-down-color {
    color: #05c44b;
  }
  .arrow-down {
    width: 13px;
    height: 13px;
    margin-right: 5px;
    background-image: url("~@/assets/arrow-down.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .etable {
    font-size: 20px;
    .etable-wrapper {
      width: 50%;
    }
    table {
      border-spacing: 1px;
      width: 100%;
      tr.empty-row {
        width: 100%;
        background: #f2f2f2;
        td {
          background: #f2f2f2 !important;
          border-spacing: 0 !important;
        }
      }

      tr {
        td {
          height: 51px;
          background: #dbdbdb;

          font-weight: bold;
          color: #333333;
          line-height: 20px;
          padding-left: 15px;
        }
        td:nth-child(1) {
          text-align: left;
        }
        td:nth-child(2n) {
          text-align: center;
          background: #f2f2f2;
          font-weight: 400;
        }
      }
    }
    .etable-wrapper:nth-child(2) {
      margin-left: -0.5px;
    }
  }
}
</style>
