import request from "@/utils/request";

export function getReport(
  address_id,
  module_ids = "1|2|3|4|5|6|7|8|9|10|11|12|13|14|15"
) {
  return request({
    url: "/api/market_survey_report/urban_insight_pdf",
    method: "post",
    params: {
      address_id,
      module_ids,
    },
  });
}

// 周边数据替换poi 使用 Java 接口
export function getPoiData({ location, radius, bhProType, cityCode = [], showPoiList = true }) {
  return request({
    url: "/center-bh-pro-poi-core/data/baseReport",
    method: "post",
    data: {
      location,
      radius: radius * 1000,
      bhProType,
      cityCode,
      showPoiList,
    },
  });
}

// 获取周边数据 Java 接口
export function getCityAroundData({ addressId, location, radius }) {
  return request({
    url: "/center-bh-pro-poi-core/data/cityDetail",
    method: "get",
    params: {
      addressId,
      location,
      radius
    },
  });
}

// 获取城市数据 Java接口
export function getCityDetailData(addressId) {
  return request({
    url: "/center-bh-pro-poi-core/data/cityDetailData",
    method: "get",
    params: {
      addressId,
    },
  });
}

// 商城数据
export function getMarketPoiData({ location, radius, bhProType, showPoiList = true }) {
  return request({
    url: "/center-bh-pro-poi-core/data/market",
    method: "post",
    data: {
      location,
      radius: radius * 1000,
      bhProType,
      showPoiList,
    },
  });
}

// 旧接口还保留 不是完全替换 部分页面还在使用
export function getCatData({ location, radius, cate_id, return_type = "all" }) {
  return request({
    url: "/api/poi/distribution",
    method: "post",
    params: {
      location,
      radius: radius * 1000,
      cate_id,
      return_type,
      list_filter: 1,
    },
  });
}

export function getHousePriceData({ location, radius, households }) {
  return request({
    url: "/api/data_map/house_price",
    method: "post",
    params: {
      location,
      radius: radius * 1000,
      households,
      source: 1,
      list_filter: 1,
    },
  });
}

export function getBrandData(address_id) {
  return request({
    url: "/api/market_survey_report/recommend_brand",
    method: "post",
    params: {
      address_id
    },
  });
}

/**
 * 获取商区围栏数据
 * @param location
 * @param radius
 * @return {AxiosPromise}
 */
export function getBusPolygon({ location, radius }) {
  return request({
    url: "/api/home_page/fence_data_by_location",
    method: "post",
    params: {
      location,
      radius: radius * 1000
    },
  });
}

// 绘制地图保持连接
export const drawMapKeepAlive = (millisecond) => {
  return request({
    url: "/api/keep_connection",
    method: "post",
    params: {
      millisecond
    },
  });
}