<template>
  <div class="line-chart f-r f-c">
    <div class="chart-canvas">
      <VChart
        style="width: 100%; height: 100%"
        :option="option"
        autoresize
      ></VChart>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
export default defineComponent({
  props: {
    unit: String,
    xData: {
      type: Array,
      default: () => [],
    },
    yData: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const option = computed(() => {
      return {
        color: ["#000"],
        grid: {
          top: 45,
          left: -30,
          right: 0,
          bottom: 40,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: props.xData.map((el, index) => {
            const hlight = index === 0;
            let name = ''
            const nameList = el.split('')
            while (nameList.length) {
              name += ('\n'  + nameList.splice(0,3).join(''))
            }
            return {
              value:name,
              textStyle: {
                fontSize: 14,
                lineHeight: 16,
                color: hlight ? "#f40000" : "#666666",
              },
            };
          }),
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          show: false,
        },
        series: {
          type: "bar",
          // triggerLineEvent: true,
          data: props.yData.map((value, valueIndex) => {
            let hlight = valueIndex === 0;
            return {
              name: props.xData[valueIndex],
              value: value,
              label: {
                show: true,
                position: "top",
                color: hlight ? "#f40000" : "#909090",
                fontSize: 20,
              },
              itemStyle: {
                color: hlight ? "#f40000" : "#DDDDDD",
                borderRadius: 8,
              },
            };
          }),
        },
      };
    });
    return {
      option,
    };
  },
});
</script>

<style lang="less">
.line-chart {
  padding: 12px;
  background: #f6f7fa;
  .unit {
    width: 100%;
    text-align: right;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;
  }
  .chart-canvas {
    flex-grow: 1;
  }
}
</style>
