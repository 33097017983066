export function mapImage (id) {
//    setTimeout(() => {
//        html2Canvas(
//            document.getElementById(id), // 要截图的容器id
//            {
//                backgroundColor:null,//画出来的图片有白色的边框,不要可设置背景为透明色（null）
//                useCORS: true,//支持图片跨域
//                scale:2,//设置放大的倍数
//                dpi: window.devicePixelRatio*2, //将分辨率提高到特定的DPI 提高四倍
//            }
//        ).then(canvas => {
//            //截图用img元素承装，显示在页面的上
//            let img = new Image();
//            img.src = canvas.toDataURL('image/jpeg',);// toDataURL :图片格式转成 base64
//            document.getElementById(id).appendChild(img); // 这是看截图的页面承载，可以删掉
//            img.className = 'map-img'
//            // //如果你需要下载截图，可以使用a标签进行下载
//            // let a = document.createElement('a');
//            // a.href = canvas.toDataURL('image/jpeg');
//            // a.download = 'test';
//            // a.click();
//        })
//    },4000)
}

/**
 * 绘制围栏
 * @param cityInfo
 * @param map
 * @return {AMap.Circle}
 */
export function mapAddPolygon(list) {
  if (!list || !list.length) return []
  let _list = list.map(v => {
    const points = v.all_point.split(';')
    return {
      ...v,
      path: points.map(v2 => {
        const l = v2.split(',')
        return new AMap.LngLat(l[0], l[1])
      })
    }
  })
  return _list.map(v => {
    const polygon = new AMap.Polygon({
      zIndex: 150,
      path: v.path,
      strokeColor: "#F40000",  //线颜色
      strokeOpacity: 1,  //线透明度
      strokeWeight: 1,  //线粗细度
      fillColor: "#FF8C00",  //填充颜色
      fillOpacity: 0.6 //填充透明度
    });
    return {
      name: v.circle_name,
      polygon,
    }
  })
}
export function mapAddCircle (cityInfo, map) {
    const center = cityInfo.location.split(",")
    const circle = new AMap.Circle({
      center: new AMap.LngLat(center[0], center[1]), // 圆心位置
      radius: cityInfo.radius * 1000,  //半径
      strokeColor: "#F40000",  //线颜色
      strokeOpacity: 1,  //线透明度
      strokeWeight: 1,  //线粗细度
      fillColor: "#F40000",  //填充颜色
      fillOpacity: 0.07 //填充透明度
  });
  map.add(circle);
  return circle
 }

/**
 * 地图绘制中心定位点
 * @param amap 地图实例
 * @param map 当前地图对象
 * @param center 中心点坐标 字符串 英文逗号分割
 */
export const addMapCenter = (amap, map, center) => {
  const wrapCenter = center.split(",")
  const centerMark = new amap.Marker({
    offset: [-17, -40],
    position: new amap.LngLat(
      wrapCenter[0],
      wrapCenter[1]
    ),
    content: `<div class="center-marker" />`,
  });
  map.add(centerMark)
}

/**
 * 绘制透明边界
 * @param cityInfo
 * @param map
 * @param radius
 * @return {AMap.Circle}
 */
export function mapAddCircle2 (cityInfo, map, radius) {
  const center = cityInfo.location.split(",")
  const circle = new AMap.Circle({
    center: new AMap.LngLat(center[0], center[1]), // 圆心位置
    radius: radius * 1000,  //半径
    strokeColor: "#F40000",  //线颜色
    strokeOpacity: 0,  //线透明度
    strokeWeight: 0,  //线粗细度
    fillColor: "#F40000",  //填充颜色
    fillOpacity: 0 //填充透明度
  });
  map.add(circle);
  return circle
}

export const mapZoom = 13
