<template>
  <div class="block page8 f-r f-c">
    <PageHeader :title="moduleData['3_8_1_1']" :sub-title="moduleData['3_8_1_1_en']" />
    <PageTips page-num="05"/>
    <div class="page8-body f-r j-b">
      <div class="page8-body-left">
        <TableNav width="899px">
          <template v-slot:title>{{ moduleData["3_8_2_2"] }}</template>
        </TableNav>
        <!-- 周边人口规模-->
        <div class="etable f-r">
          <div class="etable-wrapper">
            <table>
              <tbody>
                <tr>
                  <td style="width: 225px">
                    <div class="cell">{{ moduleData["3_8_3_2"][0][0] }}</div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell">
                      {{ moduleData["3_8_3_2"][0][1] }}
                      {{ moduleData["3_8_3_2"][0][2] }}
                    </div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell">{{ moduleData["3_8_3_2"][1][0] }}</div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell">
                      {{ moduleData["3_8_3_2"][1][1] }}
                      {{ moduleData["3_8_3_2"][1][2] }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- 周边小区-->
        <TableNav width="899px">
          <template v-slot:title>{{ moduleData["3-8-4-3"] }}</template>
        </TableNav>
        <div class="etable f-r">
          <div class="etable-wrapper">
            <table>
              <tbody>
                <tr>
                  <td style="width: 225px" rowspan="2">
                    <div class="cell">{{ moduleData["3_8_5_3"][0][0] }}</div>
                  </td>
                  <td style="width: 225px" rowspan="2">
                    <div class="cell">
                      {{ moduleData["3_8_5_3"][0][1] }}
                      {{ moduleData["3_8_5_3"][0][2] }}
                    </div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell">{{ moduleData["3_8_5_3"][1][0] }}</div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell">
                      {{ moduleData["3_8_5_3"][1][1] }}
                      {{ moduleData["3_8_5_3"][1][2] }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="width: 225px">
                    <div class="cell">{{ moduleData["3_8_5_3"][2][0] }}</div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell">
                      {{ moduleData["3_8_5_3"][2][1] }}
                      {{ moduleData["3_8_5_3"][2][2] }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="width: 225px">
                    <div class="cell">{{ moduleData["3_8_6_4"].residential_house_price.tip }}</div>
                  </td>
                  <td style="width: 225px" colspan="1">
                    <div class="cell">
                      {{ moduleData["3_8_6_4"].residential_house_price.val }}
                      {{
                      moduleData["3_8_6_4"].residential_house_price.company
                      }}
                    </div>
                  </td>
                  <td style="width: 225px;background-color: #f2f2f2" colspan="2">
                  </td>
                </tr>
                <tr>
                  <td style="width: 225px">
                    <div
                      class="cell f-r a-c"
                    >{{ moduleData["3_8_6_4"].residential_house_price.tip1 }}</div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell f-r a-c j-c">
                      <div
                        :class="[
                          getArrowIcon(
                            moduleData['3_8_6_4'].residential_house_price
                              .tip1_plus_minus
                          ),
                        ]"
                      ></div>
                      <span
                        :class="[
                          getArrowColor(
                            moduleData['3_8_6_4'].residential_house_price
                              .tip1_plus_minus
                          ),
                        ]"
                      >
                        {{
                        moduleData["3_8_6_4"].residential_house_price
                        .tip1_percent
                        }}
                      </span>
                    </div>
                  </td>
                  <td style="width: 225px">
                    <div
                      class="cell f-r a-c"
                    >{{ moduleData["3_8_6_4"].residential_house_price.tip2 }}</div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell f-r a-c j-c">
                      <div
                        :class="[
                          getArrowIcon(
                            moduleData['3_8_6_4'].residential_house_price
                              .tip2_plus_minus
                          ),
                        ]"
                      ></div>
                      <span
                        :class="[
                          getArrowColor(
                            moduleData['3_8_6_4'].residential_house_price
                              .tip2_plus_minus
                          ),
                        ]"
                      >
                        {{
                        moduleData["3_8_6_4"].residential_house_price
                        .tip2_percent
                        }}
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- 周边写字楼-->
        <TableNav width="899px">
          <template v-slot:title>{{ moduleData["3_8_7_5"].name }}</template>
        </TableNav>
        <div class="etable f-r">
          <div class="etable-wrapper">
            <table>
              <tbody>
                <tr>
                  <td style="width: 225px">
                    <div class="cell">{{ moduleData["3_8_7_5"].office_build_sum.tip }}</div>
                  </td>
                  <td style="width: 225px" colspan="1">
                    <div class="cell">
                      {{ moduleData["3_8_7_5"].office_build_sum.val }}
                      {{ moduleData["3_8_7_5"].office_build_sum.company }}
                    </div>
                  </td>
                  <td style="width: 225px;background-color: #f2f2f2" colspan="2">
                  </td>
                </tr>
                <tr>
                  <td style="width: 225px">
                    <div class="cell">{{ moduleData["3_8_7_5"].daily_rent_avg.tip }}</div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell">
                      {{ moduleData["3_8_7_5"].daily_rent_avg.val }}
                      {{ moduleData["3_8_7_5"].daily_rent_avg.company }}
                    </div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell">{{ moduleData["3_8_7_5"].layers_avg.tip }}</div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell">
                      {{ moduleData["3_8_7_5"].layers_avg.val }}
                      {{ moduleData["3_8_7_5"].layers_avg.company }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="width: 225px">
                    <div class="cell">{{ moduleData["3_8_7_5"].month_rent_avg.tip }}</div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell">
                      {{ moduleData["3_8_7_5"].month_rent_avg.val }}
                      {{ moduleData["3_8_7_5"].month_rent_avg.company }}
                    </div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell">{{ moduleData["3_8_7_5"].completion_time_avg.tip }}</div>
                  </td>
                  <td style="width: 225px">
                    <div class="cell">
                      {{ moduleData["3_8_7_5"].completion_time_avg.val }}
                      {{ moduleData["3_8_7_5"].completion_time_avg.company }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="page8-body-right f-r f-w j-b">
        <div class="page8-chart-box">
          <PieChart title="商业配套" unit="家" :chart-data="chart1" />
        </div>
        <div class="page8-chart-box">
          <PieChart title="酒店" unit="家" :chart-data="chart2" />
        </div>
        <div class="page8-chart-box">
          <PieChart title="公共配套" unit="个" :chart-data="chart3" />
        </div>
        <div class="page8-chart-box">
          <PieChart title="教育配套" unit="所" :chart-data="chart4" />
        </div>
      </div>
    </div>
    <div class="each_region_pop-tips">数据来源：位置厂商数据，边界房产大数据</div>
  </div>
</template>

<script>
import PageHeader from "./components/PageHeader";
import PageTips from "./components/PageTips";
import TableNav from "./components/TableNav";
import PieChart from "./components/PieChart.vue";

import { computed, defineComponent } from "vue";
export default defineComponent({
  components: {
    PageHeader,
    PageTips,
    TableNav,
    PieChart,
  },
  props: {
    moduleData: {
      type: Object,
      default: () => {}
    },
    catData: {
      type: Array,
      default: () => {
        return [[], [], [], []];
      },
    },
  },
  setup(props) {
    function getArrowIcon(plus_minus) {
      if (plus_minus === "+") return "arrow-up";
      if (plus_minus === "-") return "arrow-down";
      return "";
    }
    function getArrowColor(plus_minus) {
      if (plus_minus === "+") return "arrow-up-color";
      if (plus_minus === "-") return "arrow-down-color";
      return "";
    }
    const chart1 = computed(() => {
      return props.catData[0].map((el) => {
        return {
          name: el.cate_name,
          value: el.count,
        };
      });
    });
    const chart2 = computed(() => {
      if (props.catData[1].length === 0) return [];
      let star = props.catData[1].filter((el) => el.cate_name.includes("星"));
      let data = props.catData[1].filter((el) => !el.cate_name.includes("星"));
      let starobj = {
        cate_name: "星级",
        count: star.map((el) => el.count).length ? star.map((el) => el.count).reduce((pre, next) => pre + next) : 0,
      };
      if (!starobj.count) {
        data.splice(1, 0);
      } else {
        data.splice(1, 0, starobj);
      }
      return data.map((el) => {
        return {
          name: el.cate_name,
          value: el.count,
        };
      });
    });
    const chart3 = computed(() => {
      return props.catData[2].map((el) => {
        return {
          name: el.cate_name,
          value: el.count,
        };
      });
    });
    const chart4 = computed(() => {
      return props.catData[3].map((el) => {
        return {
          name: el.cate_name,
          value: el.count,
        };
      });
    });
    return {
      getArrowIcon,
      getArrowColor,
      chart1,
      chart2,
      chart3,
      chart4,
    };
  },
});
</script>

<style lang="less">
.page8 {
  position: relative;
  background: #ffffff;
  padding: 26px 60px 32px 0;
  .etable .etable-wrapper {
    width: 100% !important;
  }
  .page8-body {
    flex-grow: 1;
    padding: 17px 20px 0 39px;
    .page8-body-left {
      width: 900px;
    }
    .page8-body-right {
      width: 890px;
      .page8-chart-box {
        width: 440px;
        height: 400px;
        background: #ffffff;
        border: 1px solid #333333;
        margin-bottom: 10px;
      }
    }
  }
  .each_region_pop-tips {
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: right;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9a9a9a;
    justify-content: flex-end;
    padding-right: 20px;
  }
}
</style>
