<template>
  <div class="radar-chart">
    <slot></slot>
    <VChart style="width: 100%; height: 100%" :option="option" autoresize></VChart>
  </div>
</template>

<script>
export default {
    props: {
        chartData: Array
    },
  computed: {
    option() {
        if (!this.chartData || !this.chartData.length) return {}
      return {
        radar: {
          // shape: 'circle',
          indicator: [
            {
              text: "经济规模",
              max: 10,
              value: this.chartData[0]
            },
            {
              text: "消费能级",
              max: 10,
                value: this.chartData[4]
            },
            {
              text: "人口规模",
              max: 10,
                value: this.chartData[3]
            },
            {
              text: "商圈活跃度",
              max: 10,
                value: this.chartData[2]
            },
            {
              text: "交通便利性",
              max: 10,
                value: this.chartData[1]
            },
          ],
          name: {
            formatter: function (params, i) {
              return "{yellow|" + i.text + "\n}{white| " + i.value + "分}";
            },
            rich: {
              yellow: {
                color: "#000000",
                fontSize: 24,
                padding: [0, 0, 6, 0],
              },
              white: {
                color: "#F40000",
                fontSize: 36,
              },
            },
          },
        },
        series: [
          {
            name: "Budget vs spending",
            type: "radar",
            symbol: "none",
            areaStyle: {
              color: "#FF5B48",
            },
            lineStyle: {
              color: "rgba(0,0,0,0.23)",
            },
            data: [
              {
                value: [this.chartData[0], this.chartData[4], this.chartData[3], this.chartData[2], this.chartData[1]],
                name: "Allocated Budget",
              },
            ],
          },
        ],
      };
    },
  },
};
</script>
<style lang="less">
.radar-chart {
  height: 100%;
  padding: 12px;
  position: relative;
}
</style>
