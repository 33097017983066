<template>
  <div
    class="block page1"
    :style="{ backgroundImage: `url(${moduleData?.background_img || DEFAULT_BG})` }"
    id="page1"
  >
    <div class="page1-top f-r">
      <div class="desc">
        <div class="title">{{ moduleData.report_name }}</div>
        <div class="title2">{{ moduleData.report_name_en }}</div>
        <div class="title3">{{moduleData.date}}</div>
      </div>
    </div>
    <div class="page1-bottom f-r a-c">
      <div class="round f-s">调研半径：{{ moduleData.radius }}km</div>
      <div class="address">地址：{{ moduleData.address }}</div>
    </div>
  </div>
</template>

<script>
import DEFAULT_BG from '@/assets/bg-front.png'

export default {
  name: "Page1",
  props: {
    moduleData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  setup() {
    return {
      DEFAULT_BG,
    }
  }
};
</script>

<style lang="less" scoped>
.report-page {
  font-family: PingFangSC-Semibold, PingFang SC;

  .page1 {
    display: flex;
    flex-direction: column;
    //background-image: url("~@/assets/bg.png");
    background-size: 100% 1080px;
    background-repeat: no-repeat;

    .page1-top {
      flex: 1;
      display: flex;
      align-items: center;

      .desc {
        padding-top: 80px;
        padding-left: 202px;
        width: 100%;

        .title {
          max-width: 80%;
          min-height: 118px;
          line-height: 118px;
          font-size: 84px;
          font-weight: 600;
          color: #333333;
        }
        .title2 {
          height: 67px;
          font-size: 48px;

          font-weight: bold;
          color: #333333;
          line-height: 67px;
          margin-top: 4px;
        }
        .title3 {
          width: 295px;
          height: 67px;
          font-size: 48px;
          font-weight: 400;
          color: #333333;
          line-height: 67px;
          margin-top: 23px;
        }
      }
    }

    .page1-bottom {
      width: 100%;
      min-height: 118px;
      background: rgba(51, 51, 51, 0.39);
      padding: 0 60px;
      font-size: 36px;
      font-weight: bold;
      color: #ffffff;
      line-height: 48px;
      .round {
        flex-shrink: 0;
        margin-right: 136px;
      }
    }
  }
}
</style>
