<template>
  <div class="block page24">
    <div class="bg">
      <img :src="moduleData?.background_img || DEFAULT_COVER" alt="">
    </div>
<!--    <PageTips />-->
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DEFAULT_COVER from '@/assets/bg-back.png'

// import PageTips from "./components/PageTips";
export default defineComponent({
  // components: { PageTips },
  props: {
    moduleData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  setup() {
    return {
      DEFAULT_COVER,
    }
  }
});
</script>

<style lang="less" scoped>
.page24 {
  background: #333333;
  position: relative;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1080px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
