<template>
  <div class="page-header f-r a-c">
    <div class="box"></div>
    <div class="h-right f-r f-c j-c">
      <div class="h-title">
        {{ title }}
      </div>
      <div class="h-sub-title">
        {{ subTitle }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    title: String,
    subTitle: String,
  },
});
</script>

<style lang="less">
.report-page {
  .page-header {
    .box {
      width: 90px;
      height: 80px;
      background: #333333;
      margin-right: 20px;
    }
    .h-title {
      height: 50px;
      font-size: 36px;

      font-weight: bold;
      color: #333333;
      line-height: 50px;
    }
    .h-sub-title {
      height: 30px;
      font-size: 22px;

      font-weight: bold;
      color: #333333;
      line-height: 30px;
      margin-top: 3px;
    }
  }
}
</style>
