<template>
  <div class="block page15 f-r f-c">
    <PageHeader :title="moduleData['3_15_1_0']" :sub-title="moduleData['3_15_1_0_en']"/>
    <PageTips page-num="12"/>
    <div class="page15-body f-r j-b">
      <div class="page15-body-left">
        <TableNav width="900px">
          <template v-slot:title> 周边中学（最多展示TOP 5） </template>
          <template v-slot:subtitle> 共 {{catData1.count || 0}} 所 </template>
        </TableNav>
        <PTable :col-data="colData" :row-data="rowData1.slice(0,5)" style="height: 314px">
          <Empty text="当前区域暂无中学覆盖" v-if="!rowData1.slice(0,5).length"/>
        </PTable>
        <TableNav width="900px" style="margin-top: 40px">
          <template v-slot:title> 周边高等院校（最多展示TOP 5）</template>
          <template v-slot:subtitle> 共 {{catData2.count || 0}} 所 </template>
        </TableNav>
        <PTable :col-data="colData" :row-data="rowData2.slice(0,5)" style="height: 314px">
          <Empty text="当前区域暂无高等院校覆盖" v-if="!rowData2.slice(0,5).length"/>
        </PTable>
        <div class="page-tips2" style="padding-bottom: 27px;margin-top: 23px">>更多教育配套于APP「数据地图/市调详情_周边配套」中查看</div>

      </div>
      <div class="page15-body-right">
        <div class="page15-body-right f-c">
          <div class="page15-map" id="page15Map">
            <Empty text="当前区域暂无学校覆盖" v-if="[
                        ...catData1.pois,
                        ...catData2.pois,
                    ].length === 0"/>
          </div>
          <div class="page15-map-legend f-r a-c">
            <div class="page15-map-legend-item f-r a-c">
              <div class="page15-map-legend-point point1"></div>
                中学
            </div>
            <div class="page15-map-legend-item f-r a-c">
              <div class="page15-map-legend-point point2"></div>
                高等院校
            </div>
          </div>
          <div class="page15-map-tips" >
            数据来源：位置厂商数据，边界教育数据整合
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "./components/PageHeader";
import PageTips from "./components/PageTips";
import TableNav from "./components/TableNav";
import PTable from "./components/PTable";

import AMapLoader from "@amap/amap-jsapi-loader";
import { shallowRef } from "@vue/reactivity";

import { computed, defineComponent, onMounted, ref } from "vue";
import { mapAddCircle, addMapCenter } from "@/utils/pdf";
import { useStore } from "vuex";

import { getMapDelayByMarkerCount } from '@/utils'
import {
  drawMapKeepAlive,
} from '@/api/apiv1'

export default defineComponent({
  components: {
    PageHeader,
    PageTips,
    TableNav,
    PTable,
  },
  props: {
      cityInfo: {
          type: Object,
          default: () => {
              return {};
          },
      },
      catData1: {
          type: Object,
          default: () => {
              return {
                  pois: []
              };
          },
      },
      catData2: {
          type: Object,
          default: () => {
              return {
                  pois: []
              };
          },
      },
    moduleData: {
      type: Object,
      default: () => {
        return {
        };
      },
    },
  },
    setup(props) {
    const store = useStore()
        const colData = ref([
            { prop: "index", width: "67px", label: "序号" },
            { prop: "name", width: "312px", label: "名称" },
            { prop: "educational_level", width: "255px", label: "能级" },
            { prop: "distance", width: "265px", label: "距离", subLabel: "km" },
        ]);
        const rowData1 = computed(() => {
            return props.catData1.pois.map((el,index) => {
                let _index = index + 1
                return {
                    index: _index < 10 ? ('0' + _index) : _index,
                    ...el
                }
            })
        });
        const rowData2 = computed(() => {
            return props.catData2.pois.map((el,index) => {
                let _index = index + 1
                return {
                    index: _index < 10 ? ('0' + _index) : _index,
                    ...el
                }
            })
        });
        let map = shallowRef(null);
        const addMaker = (amap, map, newData, color) => {
            let markerList = [];
            newData.forEach((v) => {
              let marker = new amap.Marker({
                position: new amap.LngLat(
                  v.location.split(",")[0],
                  v.location.split(",")[1]
                ),
                content: `<div style="width: 12px;height: 12px; border-radius: 50%;background: ${color};"></div>`
              });
              markerList.push(marker);
            });
            map.add(markerList);
        };
        onMounted(() => {
            AMapLoader.load({
                key: "f0da9fb8263bb9b657e2257ec09c6df7", // 申请好的Web端开发者Key，首次调用 load 时必填
                version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            }).then((AMap) => {
              map = new AMap.Map("page15Map", {
                WebGLParams: {
                    preserveDrawingBuffer:true
                },
                dragEnable: false,
                zoomEnable: false,
                zoom: store.getters.mapZoom, //初始化地图级别
                center: props.cityInfo.location.split(","), //初始化地图中心点位置
              });

            addMaker(AMap, map,props.catData1.pois, '#1644FF');
            addMaker(AMap, map,props.catData2.pois, '#FF8116');
            mapAddCircle(props.cityInfo, map)
            addMapCenter(AMap, map, props.cityInfo.location)

            // 等待地图渲染
            const totalCount = props.catData1.pois.length + props.catData2.pois.length
            const delay = getMapDelayByMarkerCount(totalCount || 0)
            drawMapKeepAlive(delay).catch((e) => {})

            map.setFitView()
          })
          .catch((e) => {
              console.log(e);
          });
        });

        return {
            map,
            colData,
            rowData1,
            rowData2,
        };
    },
});
</script>

<style lang="less">
.page15 {
  position: relative;
  background: #ffffff;
  padding: 26px 60px 32px 0;
  .page15-body {
    flex-grow: 1;
    padding: 67px 20px 0 61px;
    td,
    th {
      height: 50px !important;
    }
    .page15-body-left {
      width: 900px;
    }
    .page15-body-right {
      width: 853px;
      .page15-map {
        width: 100%;
        height: 760px;
      }
      .page15-map-legend {
        flex-grow: 1;
        padding: 23px 0 23px 46px;
        .page15-map-legend-item {
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 28px;
          margin-right: 76px;
        }
        .page15-map-legend-item:last-child {
          margin-right: 0;
        }
        .page15-map-legend-icon {
          width: 22px;
          height: 22px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-right: 12px;
        }
        .icon1 {
          background-image: url("~@/assets/机场.png");
        }
        .icon2 {
          background-image: url("~@/assets/火车站.png");
        }
        .icon3 {
          background-image: url("~@/assets/高铁站.png");
        }
        .page15-map-legend-point {
          width: 22px;
          height: 22px;
          margin-right: 12px;
          border-radius: 50%;
        }
        .point1 {
          background-color: #1644ff;
        }
        .point2 {
          background-color: #ff8116;
        }
      }
      .page15-map-tips {
        flex-shrink: 0;
        text-align: right;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9a9a9a;
        line-height: 28px;
      }
    }
  }
}
</style>
