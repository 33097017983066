<template>
  <div class="block page18 f-r f-c">
    <PageHeader :title="moduleData['3_18_1_1']" :sub-title="moduleData['3_18_1_1_en']"/>
    <PageTips page-num="15"/>
    <div class="page18-body f-r j-b">
      <div class="page18-body-left">
        <div class="page18-body-header f-r j-b a-c">
          <div class="page18-body-header-left">周边餐饮门店 共 {{ total }} 家</div>
<!--          <div class="page18-body-header-right">平均客单：{{ total.order_price_avg }} 元</div>-->
        </div>
        <PieChart style="height: 500px; width: 100%" :chart-data="pieData">
          <Empty text="当前区域暂无餐饮覆盖" v-if="!poisList.length"/>
        </PieChart>
        <div class="page18-body-header f-r j-b a-c" style="margin-bottom: 10px">
          <div class="page18-body-header-left">区域餐饮品类数量分布（家）</div>
        </div>
        <BarChart style="height: 265px; width: 100%"
                  :x-data="barData.map(v => v.name)"
                  :y-data="barData.map(v => v.value)">
          <Empty text="当前区域暂无餐饮覆盖" v-if="!barData.length"/>
        </BarChart>
      </div>
      <div class="page18-body-right">
        <div class="page18-body-right f-c">
          <div class="page18-map" id="page18Map">
            <Empty text="当前区域暂无餐饮覆盖" v-if="!poisList.length"/>
          </div>
          <div class="page18-map-legend f-r a-c f-w">
            <div
              class="page18-map-legend-item f-r a-c"
              v-for="(item, index) in typeList"
              :key="index"
            >
              <div
                class="page18-map-legend-point point1"
                :style="{ background: item.color }"
              ></div>
              {{ item.name }}
            </div>
          </div>
          <div class="page18-map-tips">
            数据来源：餐饮外卖平台边界大数据整合
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "./components/PageHeader";
import PageTips from "./components/PageTips";
import PieChart from "./components/PieChart2";
import BarChart from "./components/BarChart3";

import AMapLoader from "@amap/amap-jsapi-loader";
import { shallowRef } from "@vue/reactivity";

import { computed, defineComponent, onMounted, ref } from "vue";
import { mapAddCircle, addMapCenter } from "@/utils/pdf";
import { useStore } from "vuex";

import { getMapDelayByMarkerCount } from '@/utils'
import {
  drawMapKeepAlive,
} from '@/api/apiv1'

export default defineComponent({
  components: {
    PageHeader,
    PageTips,
      PieChart,
    BarChart,
  },
  props: {
      cityInfo: {
          type: Object,
          default: () => {
              return {};
          },
      },
      catData1: {
          type: Object,
          default: () => {
              return []
          },
      },
      catData2: {
          type: Object,
          default: () => {
              return []
          },
      },
    moduleData: {
      type: Object,
      default: () => {
        return {

        };
      },
    },
  },
    setup(props) {
    const poisList = ref([])
    const store = useStore()
        const typeList = [
            {  id: 141 , name: '小吃快餐',  color: '#1644FF' },
            {  id: 142, name: '面包甜点蛋糕',  color: '#FF8116' },
            {  id: 145, name: '异域料理',  color: '#F40000' },
            {  id: 146, name: '饮品店',  color: '#17C49D' },
            {  id: 147, name: '火锅',  color: '#FF00BD' },
            {  id: 148, name: '鱼鲜海鲜',  color: '#00F7FF' },
            {  id: 149, name: '自助餐',  color: '#FFD500' },
            {  id: 143, name: '地方菜系',  color: '#91B802' },
            {  id: 144, name: '烧烤烤肉',  color: '#8316FF' },
        ]

        let map = shallowRef(null);
        const addMaker = (amap, map, newData) => {
            let markerList = [];
            newData.forEach((v) => {
                let content = `<div style="width: 12px;height: 12px; border-radius: 50%;background: ${v.color};"></div>`
                let marker = new amap.Marker({
                    position: new amap.LngLat(
                        v.location.split(",")[0],
                        v.location.split(",")[1]
                    ),
                    content
                });
                markerList.push(marker);
            });
            map.add(markerList);
        };
        onMounted(() => {
            AMapLoader.load({
                key: "f0da9fb8263bb9b657e2257ec09c6df7", // 申请好的Web端开发者Key，首次调用 load 时必填
                version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            }).then((AMap) => {
              map = new AMap.Map("page18Map", {
                WebGLParams: {
                    preserveDrawingBuffer:true
                },
                dragEnable: false,
                zoomEnable: false,
                zoom: store.getters.mapZoom, //初始化地图级别
                center: props.cityInfo.location.split(","), //初始化地图中心点位置
              });
              let list = []
              props.catData1.forEach(el => {
                list = [...list, ...el.pois.map( v => {
                  return {
                    ...v,
                    color: el.color
                  }
                })]
              })
              poisList.value = list
              mapAddCircle(props.cityInfo, map)
              addMaker(AMap, map, list);
              addMapCenter(AMap, map, props.cityInfo.location)

              // 等待地图渲染
              const delay = getMapDelayByMarkerCount(list.length)
              drawMapKeepAlive(delay).catch((e) => {})

              map.setFitView()
          })
          .catch((e) => {
              console.log(e);
          });
        });
        const total = computed(() => {
          if (!props.catData1.length) return 0
          return props.catData1.map(el => el.value).reduce((pre,next) => pre + next)
        })

        const pieData = computed(() => {
            return props.catData1
        })

        const barData = computed(() => {
            return props.catData2.map(el => {
                return {
                    name: el.cate_name,
                    value: el.count
                }
            })
        })

        return {
            map,
            total,
            pieData,
            barData,
            typeList,
          poisList
        };
    },
});
</script>

<style lang="less">
.page18 {
  position: relative;
  background: #ffffff;
  padding: 26px 60px 32px 0;
  .page18-body {
    flex-grow: 1;
    padding: 67px 20px 0 61px;
    .page18-body-left {
      width: 900px;
      .page18-body-header {
        height: 33px;
        font-size: 24px;

        font-weight: bold;
        color: #333333;
        line-height: 33px;
        margin-bottom: 10px;
      }
    }
    .page18-body-right {
      width: 853px;
      .page18-map {
        width: 100%;
        height: 760px;
      }
      .page18-map-legend {
        flex-grow: 1;
          margin-bottom: 15px;
        .page18-map-legend-item {
            width: 20%;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 28px;
            margin-top: 15px;
        }
        .page18-map-legend-item:last-child {
          margin-right: 0;
        }
        .page18-map-legend-icon {
          width: 22px;
          height: 22px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-right: 12px;
        }
        .icon1 {
          background-image: url("~@/assets/机场.png");
        }
        .icon2 {
          background-image: url("~@/assets/火车站.png");
        }
        .icon3 {
          background-image: url("~@/assets/高铁站.png");
        }
        .page18-map-legend-point {
          width: 22px;
          height: 22px;
          margin-right: 12px;
          border-radius: 50%;
        }
        .point1 {
          background-color: #1644ff ;
        }
        .point2 {
          background-color: #ff8116;
        }
      }
      .page18-map-tips {
        flex-shrink: 0;
        text-align: right;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9a9a9a;
        line-height: 28px;
      }
    }
  }
}
</style>
