<template>
  <div class="line-chart f-r f-c">
    <div class="chart-title">
      <div class="title">{{ title }}</div>
      <div class="subtitle">{{ total }}{{ unit }}</div>
    </div>
    <div class="chart-canvas">
      <VChart style="width: 100%; height: 100%" :option="option" autoresize></VChart>
    </div>
    <div class="chart-legend f-r f-w a-c">
      <div class="chart-legend-item f-r a-c" v-for="(item, index) in legendList" :key="index">
        <div class="chart-legend-color" :style="{ background: item.color }"></div>
        <div class="chart-legend-text">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
export default defineComponent({
  props: {
    title: String,
    unit: String,
    chartData: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const color = [
      "#0054FF",
      "#00D681",
      "#727F95",
      "#FFBE00",
      "#F40000",
      "#26C1FF",
    ];
    const option = computed(() => {
      return {
        color,
        series: [
          {
            type: "pie",
            radius: ["50%", "80%"],
            center: ["50%", "42%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "inside",
              formatter: (p) => {
                return p.value || ''
              },
              color: "#fff",
              fontSize: 20,
            },
            labelLine: {
              show: false,
            },
            data: props.chartData,
          },
        ],
      };
    });
    const legendList = computed(() => {
      return props.chartData.map((el, index) => {
        return {
          ...el,
          color: color[index],
        };
      });
    });
    const total = computed(() => {
      if (props.chartData.length === 0) return 0;
      return props.chartData
        .map((el) => el.value)
        .reduce((pre, next) => pre + next);
    });

    return {
      option,
      legendList,
      total,
    };
  },
});
</script>

<style lang="less">
.line-chart {
  height: 100%;
  padding: 12px;
  background: #f6f7fa;
  position: relative;
  .chart-title {
    position: absolute;
    top: 42%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;

    font-weight: bold;
    color: #333333;
    line-height: 33px;
    .title {
      font-size: 20px;
    }
    .subtitle {
      font-size: 38px;
      line-height: 38px;
    }
  }
  .chart-legend {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    padding: 0 18px;
    .chart-legend-item {
      margin-right: 17px;
      .chart-legend-color {
        width: 13px;
        height: 13px;
        border-radius: 50%;
        margin-right: 15px;
      }
      .chart-legend-text {
        height: 24px;
        font-size: 17px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8c8c8c;
        line-height: 24px;
      }
    }
  }
  .chart-canvas {
    flex-grow: 1;
  }
}
</style>
