import { createApp } from "vue";
import App from "./App.vue";
import '@/style/index.css'
import router from "./router";
import store from "./store";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import {
  PieChart,
  LineChart,
  RadarChart,
  BarChart,
  ScatterChart,
} from "echarts/charts";
import {
  LegendComponent,
  TooltipComponent,
  TitleComponent,
  AxisPointerComponent,
  GridComponent,
} from "echarts/components";
import VChart from "vue-echarts";
// import Map from './views/Report/components/Map.vue'
use([
  CanvasRenderer,
  ScatterChart,
  PieChart,
  LineChart,
  BarChart,
  RadarChart,
  TooltipComponent,
  AxisPointerComponent,
  GridComponent,
  TitleComponent,
  LegendComponent
]);
const app = createApp(App);

app.component("VChart", VChart);
// app.component("VMap", Map);
import Empty from "@/views/Report/components/Empty";
app.component('Empty', Empty)
app.use(store).use(router).mount("#app");
