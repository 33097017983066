<template>
  <router-view />
</template>

<style>
* {
  box-sizing: border-box;
}
.f-r {
  display: flex;
  flex-direction: row;
}
.f-w {
  flex-wrap: wrap;
}
.f-c {
  display: flex;
  flex-direction: column;
}
.j-e {
  justify-content: flex-end;
}
.j-a {
  justify-content: space-around;
}
.j-b {
  justify-content: space-between;
}
.j-c {
  justify-content: center;
}
.a-c {
  align-items: center;
}
.f-g {
  flex-grow: 1;
}
</style>
