<template>
  <div class="table-nav f-r" :style="{ width, height }">
    <div class="nav1"></div>
    <div class="table-nav-right f-r j-b a-c">
      <div class="nav2">
        <slot name="title"> </slot>
      </div>
      <div class="nav3">
        <slot name="subtitle"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    width: String,
    height: String,
  },
});
</script>

<style lang="less">
.report-page {
  .table-nav {
    color: #fff;
    height: 50px;
    background: #333333;
    .table-nav-right {
      flex-grow: 1;
      padding: 0 20px;
    }
    .nav1 {
      width: 15px;
      height: 100%;
      background: #ffdb00;
    }
    .nav2 {
      flex-grow: 1;
      font-size: 24px;

      font-weight: bold;
      color: #ffffff;
      line-height: 33px;
    }
    .nav3 {
      flex-shrink: 0;
      font-size: 24px;

    }
  }
}
</style>
