import { createStore } from "vuex";

export default createStore({
  state: {
    // 基础报告
    baseReport: {
      module1: {}
    },
    token: ''
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_BASIC_REPORT:(state, reportData) => {
      state.baseReport = reportData
    }
  },
  actions: {},
  modules: {},
  getters: {
    mapZoom: state => {
      const r = state.baseReport.module1.radius * 1000
      if (r <= 500) {
        return  15.8
      } else if (r <=1000) {
        return  15.2
      }else if (r <=1500) {
        return  14.8
      }else if (r <=2000) {
        return  14.3
      }else if (r <=2500) {
        return  14
      }else if (r <=3000) {
        return  13.8
      }else if (r <=3500) {
        return  13.5
      }else if (r <=4000) {
        return  13.3
      }else {
        return  13
      }
    },
  }
});
