<template>
  <div class="block page25 f-c">
    <PageHeader
      :title="moduleData['1_1_1_1']"
      :sub-title="moduleData['1_1_1_1_en']"
    />
    <PageTips page-num="01" />
    <div class="page25-body f-r">
      <div class="page25-left">
        <div class="page25-left-title">
          <div class="page25-score-top"></div>
          <div class="page25-score">
            <text class="page25-score-title">区域综合评分:</text>
            <text class="page25-score-value">
              {{ moduleData.total_score }}
            </text>
          </div>
        </div>
        <div class="page25-score-box">
          <div class="page25-score-box-item">
            <div class="page25-score-box-item1" 
            :style="{ width: percentage1 + '%', backgroundColor: '#FFDB00' }">
            </div>
          </div>
          <div class="page25-score-box-item">
            <div class="page25-score-box-item1" 
            :style="{ width: percentage2 + '%', backgroundColor: '#FFDB00' }">
            </div>
          </div>
          <div class="page25-score-box-item">
            <div class="page25-score-box-item1" 
            :style="{ width: percentage3 + '%', backgroundColor: '#FFDB00' }">
            </div>
          </div>
          <div class="page25-score-box-item">
            <div class="page25-score-box-item1" 
            :style="{ width: percentage4 + '%', backgroundColor: '#FFDB00' }">
            </div>
          </div>
          <div class="page25-score-box-item">
            <div class="page25-score-box-item1" 
            :style="{ width: percentage5 + '%', backgroundColor: '#FFDB00' }">
            </div>
          </div>
        </div>
        <div class="page25-score-box-tips">
          <text class="page25-score-box-tip" style="margin-left:5px">条件较差（0-3.9）</text>
          <text class="page25-score-box-tip" style="margin-left:165px">一般（4-5.9）</text>
          <text class="page25-score-box-tip" style="margin-left:50px">条件优质（6-10）</text> 
        </div>
        <div class="page25-score-box-tips-line"></div>
        <div class="page25-score-box-tips-line" style="margin-left:283px;margin-top:-150px"></div>
        <div class="page25-score-box-tips-line" style="margin-left:425px;margin-top:-150px"></div>
        <div class="page25-left-title">
          <div class="page25-score-top" style="height:50px"></div>
          <div class="page25-score" style="height:50px">
            <text class="page25-score-title2">区域综合评分分析</text>
          </div>
        </div>
        <div class="page25-metric-list">
          <div
            class="page25-metric-item"
            v-for="(item, index) in analysisContent"
            :key="index"
          >
            <div class="page25-metric-title">
              {{ item.title }}
            </div>
            <div class="page25-metric-desc">
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
      <div class="page25-right">
        <RadarChart :chart-data="moduleData.regional_capability_index" />

        <div class="page25-map-tips" style="margin-top: 20px">
          数据来源：国家统计局社会发展公报整合，以上数据为最新年份统计数据
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import PageHeader from "@/views/Report/components/PageHeader";
import PageTips from "./components/PageTips";
import RadarChart from "./components/AreaRadarChart";

export default defineComponent({
  components: {
    PageHeader,
    PageTips,
    RadarChart,
  },
  props: {
    moduleData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    // const colData = ref([
    //   { prop: "id", width: "109px", label: "序号" },
    //   { prop: "circle_name", width: "538px", label: "商圈名称" },
    //   { prop: "district", width: "279px", label: "所属区" },
    //   {
    //     prop: "business_circle_positioning",
    //     width: "408.63px",
    //     label: "商圈定位",
    //   },
    //   { prop: "business_num", width: "408.63px", label: "覆盖商圈数量" },
    // ]);
    // const rowData = computed(() => {
    //   return props.moduleData["2_5_4_4"];
    // });
    // const score = 6.3
    const score = props.moduleData["total_score"]
    var percentage1 = ''
    var percentage2 = ''
    var percentage3 = ''
    var percentage4 = ''
    var percentage5 = ''
    if(score == 10){
      percentage1 = '100'
      percentage2 = '100'
      percentage3 = '100'
      percentage4 = '100'
      percentage5 = '100'
    }else if(score > 8){
      percentage1 = '100'
      percentage2 = '100'
      percentage3 = '100'
      percentage4 = '100'
      percentage5 = score>9?String(50+(score-9)*50):String((score-8)*50)
    }else if(score > 6){
      percentage1 = '100'
      percentage2 = '100'
      percentage3 = '100'
      percentage4 = score>7?String(50+(score-7)*50):String((score-6)*50)
      percentage5 = '0'
    }else if(score > 4){
      percentage1 = '100'
      percentage2 = '100'
      percentage3 = score>5?String(50+(score-5)*50):String((score-4)*50)
      percentage4 = '0'
      percentage5 = '0'
    }else if(score > 2){
      percentage1 = '100'
      percentage2 = score>3?String(50+(score-3)*50):String((score-2)*50)
      percentage3 = '0'
      percentage4 = '0'
      percentage5 = '0'
    }else{
      percentage1 = score>1?String(50+(score-1)*50):String(score*50)
      percentage2 = '0'
      percentage3 = '0'
      percentage4 = '0'
      percentage5 = '0'
    }
// console.log(percentage1,percentage2,percentage3,percentage4,percentage5)
const analysisContent = [{'title':'优秀（8-10分）','value':'点位位于最佳的选址区域，能够吸引大量的消费者和高消费能力群体。需要评估租金成本与预期收益之间的平衡，确保投资回报率最大化。'}
,{'title':'良好（6-7.9分）','value':'具备较好的商业环境和发展潜力，可能是位于城市的次级商业区或发展中的区域。可以考虑作为备选地点值得挖掘。'}
,{'title':'中等（4-5.9分）','value':'点位虽然具有一定的市场基础，但需要更多的市场推广和运营优化才能实现良好的商业效果。建议进行详细的成本效益分析和市场进入策略规划。'}
,{'title':'较差（2-3.9分）','value':'市场潜力有限，且改善成本可能较高。通常不建议选择这类点位，除非有特殊的业务需求或极低的租金可以大幅降低成本。'}
,{'title':'差（0-1.9分）','value':'点位可能位于极端偏远的地区或完全没有商业活动的地带，不具备开展业务的基础设施或市场支持。应避免选择这类点位 。'}]
    return {
      // rowData,
      // colData,
      percentage1,
      percentage2,
      percentage3,
      percentage4,
      percentage5,
      analysisContent,
    };
  },
});
</script>

<style lang="less">
.page25 {
  position: relative;
  background: #ffffff;
  padding: 26px 60px 32px 0;
  .page25-body {
    flex-grow: 1;
    padding: 17px 20px 0 39px;
    .page25-left {
      width: 700px;
      margin-right: 53px;
      .page25-left-title{
        display: flex;
      }
      .page25-score-top {
        width: 15px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        height: 70px;
        background: #ffdb00;
        flex: 0.02;
      }
      .page25-score {
        width: cale(100%-15px);
        height: 70px;
        background: #333333;
        border-radius: 25px;
        margin-bottom: 25px;
        border-radius: 0;
        flex: 0.98;
        .page25-score-title {
          height: 70px;
          font-size: 36px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 70px;
          margin-left: 19px;
        }
        .page25-score-title2 {
          height: 50px;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 50px;
          margin-left: 19px;
        }
        .page25-score-value {
          font-size: 36px;

          font-weight: bold;
          color: #ffdb00;
          line-height: 70px;
          margin-left: 19px;
        }
        .page25-metric-list {
          margin-top: 26px;
        }
      }
      .page25-metric-item {
        margin-bottom: 30px;
        .page25-metric-title {
          font-size: 24px;

          font-weight: bold;
          color: #111F2C;
          line-height: 30px;
        }
        .page25-metric-desc {
          font-size: 20px;
          color: #111F2C;
          line-height: 30px;
        }
      }
      .page25-score-box{
        width:cale(100% + 8px);
        height:50px;
        margin-left:-8px;
        display:flex;

        .page25-score-box-item{
          width:152px;
          height:100%;
          margin-left:8px;
          border:1px solid #000;
          // background-color:#FFDB00;
          .page25-score-box-item1{
          width:100%;
          height:100%;
        }
        }
      }
      .page25-score-box-tips{
        width:100%;
        height:20px;
        margin-top:10px;
        .page25-score-box-tip{
          font-size: 14px;
          font-family: PingFangSC;
          // line-height: 20px;
        }
      }
      .page25-score-box-tips-line{
        width:1px;
        height:85px;
        background-color:#333333;
        margin-top:-85px;
        margin-bottom:66px;
      }
    }
    .page25-right {
      width: 1026px;
      height: 877px;
      background: #ffffff;
      border-radius: 23px;
      border: 1px dashed #333333;
    }
  }
  .page25-map-tips {
    width: 100%;
    padding-right: 20px;
    flex-shrink: 0;
    text-align: right;
    height: 28px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9a9a9a;
    line-height: 28px;
  }
}
</style>
