<template>
  <div class="block page12 f-r f-c">
    <PageHeader :title="moduleData['3_13_1_0']" :sub-title="moduleData['3_13_1_0_en']" />
    <PageTips page-num="06"/>
    <div class="page12-body f-r j-b">
      <div class="page12-body-left">
        <TableNav width="551px" height="67px">
          <template v-slot:title>
            <div class="page12-nav f-c j-c">
              <div class="title1">办公楼日租金分布</div>
              <div class="desc f-r j-b a-c">
                <div class="desc1">平均日租金（{{ avg1.p }} 元/㎡）</div>
                <div class="desc2">平均距离（{{ avg1.d }} m）</div>
              </div>
            </div>
          </template>
        </TableNav>
        <PointChart unit="元" style="height: 303px; margin-bottom: 20px" :chart-data="chart1">
          <Empty text="当前区域数据暂未覆盖" v-if="!chart1.length"/>
          <Empty text="当前区域暂未获取到数据" v-else-if="!chart1.filter(c => !!c[1]).length"/>
        </PointChart>
        <TableNav width="551px" height="67px">
          <template v-slot:title>
            <div class="page12-nav f-c j-c">
              <div class="title1">办公楼楼层分布</div>
              <div class="desc f-r j-b a-c">
                <div class="desc1">平均楼层（{{ avg2.p }}层）</div>
                <div class="desc2">平均距离（{{ avg2.d }} m）</div>
              </div>
            </div>
          </template>
        </TableNav>
        <PointChart unit="层" style="height: 303px" :chart-data="chart2">
          <Empty text="当前区域数据暂未覆盖" v-if="!chart2.length"/>
          <Empty text="当前区域暂未获取到数据" v-else-if="!chart2.filter(c => !!c[1]).length"/>
        </PointChart>
      </div>
      <div class="page12-body-right f-c j-b">
        <div class="page12-map" id="page12Map">
          <Empty text="当前区域数据暂未覆盖" v-if="!catData?.poi || !catData?.poi?.length"/>
        </div>
        <div class="page12-map-tips">
          数据来源：边界房产大数据，房产平台数据整合
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "./components/PageHeader";
import TableNav from "./components/TableNav";
import PageTips from "./components/PageTips";

import AMapLoader from "@amap/amap-jsapi-loader";
import { shallowRef } from "@vue/reactivity";

import {computed, defineComponent, onMounted} from "vue";
import PointChart from "@/views/Report/components/PointChart";
import { mapImage, mapAddCircle, addMapCenter } from "@/utils/pdf";
import { useStore } from "vuex";

import { getMapDelayByMarkerCount } from '@/utils'
import {
  drawMapKeepAlive,
} from '@/api/apiv2'
import Empty from "@/views/Report/components/Empty.vue";

export default defineComponent({
  components: {
    Empty,
    PointChart,
    PageHeader,
    PageTips,
    TableNav,
  },
  props: {
    cityInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    catData: {
      type: Object,
      default: () => {
        return {
          count: 0,
          poi: []
        };
      },
    },
    moduleData: {
      type: Object,
      default: () => {}
    },
  },
    setup(props) {
        const store = useStore()
        let map = shallowRef(null);
        const addMaker = (amap, map, newData) => {
            let markerList = [];
            newData.forEach((v) => {
                let marker = new amap.Marker({
                    position: new amap.LngLat(
                        v.location.split(",")[0],
                        v.location.split(",")[1]
                    ),
                    content: `
                <div style="width: 12px;height: 12px; border-radius: 50%;background: #1644FF;"></div>
              `,
                });
                markerList.push(marker);
            });
            map.add(markerList);
        };
        const chart1 = computed(() => {
            return props.catData.poi.filter((el) => !!(el.dailyRent*1)).map((el) => {
                return [el.distance * 1 || 0, el.dailyRent * 1 || 0];
            });
        });
      const avg1 = computed(() => {
            if (chart1.value.length === 0)
                return {
                    d: 0,
                    p: 0,
                };
            return {
                d: (
                    chart1.value.map((el) => el[0]).filter(el => el).reduce((pre, next) => {
                      return pre + next
                    },0) /
                    (chart1.value.map((el) => el[0]).filter(el => el)?.length || 1)
                ).toFixed(2),
                p: (
                    chart1.value.map((el) => el[1]).filter(el => el).reduce((pre, next) => {
                      return pre + next
                    },0) /
                    (chart1.value.map((el) => el[1]).filter(el => el)?.length || 1)
                ).toFixed(2),
            };
        });
        const chart2 = computed(() => {
            return props.catData.poi.filter((el) => !!(el.floorAvg*1)).map((el) => {
                return [el.distance * 1 || 0, el.floorAvg * 1 || 0];
            });
        });
      const avg2 = computed(() => {
            if (chart2.value.length === 0)
                return {
                    d: 0,
                    p: 0,
                };
            return {
                d: (
                    chart2.value.map((el) => el[0]).filter(el => el).reduce((pre, next) => {
                      return pre + next
                    }, 0) /
                    (chart2.value.map((el) => el[0]).filter(el => el)?.length || 1)
                ).toFixed(2),
                p: (
                    chart2.value.map((el) => el[1]).filter(el => el).reduce((pre, next) => {
                      return pre + next
                    }, 0) /
                    (chart2.value.map((el) => el[1]).filter(el => el)?.length || 1)
                ).toFixed(0),
            };
        });
        onMounted(() => {
            AMapLoader.load({
                key: "f0da9fb8263bb9b657e2257ec09c6df7", // 申请好的Web端开发者Key，首次调用 load 时必填
                version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            })
              .then((AMap) => {
                  map = new AMap.Map("page12Map", {
                    WebGLParams: {
                        preserveDrawingBuffer:true
                    },
                    dragEnable: false,
                    zoomEnable: false,
                    zoom: store.getters.mapZoom, //初始化地图级别
                    center: props.cityInfo.location.split(","), //初始化地图中心点位置
                  });
                  mapAddCircle(props.cityInfo, map)
                  addMaker(AMap, map, props.catData.poi);
                  addMapCenter(AMap, map, props.cityInfo.location)

                  // 等待地图渲染
                  const delay = getMapDelayByMarkerCount(props?.catData?.poi?.length || 0)
                  drawMapKeepAlive(delay).catch((e) => {})

                  map.setFitView()
                })
                .catch((e) => {
                    console.log(e);
                });
        });

        return {
            map,
            chart1,
            chart2,
            avg1,
            avg2,
        };
    },
});
</script>

<style lang="less">
.page12 {
  position: relative;
  background: #ffffff;
  padding: 26px 60px 32px 0;

  .page12-body {
    flex-grow: 1;
    padding: 67px 20px 0 37px;
    .page12-body-left {
      width: 551px;
      .page12-nav {
        height: 100%;
        .title1 {
          font-size: 24px;

          font-weight: bold;
          color: #ffffff;
          line-height: 33px;
        }
        .desc {
          height: 20px;
          font-size: 14px;

          font-weight: bold;
          color: #ffffff;
          line-height: 20px;
        }
      }
    }
    .page12-body-right {
      width: 1192px;
      .page12-map {
        width: 100%;
        height: 760px;
      }
      .page12-map-tips {
        flex-shrink: 0;
        text-align: right;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9a9a9a;
        line-height: 28px;
      }
    }
  }
}
</style>
