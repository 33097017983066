<template>
  <div class="etable f-r">
    <div
      class="etable-wrapper"
      v-for="(rowData, tableIndex) in tableData"
      :key="tableIndex"
    >
      <table>
        <tbody>
          <tr
            v-for="(row, rowIndex) in rowData"
            :key="rowIndex"
            :class="[row.length ? '' : 'empty-row']"
          >
            <td :style="{ width: cellWidth }">
              <div class="cell" v-html="row[0]"></div>
            </td>
            <td :style="{ width: cellWidth }">
              <div class="cell" v-html="row[1]"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    tableData: Array,
    cellWidth: String,
  },
});
</script>

<style lang="less"></style>
