<template>
  <div class="page-tips">
    <div class="text">
      {{ baseReportRightText }}
    </div>
    <div class="page-num">{{ pageNum }}</div>
  </div>
</template>

<script>
export default {
  props: {
    pageNum: String,
  },
  computed: {
    baseReportRightText() {
      return this.$store.state.baseReport.right_text
    }
  }
};
</script>

<style lang="less">
.report-page {
  .page-tips {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 100%;
    font-size: 40px;

    font-weight: bold;
    color: #ffffff;
    line-height: 56px;
    background: #f40000;
    padding-top: 124px;
    line-height: 60px;

      .text {
          position: absolute;
          width: 1080px;
          height: 60px;
          line-height: 60px;
          transform: rotate(90deg);
          left: -510px;
          top: 510px;
          z-index: 99;
          padding-left: 124px;
      }
    .page-num {
      position: absolute;
      bottom: 30px;
      left: 0;
      width: 60px;
      text-align: center;
      writing-mode: initial;
    }
  }
}
</style>
